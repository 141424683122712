import React, { useEffect, useRef, useState } from "react";
import "../notification.css";
import { basename } from "../../../Config";
import { withTranslation } from "react-i18next";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { CircularProgress, Skeleton } from "@mui/material";
import BtnOutline from "../../../components/button/BtnOutline";
import { Date as DateFormat } from "../../../utils/moment";
import Nodata from "../../../components/Nodata";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "../../../routing/utils/routeConstant";
import Tooltip from "@mui/material/Tooltip";
import urlResolver from "../../../utils/urlResolver";
function Fivedays(props) {
  const [dataNotification, setDataNotification] = useState([]);
  const [loading, setLoading] = useState({
    initial: false,
    loadmore: false,
  });
  const [openCard, setOpenCard] = useState(null);
  const [pagenumber, setPagenumber] = useState(0);
  const { t } = props;
  const totalItem = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    getNotification(pagenumber);
  }, []);
  const getNotification = (CurrentPage) => {
    if (CurrentPage === 0) {
      setLoading({ ...loading, initial: true });
    } else {
      setLoading({ ...loading, loadmore: true });
    }
    const body = {
      ShipmentId: "",
      EmailId: "",
      Days: 5,
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: CurrentPage,
      PageSize: 10,
    };
    instance
      .post(API_PATH.GET_NOTIFICATION, body)
      .then((res) => {
        if (res._IsSuccess) {
          totalItem.current = res._TotalItem;
          if (CurrentPage === 0) {
            setDataNotification(res?._Data ?? []);
          } else {
            setDataNotification([...dataNotification, ...res?._Data]);
          }
        } else {
          setDataNotification([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading({ ...loading, initial: false, final: false });
      });
  };
  const OnClickDropIcon = (item, index) => {
    setOpenCard(index);
  };
  const onLoadMore = () => {
    const temp = pagenumber + 1;
    getNotification(temp);
    setPagenumber(temp);
  };
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="container-list-notification col-12 ">
          <div>
            <div className="container-list-note">
              <div className="list-img visible-img">
                <img src={urlResolver("icon/icon-interrogation.svg")} alt="" />
              </div>
              <div className="info-notification">
                <div className="description-data">
                  <p className="text-uppercase">{t("Destination")}</p>
                  <p className="text-uppercase">{t("Ship")}</p>
                  <p className="text-uppercase">{t("PONumber")}</p>
                  <p className="text-uppercase">{t("ETD")}</p>
                  <p className="text-uppercase">{t("ETA")}</p>
                </div>
              </div>
            </div>
          </div>
          {loading.initial ? (
            <div className="skeleton-type">
              <Skeleton style={{ height: "8rem", marginTop: "0.5rem" }} />
              <Skeleton style={{ height: "8rem", marginTop: "0.5rem" }} />
              <Skeleton style={{ height: "8rem", marginTop: "0.5rem" }} />
              <Skeleton style={{ height: "8rem", marginTop: "0.5rem" }} />
              <Skeleton style={{ height: "8rem", marginTop: "0.5rem" }} />
              <Skeleton style={{ height: "8rem", marginTop: "0.5rem" }} />
              <Skeleton style={{ height: "8rem", marginTop: "0.5rem" }} />
            </div>
          ) : (
            <>
              {dataNotification.length ? (
                <>
                  <div className="list-last-updates" id="days-div">
                    {dataNotification.map((item, index) => {
                      return (
                        <>
                          <div
                            className="testdiv"
                            onClick={() => {
                              const path = `${routeConstant.PROCESS}/${routeConstant.PROCESSDETAILS}`;
                              navigate(path, {
                                state: { item: item, from: "notification" },
                              });
                            }}
                          >
                            <div
                              key={index}
                              className="list-notification "
                              id="days-inside"
                            >
                              <div className="list-img">
                                <div>
                                  {item.TransportMode === "SEA" ? (
                                    <img
                                      src={urlResolver("icon/icon-ship.svg")}
                                    />
                                  ) : item.TransportMode === "AIR" ? (
                                    <img
                                      src={urlResolver(
                                        "icon/icon-plane-white.svg"
                                      )}
                                    />
                                  ) : item.TransportMode === "ROA" ? (
                                    <img
                                      src={urlResolver("icon/truck_color.svg")}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="information-table-note">
                                <div className="description-data-bottom">
                                  <div>
                                    <p>
                                      {item?.OriginPortName ?? "N/A"}
                                      {" →"}
                                    </p>
                                    <p>{item?.DestinationPortName ?? "N/A"}</p>
                                  </div>
                                  <div>
                                    <p>{item.CarrierName}</p>
                                  </div>
                                  <div>
                                    <Tooltip
                                      title={
                                        item?.OrderReference.length > 20
                                          ? item?.OrderReference
                                          : ""
                                      }
                                    >
                                      <p>
                                        {item?.OrderReference.length > 20
                                          ? item?.OrderReference.slice(0, 18) +
                                            "..."
                                          : item?.OrderReference}
                                      </p>
                                    </Tooltip>
                                    {/* <p>{item?.OrderReference?? "N/A"}</p> */}
                                  </div>
                                  <div>
                                    <p>{DateFormat(item?.ETD ?? null)}</p>
                                  </div>
                                  <div>
                                    <p>{DateFormat(item?.ETA ?? null)}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="testdiv2">
                            <div
                              className="list-items"
                              key={index}
                              // onClick={() => {
                              //   const path = `${routeConstant.PROCESS}/${routeConstant.PROCESSDETAILS}`;
                              //   navigate(path, {
                              //     state: { item: item },
                              //   });
                              // }}
                            >
                              {/* LAST CARGO UPDATES TABLE */}
                              <div
                                className="container-list"
                                style={
                                  openCard === index
                                    ? {
                                        gridTemplateRows:
                                          "14px 50px 36px 30px 17px 14px 32px 16px",
                                      }
                                    : {}
                                }
                              >
                                {item.TransportMode === "SEA" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver("icon/icon-ship.svg")}
                                      alt="Sea Shipment icon"
                                    />
                                  </div>
                                ) : item.TransportMode === "AIR" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver(
                                        "icon/icon-plane-white.svg"
                                      )}
                                      alt="Air Shipment icon"
                                    />
                                  </div>
                                ) : item.TransportMode === "ROA" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver("icon/truck_color.svg")}
                                      alt="Road Shipment icon"
                                    />
                                  </div>
                                ) : null}
                                <p className="title-table position-grid-title-destination">
                                  Destination
                                </p>
                                <div className="destination">
                                  <p className="from">
                                    {item?.OriginPortName ?? t("N/A")} {" →"}
                                  </p>
                                  <p className="to">
                                    {item?.DestinationPortName ?? t("N/A")}
                                  </p>
                                </div>
                                {openCard === index ? (
                                  <>
                                    <p className="title-table position-grid-title-ship">
                                      Ship
                                    </p>
                                    <div className="ship">
                                      <p
                                        className="from"
                                        title={item?.CarrierName ?? t("N/A")}
                                      >
                                        {item?.CarrierName ?? t("N/A")}
                                      </p>
                                    </div>
                                    <p className="title-table position-grid-title-po-number">
                                      PO Number
                                    </p>
                                    <div className="po-number">
                                      <p
                                        className="from"
                                        title={item?.OrderReference ?? t("N/A")}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "8rem",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {item?.OrderReference ?? t("N/A")}
                                      </p>
                                    </div>
                                  </>
                                ) : null}
                                <p className="title-table position-grid-title-etd">
                                  ETD
                                </p>
                                <div className="etd">
                                  <p className="from">
                                    {DateFormat(item?.ETD ?? null)}
                                  </p>
                                </div>
                                <p className="title-table position-grid-title-eta">
                                  ETA
                                </p>
                                <div className="eta">
                                  <p className="from">
                                    {DateFormat(item?.ETA ?? null)}
                                  </p>
                                </div>
                                <span className="line-list"></span>
                                <button
                                  className="btn-dropdown-list-items"
                                  onClick={() => {
                                    OnClickDropIcon(item, index);
                                  }}
                                >
                                  <img
                                    className="img-arrow-dropdown"
                                    style={{ color: "red" }}
                                    src={urlResolver(
                                      "icon/icon-arrow-dropdown.svg"
                                    )}
                                    alt=""
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>

                        // <div
                        //   key={index}
                        //   className="list-notification "
                        //   id="days-inside"
                        // >
                        //   <div className="list-img">
                        //     <div>
                        //       {i.TransportMode === "SEA" ? (
                        //         <img src={`${basename}/icon/icon-ship.svg`} />
                        //       ) : i.TransportMode === "AIR" ? (
                        //         <img
                        //           src={`${basename}/icon/icon-plane-white.svg`}
                        //         />
                        //       ) : i.TransportMode === "ROA" ? (
                        //         <img
                        //           src={`${basename}/icon/truck_color.svg`}
                        //         />
                        //       ) : null}
                        //     </div>
                        //   </div>
                        //   <div className="information-table-note">
                        //     <div className="description-data-bottom">
                        //       <div>
                        //         <p>
                        //           {i?.OriginPortName ?? "N/A"}
                        //           {" →"}
                        //         </p>
                        //         <p>{i?.DestinationPortName ?? "N/A"}</p>
                        //       </div>
                        //       <div>
                        //         <p>{i.CarrierName}</p>
                        //       </div>
                        //       <div>
                        //         <p>{i?.OrderReference ?? "N/A"}</p>
                        //       </div>
                        //       <div>
                        //         <p>{Date(i?.ETD ?? null)}</p>
                        //       </div>
                        //       <div>
                        //         <p>{Date(i?.ETA ?? null)}</p>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    })}
                  </div>
                  {/* show more button */}
                  {totalItem.current > dataNotification.length && (
                    <div className="d-flex justify-content-center align-items-center">
                      <BtnOutline
                        title={
                          loading.loadmore ? (
                            <CircularProgress
                              size="0.7rem"
                              style={{ color: "--color-blue1" }}
                            />
                          ) : (
                            "Show More"
                          )
                        }
                        onClick={() => onLoadMore()}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Nodata />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Fivedays);
