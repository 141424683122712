import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import BtnOutline from "../../../components/button/BtnOutline";
import { useLocation } from "react-router-dom";
import { routeConstant } from "../../../routing";
import instance from "../../../api/interceptor";
import toast from "react-hot-toast";
import { API_PATH } from "../../../constant/apiPath";
import axios from "axios";
import { baseUrl } from "../../../Config";
import { withTranslation } from "react-i18next";
const EditUser = (props) => {
  const location = useLocation();
  const [editobject, setEditobject] = useState({});

  useEffect(() => {
    userdetails(location.state);
  }, []);

  const onClicksave = () => {
    if (location.pathname === routeConstant.EDITUSER) {
      instance
        .post(API_PATH.EDIT_USER, editobject)
        .then((res) => {
          if (res._IsSuccess) {
            toast.success(res._Message);
          } else {
            toast.error(res._Message);
          }
        })
        .catch((err) => {
          console.log("save user error", err);
          toast.error(err?.response?.data?._Message ?? "Something went wrong!");
        });
    }
  };

  const userdetails = (user) => {
    const params = {
      OrgCodes: user.OrgCodes,
      EmailId: user.EmailId,
    };
    instance
      .post(API_PATH.GET_SINGLE_USER, params)
      .then((response) => {
        setEditobject(response?._Data ?? {});
      })
      .catch((error) => {
        console.log("error in get user based on mbl", error);
      });
  };
  const { t } = props;
  return (
    <div className="new-user">
      <div className="new-user-text">
        <p className="m-0">{t("EditUser")}</p>
      </div>

      <div className="add-user-div d-flex">
        <div className="d-flex add-user-text">
          <div className="add-user-textfield">
            <TextField
              // id="standard-basic"
              disabled={true}
              label="Name"
              variant="standard"
              value={editobject?.ContactName ?? ""}
              onChange={(event) => {
                const obj = { ...editobject };
                obj.ContactName = event.target.value;
                setEditobject(obj);
              }}
            />
          </div>
          <div className="add-user-textfield">
            <TextField
              // id="standard-basic"
              disabled={true}
              label="Email"
              variant="standard"
              value={editobject?.EmailId ?? ""}
              onChange={(event) => {
                const obj = { ...editobject };
                obj.EmailId = event.target.value;
                setEditobject(obj);
              }}
            />
          </div>
        </div>
      </div>
      <div className="user-permissions-div">
        <div>
          <p className=" user-permission-head m-0">{t("Permissions")}</p>
        </div>
        <div className="user-permissions-options">
          <div>
            <AntSwitch
              checked={editobject?.IsPorcess ?? false}
              onChange={(event) => {
                const obj = { ...editobject };
                obj.IsPorcess = !obj.IsPorcess;
                setEditobject(obj);
              }}
            />
            <p>{t("Process")}</p>
          </div>
          <div>
            <AntSwitch
              checked={editobject?.IsOrder ?? false}
              onChange={(event) => {
                const obj = { ...editobject };
                obj.IsOrder = !obj.IsOrder;
                setEditobject(obj);
              }}
            />
            <p>{t("Order")}</p>
          </div>
          <div>
            <AntSwitch
              checked={editobject?.IsFinance ?? false}
              onChange={(event) => {
                const obj = { ...editobject };
                obj.IsFinance = !obj.IsFinance;
                setEditobject(obj);
              }}
            />
            <p>{t("Financial")}</p>
          </div>
          <div>
            <AntSwitch
              checked={editobject?.IsReport ?? false}
              onChange={(event) => {
                const obj = { ...editobject };
                obj.IsReport = !obj.IsReport;
                setEditobject(obj);
              }}
            />
            <p>{t("Report")}</p>
          </div>
        </div>
      </div>
      <div className="save-button">
        <button onClick={onClicksave} title={t("Save")} className="btn-newuser">
          {t("Save")}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(EditUser);

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#120b5e",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "var(--color-white)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
