import { CircularProgress, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import instance from "../../../api/interceptor";
import BtnOutline from "../../../components/button/BtnOutline";
import Nodata from "../../../components/Nodata";
import { basename, baseUrl } from "../../../Config";
import { API_PATH } from "../../../constant/apiPath";
import { Date } from "../../../utils/moment";
import DocModal from "./DocModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-hot-toast";
import { genrateDownloadLink } from "../../../utils/utility";
import BtnGreen from "../../../components/button/BtnGreen";
import BtnRed from "../../../components/button/BtnRed";
import { AuthContext } from "../../../store/context/AuthProvider";
import urlResolver from "../../../utils/urlResolver";

function Pending() {
  const location = useLocation();
  const [pendingDocs, setPendingDocs] = useState([]);
  const [temp, setTemp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(-1);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(0);
  const [moalopen, setMoalopen] = useState(false);
  const [currentDoc, setCurrentDoc] = useState({});
  const [searcheddata, setSearcheddata] = useOutletContext();
  const [LoadingForDisapprove, setLoadingForDisapprove] = useState(false);
  const [copied, setCopied] = useState(false);
  const Auth = useContext(AuthContext);
  const [type, setType] = useState(0);

  useEffect(() => {
    if (!Auth.docPending?.length) {
      getpendingDocData(0);
    } else {
      let arr = Auth.docPending;
      setTemp(arr);
      setPendingDocs(arr);
    }
  }, [location]);

  useEffect(() => {
    if (!Auth.docPending?.length) {
      setPendingDocs([...searcheddata]);
    }
  }, [searcheddata]);

  const getpendingDocData = (count) => {
    if (count === 0) {
      setLoading(true);
    }
    const body = {
      Status: 1,
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: count,
      HouseBillNumber: "",
      PageSize: 10,
    };
    instance
      .post(API_PATH.GET_DOCUMENT_DATA, body)
      .then((res) => {
        setLoading(false);
        if (res._IsSuccess) {
          if (count === 0) {
            setPendingDocs(res?._Data ?? []);
          } else {
            setPendingDocs([...pendingDocs, ...res._Data]);
          }
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("get pending document api fail", err);
      })
      .finally(() => {
        setPage(count);
        setLoadMore(false);
      });
  };
  const onLoadMore = () => {
    setLoadMore(true);
    const count = page + 1;
    getpendingDocData(count);
    setPage(count);
  };

  const DocApproval = (item, remarks = "") => {
    setLoadingForDisapprove(true);
    const body = {
      Status: type,
      PK: item?.PK ?? null,
      Remarks: remarks,
    };
    instance
      .post(API_PATH.DOC_STATUS_CHANGED, body)
      .then((res) => {
        setPendingDocs([]);
        setType(0);
        if (res._IsSuccess) {
          getpendingDocData(0);
          setMoalopen(false);
        } else {
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message ?? "Something went wrong!");
        console.log("Document approval api fail", err);
      })
      .finally(() => {
        setLoadingForDisapprove(false);
      });
  };

  const onClickDownloadDocs = (item, index) => {
    setLoadingDownload(index);
    const payload = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
      ShipmentID: item?.ParentId ?? "",
      DocType: "HBL",
      DocumentName: "",
      DocumentDate: "",
      DocumentPk: item.PK ?? "",
    };
    instance
      .post(API_PATH.DOWNLOAD_SHIPMENT_DOCS, payload)
      .then((res) => {
        if (res?._IsSuccess && res?._Data?.ImageDataVC) {
          const downloadLink = document.createElement("a");
          const prefix = genrateDownloadLink(res?._Data?.SC_FileName);
          downloadLink.href = `${prefix}${res?._Data?.ImageDataVC}`;
          if (prefix) {
            downloadLink.download = res?._Data?.SC_FileName ?? "";
            downloadLink.click();
          } else {
            toast.error("Invalid document found!");
          }
        } else {
          toast.error("No data found!");
        }
      })
      .catch((err) => {
        console.log("download cargo docs error", err);
      })
      .finally(() => {
        setLoadingDownload(-1);
      });
  };
  return (
    <div className="approved-sections" id="approved-div">
      {moalopen ? (
        <DocModal
          LoadingForDisapprove={LoadingForDisapprove}
          DocApproval={DocApproval}
          moalopen={moalopen}
          setMoalopen={setMoalopen}
          currentDoc={currentDoc}
          title={type === 3 ? "Dis-Approved Reason" : "Approved Reason"}
        />
      ) : null}
      <div className="container-fluid">
        {loading ? (
          <div className="skeleton-type">
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
          </div>
        ) : (
          <>
            {pendingDocs.length ? (
              <>
                {pendingDocs.map((item, index) => {
                  return (
                    <div className="row  pending-section" key={index}>
                      <div className="d-flex justify-content-end">
                        {copied === index ? (
                          <p className="mb-0">Copied ! </p>
                        ) : null}

                        <div title="Copy URL" className="copyIcons">
                          <ContentCopyIcon
                            title={"Copy URL"}
                            style={{
                              cursor: "pointer",
                            }}
                            className={copied === index ? "copybutton" : ""}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `https://mw.sflhub.com/ASTrackingV2/DocumentTracking/${
                                  item?.PK ?? ""
                                }`
                              );
                              setCopied(index);
                              setTimeout(() => {
                                setCopied(false);
                              }, 300);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <p className="pending-color first-p-of-payment">
                          House number:{" "}
                          <span>{item?.HouseBillNumber ?? "N/A"}</span>
                        </p>
                        <p className="approved-color first-p-of-payment">
                          Shipment number:{" "}
                          <span>{item?.ParentId ?? "N/A"}</span>
                        </p>
                      </div>
                      <div className="col-12 col-lg-7 col-md-8">
                        <div className="conatiner-fluid">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="pending-color m-0">Data de Saida</p>
                              <p className="pending-date  m-0">
                                {Date(item?.ETD ?? null)}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="pending-color  m-0">
                                Data de chegada
                              </p>
                              <p className="pending-date  m-0">
                                {Date(item?.ETA ?? null)}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="pending-color  m-0">
                                Data limite para aprovação
                              </p>
                              <p className="pending-date  m-0">
                                {item?.DeadlineForApprovalDate ?? "N/A"}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              {loadingDownload === index ? (
                                <p className="mb-0 d-flex justify-content-center align-items-center">
                                  Downloading...
                                </p>
                              ) : (
                                <div
                                  className="d-flex image-div "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    onClickDownloadDocs(item, index);
                                  }}
                                >
                                  <div className=" image-section ">
                                    <img
                                      src={urlResolver("icon/icon-pdf.svg")}
                                    />
                                    {/* <p>{item?.DataType ?? "N/A"}</p> */}
                                  </div>
                                  <div>
                                    <p className="pending-color">
                                      <span>{item?.FileName ?? "N/A"}</span>
                                    </p>
                                    <p style={{ cursor: "pointer" }}>
                                      Upload:{Date(item?.Date)}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2  col-md-4 col-lg-4 d-flex align-items-center btn-approval">
                        {/* <BtnOutline
                          title={"Approve"}
                          onClick={() => {
                            setType(2);
                            setCurrentDoc(item);
                            setMoalopen(true);
                            // DocApproval(2, item, "");
                          }}
                        /> */}
                        <BtnGreen
                          title={"Approve"}
                          onClick={() => {
                            setType(2);
                            setCurrentDoc(item);
                            setMoalopen(true);
                          }}
                        />
                        <div style={{ marginLeft: "1rem" }} />
                        {/* <BtnOutline
                          title="Disapprove"
                          onClick={() => {
                            setType(3);
                            setCurrentDoc(item);
                            setMoalopen(true);
                          }}
                        /> */}
                        <BtnRed
                          title="Disapprove"
                          onClick={() => {
                            setType(3);
                            setCurrentDoc(item);
                            setMoalopen(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <Nodata text={"Document list not found"} />
            )}
          </>
        )}
        {pendingDocs.length > 9 && pendingDocs.length !== 1 && (
          <div className="d-flex justify-content-center mt-0">
            <BtnOutline
              title={
                loadMore ? (
                  <CircularProgress
                    size="0.7rem"
                    style={{ color: "var(--color-blue1)" }}
                  />
                ) : (
                  // t("ShowMore")
                  "Show More"
                )
              }
              onClick={() => onLoadMore()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Pending;
