import "./App.css";
import { Navigation } from "./routing";
import { Provider } from "react-redux";
import store from "./store/redux/Index";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./store/context/AuthProvider";

function App(props) {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Toaster
          toastOptions={{
            position: "top-center",
          }}
        />
        <Navigation />
      </AuthProvider>
    </Provider>
  );
}

export default App;
