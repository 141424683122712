import React from "react";
import Chart from "chart.js";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ labels, value }) {
  let arr = value[0] + value[1];

  let tempData = [100, 0];

  const data = {
    labels: labels ?? ["AIR", "SEA"],
    datasets: [
      {
        label: "",
        data: value[0] || value[1] ? value : tempData,
        backgroundColor: value[0] || value[1] ? ["#542d89", "#bcd632"] : "grey",
        borderColor: value[0] || value[1] ? ["#13095e", "#dce995"] : "grey",
        borderWidth: 0,
        borderRadius: 50,
        hoverBorderWidth: 0,
        hoverOffset: 0,
        spacing: 10,
        rotation: -30,
        hoverOffset: 0,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: value[0] || value[1] ? true : false,
      },
      datalabels: {
        display: false,
      },
      responsive: false,
      maintainAspectRatio: false,

      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontColor: "#333",
          usePointStyle: true,
        },
      },
    },
    cutout: 110,
  };

  return (
    <div style={{ height: "260px", width: "260px", position: "relative" }}>
      <div
        style={{ position: "absolute", top: "41%", left: "41%" }}
        className="d-flex justify-content-center flex-column mb-0"
      >
        <p
          className="mb-0"
          style={{
            fontSize: "1.625rem",
            color: "var(--color-gray4)",
            fontFamily: "var(--family-bold)",
          }}
        >
          {" "}
          {arr}
        </p>
        <p
          className="mb-0"
          style={{
            fontSize: "1.063rem",
            color: "var(--color-gray4)",
            fontFamily: "var(--family-bold)",
          }}
        >
          TOTAL
        </p>
      </div>
      <Doughnut data={data} options={options} />
    </div>
  );
}

export default DoughnutChart;
