import React, { useState } from "react";
import "./financialoutstanding.css";
import BtnOutline from "../../components/button/BtnOutline";
import { useOutletContext } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DocUploadShipmentSidebar from "./components/DocUploadShipmentSidebar";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
import { useEffect } from "react";
import { CircularProgress, Skeleton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadingIcon from "@mui/icons-material/Downloading";

const FinanceProforma = (props) => {
  const {
    amount,
    outstandingDetails,
    isLoadmore,
    setSidebar,
    setDocData,
    search,
  } = useOutletContext();

  const [performaData, setPerformaData] = useState([]);
  const [performaDocument, setPerformaDocument] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState({
    index: null,
    docIndex: null,
    loading: false,
  });
  const { t } = props;
  console.log(useOutletContext(), "useOutletContext");
  useEffect(() => {
    getFinanceProforma();
  }, []);

  const getFinanceProforma = () => {
    setDataLoading(true);
    const params = {
      ParentId: search.type === "Shipment Number" ? search.value : "",
      HblNumber: search.type === "HouseBill" ? search.value : "",
    };
    instance
      .post(API_PATH.FINANCE_PERFORMA, params)
      .then((res) => {
        setPerformaData(res);
        setDataLoading(false);
      })
      .catch((err) => {
        setErrorMsg(true);
        setDataLoading(false);
        console.log("err", err);
      });
  };

  const getPerformaDocument = (
    parentID,
    documentPK,
    companyCode,
    index,
    docIndex
  ) => {
    const circularProgressStart = {
      ...loading,
      index: index,
      docIndex: docIndex,
      loading: true,
    };
    setLoading(circularProgressStart);
    const params = {
      ParentId: parentID,
      DocumentPk: documentPK,
      CompanyCode: companyCode,
    };
    instance
      .post(API_PATH.DOWNLOAD_FINANCE_PERFORMA, params)
      .then((res) => {
        const pdf = res?._Data.ImageDataVC;
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `${res?._Data.SC_FileName}`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        const circularProgressEnd = {
          ...loading,
          index: null,
          docIndex: null,
          loading: false,
        };
        setLoading(circularProgressEnd);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // return <></>;
  return (
    <div key={Math.random() * 99999}>
      {dataLoading ? (
        <div className="skeleton-type list-last-updates">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
            return (
              <Skeleton
                variant="rectangular"
                height={100}
                style={{
                  width: "100%",
                  margin: "   3px auto",
                  borderRadius: " 5px",
                  marginTop: "20px",
                }}
              />
            );
          })}
        </div>
      ) : (
        <>
          {" "}
          {performaData?._Data ? (
            <>
              {" "}
              {performaData?._Data.map((indvData, index) => {
                const shipmentID = indvData.ParentId;

                const docData = indvData.DcocumentCollection.map((indvDoc) => {
                  const houseBill = indvDoc.HblNumber;
                  const parentID = indvDoc.ParentId;
                  const debtorCode = indvDoc.DebtorCode;
                  const documentID = indvDoc.Description;
                  const documentPK = indvDoc.PK;
                  const companyCode = indvDoc.CompanyCode;
                  return {
                    houseBill,
                    debtorCode,
                    documentID,
                    parentID,
                    documentPK,
                    companyCode,
                  };
                  // setDataToMap({ ...dataToMap, dataToDisplay });
                  // return { ...dataToDisplay };
                });

                return (
                  <>
                    <div
                      className="container-fluid"
                      key={Math.random() * 999999}
                    >
                      <div className="row  payment-section">
                        <div className="col-12">
                          <div className="conatiner-fluid">
                            <div className="row flex-wrap justify-content-between">
                              <div
                                className="col-lg-1 col-xl-1 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Shipment ID</p>
                                <p
                                  className="ellipsisTable"
                                  //   title={shipmentID ?? "N/A"}
                                >
                                  {shipmentID ?? "N/A"}
                                </p>
                              </div>
                              <div
                                className="col-lg-1 col-xl-1 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">
                                  {t("House Bill of Lading")}
                                </p>
                                <p
                                //   title={docData.houseBill ?? "N/A"}
                                >
                                  {docData[0].houseBill ?? "N/A"}
                                </p>
                              </div>
                              {/* <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #1</p>
                                <p
                                  style={{ cursor: "pointer" }}
                                  //   title={docData[0].documentID ?? "N/A"}
                                  onClick={() =>
                                    getPerformaDocument(
                                      docData[0].parentID,
                                      docData[0].documentPK,
                                      docData[0].companyCode,
                                      index
                                    )
                                  }
                                >
                                  {docData[0]?.documentID
                                    ? docData[0]?.documentID
                                    : "N/A"}{" "}
                                  {loading.loading &&
                                  index === loading.index ? (
                                    <CircularProgress
                                      size="15px"
                                      style={{
                                        color: "var(--color-loader)",
                                      }}
                                    />
                                  ) : (
                                    <FileDownloadIcon />
                                  )}
                                </p>{" "}
                              </div> */}

                              {/* <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #2</p>
                                <p
                                // title={docData[1].documentID ?? "N/A"}
                                >
                                  {docData[1]?.documentID
                                    ? docData[1]?.documentID
                                    : "N/A"}
                                </p>
                              </div> */}
                              <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #1</p>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    getPerformaDocument(
                                      docData[0].parentID,
                                      docData[0].documentPK,
                                      docData[0].companyCode,
                                      index,
                                      0
                                    )
                                  }
                                >
                                  {docData[0]?.documentID ? (
                                    <>
                                      {docData[0].documentID}
                                      {loading.loading &&
                                      loading.index === index &&
                                      loading.docIndex === 0 ? (
                                        <CircularProgress
                                          size="15px"
                                          style={{
                                            color: "var(--color-loader)",
                                          }}
                                        />
                                      ) : (
                                        <FileDownloadIcon />
                                      )}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #2</p>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    getPerformaDocument(
                                      docData[1].parentID,
                                      docData[1].documentPK,
                                      docData[1].companyCode,
                                      index,
                                      1
                                    )
                                  }
                                >
                                  {docData[1]?.documentID ? (
                                    <>
                                      {docData[1].documentID}
                                      {loading.loading &&
                                      loading.index === index &&
                                      loading.docIndex === 1 ? (
                                        <CircularProgress
                                          size="15px"
                                          style={{
                                            color: "var(--color-loader)",
                                          }}
                                        />
                                      ) : (
                                        <FileDownloadIcon />
                                      )}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #3</p>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    getPerformaDocument(
                                      docData[2].parentID,
                                      docData[2].documentPK,
                                      docData[2].companyCode,
                                      index,
                                      2
                                    )
                                  }
                                >
                                  {docData[2]?.documentID ? (
                                    <>
                                      {docData[2].documentID}
                                      {loading.loading &&
                                      loading.index === index &&
                                      loading.docIndex === 2 ? (
                                        <CircularProgress
                                          size="15px"
                                          style={{
                                            color: "var(--color-loader)",
                                          }}
                                        />
                                      ) : (
                                        <FileDownloadIcon />
                                      )}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #4</p>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    getPerformaDocument(
                                      docData[3].parentID,
                                      docData[3].documentPK,
                                      docData[3].companyCode,
                                      index,
                                      3
                                    )
                                  }
                                >
                                  {docData[3]?.documentID ? (
                                    <>
                                      {docData[3].documentID}
                                      {loading.loading &&
                                      loading.index === index &&
                                      loading.docIndex === 3 ? (
                                        <CircularProgress
                                          size="15px"
                                          style={{
                                            color: "var(--color-loader)",
                                          }}
                                        />
                                      ) : (
                                        <FileDownloadIcon />
                                      )}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </p>
                              </div>
                              {/* <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #3</p>
                                <p
                                //   title= {docData[2].documentID ?? "N/A"}
                                >
                                  {docData[2]?.documentID
                                    ? docData[2]?.documentID
                                    : "N/A"}
                                </p>
                              </div> */}
                              {/* <div
                                className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-xs-4"
                                style={{ minWidth: "12rem" }}
                              >
                                <p className="payment-color">Document #4</p>
                                <p
                                //   title={docData[3].documentID ?? "N/A"}
                                >
                                  {docData[3]?.documentID
                                    ? docData[3]?.documentID
                                    : "N/A"}
                                </p>
                              </div> */}
                              <div
                                className="col-lg-1 col-xl-1 col-md-4 col-sm-4 col-xs-4 d-flex justify-content-center align-items-center"
                                style={{ minWidth: "12rem" }}
                              >
                                <div>
                                  <BtnOutline
                                    onClick={() => {
                                      setSidebar(true);
                                      // setDocData(item);
                                    }}
                                    title={t("Show more")}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ); })} */}
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <div
              style={{
                width: "100%",
                height: "20vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <h2>No Document Available</h2>
            </div>
          )}
        </>
      )}

      {/* ) : (
      <Nodata />
      )} */}
    </div>
  );
};

export default withTranslation()(FinanceProforma);
// export default FinanceProforma;
