import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import BtnOutline from "../../../components/button/BtnOutline";
import "../process.css";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/interceptor";
import Nodata from "../../../components/Nodata";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Date } from "../../../utils/moment";
import toast from "react-hot-toast";
import { genrateDownloadLink } from "../../../utils/utility";

const Payment = ({ t, boldocs }) => {
  const location = useLocation();
  const [length, setLength] = useState(2);
  const [showmore, setShowmore] = useState(true);
  const [paymentdocs, setPaymentdocs] = useState([]);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   getPaymentDocs();
  // }, [location.state.item.ShipmentID]);

  const clickShowmore = () => {
    if (length === 2) {
      setLength(paymentdocs.length);
    } else {
      setLength(2);
    }
  };

  // const getPaymentDocs = () => {
  //   setLoading(true);
  //   const payload = {
  //     SortColumnName: "",
  //     SortDirection: "",
  //     CurrentPage: 0,
  //     PageSize: 10,
  //     ShipmentID: location?.state?.item?.ShipmentID ?? "",
  //     DocType: "",
  //   };
  //   instance
  //     .post(API_PATH.GET_PAYMENT_DOC_SHIPMENT, payload)
  //     .then((res) => {
  //       if (res._IsSuccess) {
  //         if (res._Data && res._Data.length) {
  //           res._Data.map((item) => {
  //             item.SC_ImageDataHasValue = false;
  //           });
  //         }
  //         setPaymentdocs(
  //           res._Data.sort((a, b) => {
  //             return new Date(b.SC_Date) - new Date(a.SC_Date);
  //           })
  //         );
  //       } else {
  //         setPaymentdocs([]);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setPaymentdocs([]);
  //       console.log("shipment payment docs api fail", err);
  //       setLoading(false);
  //     });
  // };

  const onClickDownloadDocs = (item, index) => {
    if (!item.SC_ImageDataHasValue) {
      item.SC_ImageDataHasValue = true;
      const arr = [...paymentdocs];
      arr[index] = { ...item };
      setPaymentdocs([...arr]);
      const payload = {
        OrgCodes: "",
        EmailId: "",
        SortColumnName: "",
        SortDirection: "",
        CurrentPage: 0,
        PageSize: 10,
        ShipmentID: location?.state?.item?.ShipmentID ?? "",
        DocType: item?.SC_DocType ?? "",
        DocumentName: item?.SC_FileName ?? "",
        DocumentDate: item?.SC_Date ?? "",
        DocumentPk: item?.SC_PK ?? "",
      };
      instance
        .post(API_PATH.DOWNLOAD_SHIPMENT_DOCS, payload)
        .then((res) => {
          if (res?._IsSuccess && res?._Data?.ImageDataVC) {
            const downloadLink = document.createElement("a");
            const prefix = genrateDownloadLink(item?.SC_FileName);
            downloadLink.href = `${prefix}${res?._Data?.ImageDataVC}`;
            // }
            if (prefix) {
              downloadLink.download = item?.SC_FileName ?? "";
              downloadLink.click();
            } else {
              toast.error("Invalid document found!");
            }
          } else {
            toast.error("No data found!");
          }

          item.SC_ImageDataHasValue = false;
          const arr = [...paymentdocs];
          arr[index] = { ...item };
          setPaymentdocs([...arr]);
        })
        .catch((err) => {
          console.log("download cargo docs error", err);
        });
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={110}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      ) : (
        <></>
        // <div className="payment">
        //   <p className="color-grey payment-heading">{t("PaymentDocuments")}</p>
        //   <div className="div-details-payments documents">
        //     {boldocs.length ? (
        //       boldocs.slice(0, length).map((item, index) => {
        //         return (
        //           <div className="div-details-child" key={index}>
        //             <p className="color-grey">{item.SC_DocType}</p>
        //             <p
        //               className="color-blue2"
        //               style={{ cursor: "pointer" }}
        //               onClick={() => onClickDownloadDocs(item)}
        //             >
        //               <PictureAsPdfOutlinedIcon />
        //               {item.SC_ImageDataHasValue ? (
        //                 <span>Downloading</span>
        //               ) : (
        //                 <span>{item.SC_FileName}</span>
        //               )}
        //             </p>
        //             <p className="payment-data">
        //               Uploaded: {Date(item?.SC_Date ?? null)}
        //             </p>
        //           </div>
        //         );
        //       })
        //     ) : (
        //       <Nodata/>
        //     )}
        //   </div>

        //   {paymentdocs && paymentdocs.length > 2 ? (
        //     <div className="d-flex justify-content-end buttonline">
        //       <BtnOutline
        //         onClick={() => clickShowmore()}
        //         title={length <= 2 ? t("ShowMore") : t("ShowLess")}
        //       ></BtnOutline>
        //     </div>
        //   ) : null}
        // </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(Payment));
