import { Skeleton } from "@mui/material";
import React from "react";
import "../team.css";

const SkeletonTeams = (props) => {
  return (
    <div className="user-div container-fluid">
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          marginTop: "1rem",
          height: "8rem",
          borderRadius: "1rem",
        }}
      />
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          marginTop: "1rem",
          height: "8rem",
          borderRadius: "1rem",
        }}
      />
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          marginTop: "1rem",
          height: "8rem",
          borderRadius: "1rem",
        }}
      />
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          marginTop: "1rem",
          height: "8rem",
          borderRadius: "1rem",
        }}
      />
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          marginTop: "1rem",
          height: "8rem",
          borderRadius: "1rem",
        }}
      />
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          marginTop: "1rem",
          height: "8rem",
          borderRadius: "1rem",
        }}
      />
    </div>
  );
};

export default SkeletonTeams;
