export const API_PATH = {
  FINANCE_PERFORMA: "api/v1/Shipment/getproformadocuments",
  DOWNLOAD_FINANCE_PERFORMA: "api/v1/Shipment/downloadproformadocument",
  LOGIN: "/api/v1/auth/login",
  LOGIN_CONTINUE: "/api/v1/auth/continue",
  LOGOUT: "/api/v1/auth/logout",
  FORGOT_PASSWORD: "",
  GET_PERMISSION: "/api/v1/MYTeam/getUserPermisions",
  EDIT_USER: "/api/v1/MYTeam/editUser",
  DELETE_USER: "/api/v1/MYTeam/deleteUser",
  GET_USER_DETAILS: "/api/v1/MYTeam/getUsersDetail",
  GET_RESET_PASSWORD_LINK: "/api/v1/User/passwordResetOrCreate?emailID=",
  VERIFY_LINK: "/api/v1/User/verifyResetLink?guid=",
  GENERATE_NEW_PASSWORD: "/api/v1/User/resetPassword",
  CREATE_NEW_USER: "/api/v1/MYTeam/addUser",
  CHECK_PASSWORD: "/api/JWTToken/checkPassword?username=",
  SEND_TOKEN: "/api/v1/User/loginOtp?emailId=",
  VERIFY_OTP: "/api/OtpToken/verifyToken?otp=",
  GET_SINGLE_USER: "/api/v1/MYTEAM/getUserDetailBasedOnOrg",
  GET_SHIPMENT_LIST: "/api/v1/Shipment/getshipmentList",
  GET_SHIPMENT_DOCS: "/api/v1/Shipment/getDocumentList",
  GET_SHIPMENT_DETAILS: "/api/v1/Shipment/getshipmentDetail",
  GET_SHIPMENT_COUNT: "/api/v1/Shipment/getShipmentStatusCount",
  GET_ORDER_LIST: "/api/v1/order/getorderList",
  GET_ORDER_DETAILS: "/api/v1/Order/getorderDetail",
  GET_ORDER_DOCS: "/api/v1/Order/getorderDocumentList",
  GET_OUTSTANDING: "/api/v1/Finance/getOutStanding",
  GET_OURSTANDING_GROUPING: "/api/v1/Finance/OutStandingGrouping",
  DOWNLOAD_SHIPMENT_DOCS: "/api/v1/Shipment/getShipDocumentData",
  GET_PAYMENT_DOC_SHIPMENT: "/api/v1/Shipment/getShipmentBoletoDocsList",
  GET_RECIVABLE_DOC_SHIPMENT:
    "/api/v1/Shipment/GetShipmentPaymentReceivableDocumets",
  GET_ORDER_MILESTONE: "/api/v1/Order/getorderMilstones",
  GET_DEMURRAGE: "/api/v1/Demmurage/getDemmurage",
  GET_SHIPMENT_FOR_DASHBOARD: "/api/v1/Shipment/getshipmentForDashboard",
  GET_CE_MARCENTE_FOR_DASHBOARD: "/api/v1/Shipment/CEMerchanteList",
  GET_EMPTY_RETURN_COUNT: "/api/v1/Shipment/getShipmentEmptyReturn",
  GET_DASHBOARD_OURSTANDING: "/api/v1/Shipment/getOutstandingByCurrency",
  GET_MAP_COORDINATE_PROCESS: "/api/v1/Location/ShipmentGeoCoordinates",

  GET_SHIPMENT_STATISCTICS: "/api/v1/ShipmentAnalytics/GetShipmentStatisctics",
  GET_INCOTERM_ANALYTICS: "/api/v1/ShipmentAnalytics/GetIncotermAnalytics",
  MILESTONE_PROCESS: "/api/v1/Shipment/getShipmentRoutes?shipmentId=",
  GET_THRESOLD_SETTINGS: "/api/v1/SettingsAnalytics/GetThreshHoldSettings",
  SET_THRESOLD: "/api/v1/SettingsAnalytics/SaveThreshHoldSettings",
  GET_VOLUME_STATISTICS: "/api/v1/ShipmentAnalytics/GetVolumeStatisctics",
  GET_GENERAL_STATISTICS:
    "/api/v1/ShipmentAnalytics/GetGeneralShipmentStaticsBasedOnCarrier",
  GET_DEADLINE_ANALYSIS:
    "api/v1/ShipmentAnalytics/GetAnalisesChartDataCalculated",
  GET_COLUMNS: "/api/v1/SettingsAnalytics/GetColumns",
  GET_COLUMN_FOR_DEMURAGE: "/api/v1/Demmurage/GetReportColumns",
  GET_COLUMN_FOR_FINANCE: "/api/v1/Finance/GetReportColumns",
  GET_PERFORMA_FOR_FINANCE: "api/v1/Shipment/getproformadocuments",
  GET_COLUMN_FOR_SHIPMENT: "/api/v1/Shipment/GetReportColumns",
  GET_DEMURRAGE_REPORT_DATA: "/api/v1/Demmurage/GetReportData",
  GET_FINANCE_REPORT_DATA: "/api/v1/Finance/GetReportData",
  GET_SHIPMENT_REPORT_DATA: "/api/v1/Shipment/GetReportData",
  GET_DOCUMENT_DATA: "/api/v1/Shipment/GetApprovalDocument",
  DOC_STATUS_CHANGED: "/api/v1/Shipment/DocumentStatusChange",
  DOCUMENT_FROM_URL: "/DocumentTracking/",
  STATUS_CHANGE_FROM_URL: "/api/v1/Shipment/StatusChangeFromPublicUrl",
  GET_ARBOLINV_DOCUMENTS: "/api/v1/Finance/GetARBOLINVDocuments",
  GET_DOCUMENT_NOTA_LINK: "/api/v1/Finance/GetDocumentNotaLink?ShipmentId=",
  DOWNLOAD_INVOICE_DOCS: "/api/v1/Finance/GetFinanceDocumentData",
  GET_NOTIFICATION: "/api/v1/Shipment/getshipmentListForNotification",
  CARBONDATA: "api/v1/CarbonEmission/Calculate/lune",
  CARBONREPORT: "/api/v1/CarbonEmission/GetCarbonEmissionData",
};
