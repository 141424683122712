import React, { useState, useEffect } from "react";
import "./team.css";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { routeConstant } from "../../routing";
import DeleteModal from "./DeleteModal";
import ButtonIcon from "../../components/button/ButtonIcon";
import { basename } from "../../Config";
import { getMyTeam } from "../../store/redux/actions/MyTeamAction";
import { useDispatch, useSelector } from "react-redux";
import Nodata from "../../components/Nodata";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
import SkeletonTeams from "./components/SkeletonTeams";
import toast from "react-hot-toast";
import BtnOutline from "../../components/button/BtnOutline";
import { CircularProgress } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Search } from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import urlResolver from "../../utils/urlResolver";

function Team(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    data: {},
  });
  const [search, setSearch] = useState({
    type: "",
    value: "",
  });

  const [pageSize, setPageSize] = useState(0);
  const { loading, myTeam, isLoadMore, totalCount } = useSelector(
    (state) => state.myteam
  );
  useEffect(() => {
    const urlsearchparams = new URLSearchParams(location.search);
    const searchType = urlsearchparams.get("type");
    const searchdata = urlsearchparams.get("value");
    const obj = { ...search };
    obj.type = searchType;
    obj.value = searchdata;
    setSearch(obj);
    if (searchType && searchdata) {
      getUsersList(searchType, searchdata);
    } else {
      getUsersList();
    }
  }, [location]);

  const getUsersList = (searchType, searchdata, count = 0) => {
    const body = {
      OrgCodes: "",
      EmailId: searchType === "Email" ? searchdata : "",
      Name: searchType === "Name" ? searchdata : "",
      SortColumnName: "OrganisationCode",
      SortDirection: "Asc",
      CurrentPage: count,
      PageSize: 20,
    };
    dispatch(getMyTeam(body));
  };

  const onLoadMore = () => {
    const count = pageSize + 1;
    getUsersList("", "", count);
    setPageSize(count);
  };

  const onClickEdit = (item) => {
    navigate(routeConstant.EDITUSER, { state: item });
  };

  const onClickDelete = () => {
    const body = { ...deleteModal.data };
    instance
      .post(API_PATH.DELETE_USER, body)
      .then((res) => {
        if (res._IsSuccess) {
          toast.success(res._Message);
          setDeleteModal({
            ...deleteModal,
            show: false,
            data: {},
          });
        } else {
          toast.error(res._Message);
        }
      })
      .catch((err) => {
        setDeleteModal({
          ...deleteModal,
          show: false,
          data: {},
        });
        console.log("delete user error", err);
      });
  };
  const onClickSearch = () => {
    if (search.type && search.value) {
      const params = { type: search.type, value: search.value };
      navigate({
        pathname: routeConstant.TEAM,
        search: `?${createSearchParams(params)}`,
      });
    } else {
      toast.error("Search type and value both is required");
    }
  };
  const onSearch = (e) => {
    if (e.key === "Enter") {
      if (search.type && search.value) {
        const params = { type: search.type, value: search.value };
        navigate({
          pathname: routeConstant.TEAM,
          search: `?${createSearchParams(params)}`,
        });
      } else {
        toast.error("Search type and value both is required");
      }
    }
  };

  const { permissions } = useSelector((state) => state.permission);
  const { t } = props;
  return (
    <>
      {deleteModal.show ? (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
          onClickDelete={onClickDelete}
        />
      ) : null}
      <div className="my-team-main">
        <>
          <div className="container-fluid my-team-first">
            <div className="row">
              <div className="col-md-12  order-1 order-md-0 col-6 section-first">
                <p> {t("MyTeam")}</p>
              </div>
            </div>
            <div className="row d-flex justify-content-between">
              <div className="col-md-6 col-xs-12   order-3 order-md-0 section-first-header">
                <div className=" menu-section d-flex">
                  <div className="select ">
                    <select
                      className="title-select"
                      value={search.type}
                      onChange={(event) => {
                        const obj = { ...search };
                        obj.type = event.target.value;
                        obj.value = "";
                        setSearch(obj);
                      }}
                    >
                      <option value="" disabled selected hidden>
                        {t("Select")}
                      </option>
                      <option value="Name">{t("Name")}</option>
                      <option value="Email">{t("Mail")}</option>
                    </select>
                  </div>
                  <div className="search-div ">
                    <input
                      className="input-search"
                      type="text"
                      placeholder={t("Search")}
                      value={search.value}
                      onKeyPress={onSearch}
                      onChange={(event) => {
                        const obj = { ...search };
                        obj.value = event.target.value;
                        setSearch(obj);
                      }}
                    />
                    {search.value ? (
                      <CloseOutlinedIcon
                        onClick={() => {
                          const obj = { ...Search };
                          obj.type = "";
                          obj.value = "";
                          setSearch(obj);
                          navigate(routeConstant.TEAM);
                        }}
                      />
                    ) : null}
                    <SearchOutlinedIcon
                      onClick={() => onClickSearch()}
                      className="search-icon"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xs-12 buttn-column order-2 order-md-0 d-flex justify-content-end">
                {/* {myTeam.length ? (
                  <ButtonIcon
                    onClick={() => {
                      navigate(routeConstant.NEWUSER);
                    }}
                    title={t("Newuser")}
                  ></ButtonIcon>
                ) : null} */}
              </div>
            </div>
          </div>
          {loading ? (
            <SkeletonTeams />
          ) : (
            <>
              <div className="user-div container-fluid">
                {myTeam.length ? (
                  myTeam.map((i, index) => {
                    return (
                      <div className="row section-second" key={index}>
                        <div className="col-12 col-md-7 p-0">
                          <div className="container-fluid ">
                            <div className="row ">
                              <div className="col-6 d-flex align-items-center">
                                <p className="color-section m-0 me-2">
                                  {t("Name")}:
                                </p>
                                <p className=" m-0">
                                  {i?.ContactName ?? "N/A"}
                                </p>
                              </div>
                              <div className="col-6 ">
                                <p className="color-section-other m-0">
                                  {t("Mail")}:
                                </p>
                                <p className=" m-0 ">
                                  {i?.EmailId.toLowerCase() ?? "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-5 p-0 ">
                          <div className="container-fluid ">
                            <div className="row align-items-center">
                              <div className="col-6">
                                <p className="color-section-other m-0">
                                  {t("MainAccount")}:
                                </p>
                                <p className=" m-0">{i.OrgCodes}</p>
                              </div>
                              <div className=" col-6 button-section ">
                                <button
                                  className="edit-btn m-3"
                                  onClick={() => onClickEdit(i)}
                                >
                                  {t("edit")}
                                  <img
                                    src={urlResolver("icon/icon-pencil.svg")}
                                    alt=""
                                  />
                                </button>
                                {/* <button
                                  className="delete-btn"
                                  onClick={() => {
                                    setDeleteModal({
                                      ...deleteModal,
                                      show: true,
                                      data: { i },
                                    });
                                  }}
                                >
                                  {t("delete")}
                                  <img
                                    src={`${basename}/icon/icon-trash-can.svg`}
                                    alt=""
                                  />
                                </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Nodata text={t("NodataFound")} />

                  // <h2>No data</h2>
                )}
                {myTeam.length < totalCount ? (
                  <div className="show-more-teams">
                    <BtnOutline
                      title={
                        isLoadMore ? (
                          <CircularProgress
                            size="0.7rem"
                            style={{ color: "var(--color-blue)" }}
                          />
                        ) : (
                          t("ShowMore")
                        )
                      }
                      disabled={isLoadMore}
                      onClick={onLoadMore}
                    />
                  </div>
                ) : null}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
}

export default withTranslation()(Team);
