import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { AuthContext } from "../../store/context/AuthProvider";
import { routeConstant } from "../utils/routeConstant";
import { User } from "../../store/redux/actions/UserAction";

function AppInitialize() {
  const Auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // if (Auth.userAuth) {
    //   dispatch(User.permissions())
    //     .then((res) => {
    //       // navigate(routeConstant.APP_INIT);
    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //       console.log("permission dispatch error ", err);
    //     });
    // } else {
    //   setLoading(false);
    // }
  }, []);

  if (loading) {
    <Skeleton
      variant="rectangular"
      height={50}
      style={{ width: "100%", marginBottom: "1rem" }}
    />;
  }

  if (Auth.userAuth && Auth.role === "Admin") {
    return <Navigate to={routeConstant.TEAM} />;
  } else if (Auth.userAuth) {
    return <Navigate to={routeConstant.DASHBOARD} />;
  } else {
    return <Navigate to={routeConstant.LOGIN} />;
  }
}

export default AppInitialize;
