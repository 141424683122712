import React, { useEffect, useState } from "react";
import "./styles.css";
import { withTranslation } from "react-i18next";
import { TextField, Skeleton } from "@mui/material";
import CarbonBarChart from "./chart/CarbonBarChart";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/interceptor";

function Corbon(props) {
  const [loading, setLoading] = useState(false);
  const [carbonData, setCarbonData] = useState([]);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const { t } = props;
  const getCarbonOrgDes = () => {
    setLoading(true);
    const url = `${API_PATH.CARBONREPORT}?origin=${origin}&destination=${destination}`;
    instance
      .get(url)
      .then((res) => {
        setCarbonData(res._Data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const resetFieldsAndFetch = () => {
    setOrigin("");
    setDestination("");
    getCarbonOrgDes();
  };

  useEffect(() => {
    resetFieldsAndFetch();
  }, []);
  return (
    <div>
      <div>
        <p className="head-trans"> CO₂ Emissions</p>
      </div>
      <div className="Transport-section d-flex  flex-column flex-md-row  ">
        <div
          className="d-flex  calender-section  flex-column flex-md-row justify-content-between justify-content-md-none select-section"
          style={{ width: "40%" }}
        >
          {/* <TextField
            id="standard-basic"
            label="Origin"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
            fullWidth
            variant="standard"
          />
           <TextField
            id="standard-basic"
            label="Destination"
            value={destination}
            variant="standard"
            onChange={(e) => setDestination(e.target.value)}
            fullWidth
            style={{ fontSize: "13px", fontWeight: "700" }}
          /> */}
          <input
            className="input-search"
            type="text"
            placeholder={t("Origin")}
            value={origin}
           
            onChange={(e) => setOrigin(e.target.value)}
            style={{ fontSize: "13px", fontWeight: "700" }}
          />
          <input
            className="input-search"
            type="text"
            value={destination}
            placeholder={t("Destination")}
            
            onChange={(e) => setDestination(e.target.value)}
            style={{ fontSize: "13px", fontWeight: "700" }}
          />
        </div>
        <div className=" transport-mid-car d-flex  flex-md-grow-0 flex-grow-1  ">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button className="btn-filter" onClick={getCarbonOrgDes}>
                {t("Search")}
              </button>
            </div>
            <div>
              <button
                className="btn-filter btn-reset"
                onClick={resetFieldsAndFetch}
              >
                &gt; {t("Reset")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {!loading ? (
        <div className="container-fluid main-container-chart">
          <div className="main-container-chart-child">
            {carbonData && carbonData.length > 0 && carbonData.some(data => data.CO2Amount > 0)  ? (
              <CarbonBarChart carbonData={carbonData} />
            ) : (
              <div class="div-details-payments documents">
                <div
                  class="no-data "
                  style={{ color: "var(--color-blue2)", paddingTop: "9rem" }}
                >
                  No data Found!
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="skeleton-type">
          <Skeleton style={{ height: "50rem" }} />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Corbon);
