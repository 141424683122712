import React from 'react'
import "./button.css";
function BtnFilled(props) {
    const { onClick = () => {}, title = "" } = props;
  return (
    <div> <button onClick={onClick} type="button" className="btn-filled-section ">
    {title}
  </button></div>
  )
}

export default BtnFilled