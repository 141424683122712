import React, { useState, useEffect } from "react";
import DoughnutChart from "./chart/DoughnutChart";
import HorizontalBarChart from "./chart/HorizontalBarChart";
import "./styles.css";
import { basename } from "../../../Config";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { Skeleton } from "@mui/material";
import { withTranslation } from "react-i18next";
import urlResolver from "../../../utils/urlResolver";

function Overview(props) {
  const [loading, setLoading] = useState(false);
  const [statusDataset, setStatusDataset] = useState([
    {
      img: `icon/icon-card-1.svg`,
      title: "PreLoaded",
      key: "PreloadedShipments",
      color: "var(--color-blue1)",
      num: 0,
    },
    {
      img: `icon/icon-card-2.svg`,
      title: "Loaded",
      key: "LoadedShipments",
      color: "var(--color-purpe0)",

      num: 0,
    },
    {
      img: `icon/icon-card-3.svg`,
      title: "Arrived",
      key: "ArrivedShipments",
      color: "var(--color-green0)",

      num: 0,
    },
  ]);
  const [modeData, setModeData] = useState({
    value: [0, 0],
    labels: ["AIR", "SEA"],
  });

  const [typeDataset, setTypeDataset] = useState({
    maxValue: 0,
    data: [
      {
        lable: "FCL",
        value: 0,
        key: "TotalFCL",
      },
      {
        lable: "LCL",
        value: 0,
        key: "TotalLCL",
      },
      {
        lable: "LSE",
        value: 0,
        key: "TotalLSE",
      },
    ],
  });

  useEffect(() => {
    getShipmentStatisctics();
  }, []);

  const getShipmentTypeData = (data) => {
    const arr = [...statusDataset];
    arr.map((item) => {
      item.num = data[item.key];
    });
    setStatusDataset([...arr]);
  };

  const getShipmentloadData = (data) => {
    const arr = [...typeDataset.data];
    let maxValue = 0;
    arr.map((item, index) => {
      if (index === 0 || maxValue < data[item.key]) {
        maxValue = data[item.key];
      }
      item.value = data[item.key];
    });
    setTypeDataset({ maxValue: maxValue, data: [...arr] });
  };

  const getModeData = (data) => {
    const arr = [...modeData.value];
    arr[0] = data?.TotalAirShipments ?? 0;
    arr[1] = data?.TotalSeaShipments ?? 0;
    setModeData({ ...modeData, value: [...arr] });
  };

  const getShipmentStatisctics = () => {
    setLoading(true);
    const params = {
      TransportMode: "ALL",
      CNJPNumber: "",
      OriginPortName: "",
      DestinationPortName: "",
      StartDate: "",
      EndDate: "",
      ShipmentType: "",
      IsFilterApplied: true,
      ShipmentMode: "",
    };
    instance
      .post(API_PATH.GET_SHIPMENT_STATISCTICS, params)
      .then((response) => {
        setLoading(false);
        if (response?._Success) {
          getShipmentTypeData(response?._GenericValue ?? {});
          getShipmentloadData(response?._GenericValue ?? {});
          getModeData(response._GenericValue);
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("get shipment statistics api fail", error);
      });
  };
  const { t } = props;
  return (
    <>
      <div>
        <div className="heading">
          <p className="head-trans">{t("GeneralView")}</p>
        </div>
        {loading ? (
          <div className="skeleton-type">
            <Skeleton style={{ height: "48rem" }} />
          </div>
        ) : (
          <div className=" overview d-flex flex-column flex-md-row">
            <div className="head-section ">
              <div className="type">
                <p>{t("Type")}</p>
                <div>
                  <HorizontalBarChart datasets={typeDataset} />
                </div>
              </div>

              <div className="mode">
                {t("Mode")}
                <div className="d-flex justify-content-center align-items-center align-items-md-normal flex-column flex-md-row">
                  <DoughnutChart
                    labels={modeData.labels}
                    value={modeData.value}
                  />
                  <div className="d-flex justify-content-around flex-grow-1 chart-size ">
                    <div className="d-flex flex-column justify-content-center">
                      <img
                        className="ship-icon"
                        src={urlResolver("icon/icon-boat1.svg")}
                        alt=""
                      />
                      <p className="data-sea">{modeData.value[1]}</p>
                      <p className="title-as">{t("Sea")}</p>
                    </div>

                    <div className="d-flex flex-column justify-content-center mt-md-0 mt-10">
                      <img
                        className="ship-icon"
                        src={urlResolver("icon/icon-airplane.svg")}
                        alt=""
                      />
                      <p className="data-air">{modeData.value[0]}</p>
                      <p className="title-as">{t("Air")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="last-sec d-flex flex-md-column flex-row">
              {statusDataset.map((row, index) => (
                <div
                  className="card-overview d-flex flex-column flex-md-row"
                  key={index}
                >
                  <img src={urlResolver(row.img)} alt="warning" />
                  <div className="text-overview">
                    <p className="pre-sec">{t(row.title)}</p>
                    <p style={{ color: row.color }} className="num-sec">
                      {row.num}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default withTranslation()(Overview);
