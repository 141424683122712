import { ACTION_TYPE } from "../constants/actionType";

const PermissionModal = {
  OrgCodes: "",
  EmailId: "",
  Is_WebEmableAccess: "",
  ContactName: "",
  IsPorcess: "",
  IsOrder: "",
  IsFinance: "",
  IsReport: "",
  IsMyTeam: "",
};

const initialState = {
  loading: false,
  permissions: PermissionModal,
};

export const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.PERMISSION_DATA_REQUEST:
      return {
        // ...permissions,
        loading: true,
        permissions: PermissionModal,
      };
    case ACTION_TYPE.PERMISSION_DATA_SUCCESS:
      return {
        // ...permissions,
        loading: false,
        permissions: action.payload._Data,
      };
    case ACTION_TYPE.PERMISSION_DATA_FAIL:
      return {
        loading: false,
        permissions: PermissionModal,
        // ...permissions,
      };

    default:
      return { ...state };
  }
};
