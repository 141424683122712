import { ACTION_TYPE } from "../constants/actionType";

export const orderReducer = (
  state = { Orders: [], loading: false },
  action
) => {
  switch (action.type) {
    case ACTION_TYPE.ORDER_LIST_REQUEST:
      return {
        loading: true,
        Orders: [],
      };
    case ACTION_TYPE.ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        Orders: action.payload?._Data ?? [],
      };
    case ACTION_TYPE.ORDER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        Orders: action.payload,
      };
    default:
      return state;
  }
};
