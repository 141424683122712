import React, { useEffect, useState, useRef, useContext } from "react";
import "./index.css";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "../../routing/utils/routeConstant";
import {
  LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD,
  LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT,
  LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA,
  LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY,
  LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD,
  LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT,
  basename,
} from "../../Config";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
import Nodata from "../../components/Nodata";
import { CircularProgress, Skeleton } from "@mui/material";
import BtnOutline from "../../components/button/BtnOutline";
import { Date as DateFormat } from "../../utils/moment";
import toast from "react-hot-toast";
import { AuthContext } from "../../store/context/AuthProvider";
import moment from "moment";
import urlResolver from "../../utils/urlResolver";
const Dashboard = (props) => {
  const navigate = useNavigate();
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreCe, setLoadMoreCe] = useState(false);
  const [page, setPage] = useState(0);
  const [showMoreError, setShowMoreError] = useState("");
  const [length, setLength] = useState(false);
  const [pageCe, setPageCe] = useState(0);
  const [count, setCount] = useState({ data: [], loading: true });
  const [emptyCount, setEmptyCount] = useState({ data: [], loading: true });
  const [searcheddata, setSearcheddata] = useState([]);
  const [housenumber, setHousenumber] = useState("");
  const [totalItem, setTotalItem] = useState("");
  const [loadingSearched, setLoadingSearched] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;
  const Auth = useContext(AuthContext);
  const totalDashCount = useRef(null);

  const calculateAmmount = (ammount) => {
    const amt = Number(parseFloat(ammount).toFixed(2)).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });
    return amt;
  };
  // Auth.docpending(res?.data?.Data ?? {});
  const [shipmentList, setShipmentList] = useState({
    list: [],
    loading: true,
    TotalCount: 0,
  });
  const [cemercante, setCemercante] = useState({
    list: [],
    loading: true,
    TotalCount: 0,
  });
  const OnClickDropIcon = (item, index) => {
    setOpenCard(index);
  };
  const [openCard, setOpenCard] = useState(null);

  const [outstanding, setOutstanding] = useState({ data: [], loading: true });
  const calculateElapsedTime = (dtime) => {
    let oldTime = new Date(dtime);
    let newDate = moment().format();
    let currentDate = new Date(newDate);
    let elapsedTime = currentDate.getTime() - oldTime.getTime();
    return elapsedTime;
  };

  // ============================================================ APIs============================================================

  const getCeMarcanteData = async (count) => {
    if (count === 0) {
      setCemercante({ list: [], loading: true });
    }
    const params = {
      ShipmentId: "",
      OrgCodes: "",
      EmailId: "",
      OriginPortCode: "",
      DestinationPortCode: "",
      CNPJ: "",
      PONumber: "",
      ShipperCode: "",
      Status: "",
      TransportMode: "",
      Ship: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: count,
      PageSize: 7,
    };
    await instance
      .post(API_PATH.GET_CE_MARCENTE_FOR_DASHBOARD, params, { signal })
      .then((res) => {
        console.log("response in ce mercante", res, res._TotalItem % 7);
        if (res._IsSuccess) {
          if (count === 0) {
            setCemercante({
              list: [...res?._Data],
              loading: false,
              TotalCount: res?._TotalItem,
            });
            localStorage.setItem(
              LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD,
              JSON.stringify({
                data: res?._Data,
                TotalCount: res?._TotalItem,
                time: moment().format(),
              })
            );
          } else {
            setCemercante({
              list: [...cemercante.list, ...res?._Data],
              loading: false,
              TotalCount: res?._TotalItem,
            });
          }
        } else {
          setCemercante({
            list: [],
            loading: false,
            TotalCount: 0,
          });
        }
      })
      .catch((err) => {
        setCemercante({
          list: [...cemercante.list],
          loading: false,
        });
        setShowMoreError(err?.response?.data?._Message);
        console.log("error in ce marcante list api", err);
      })
      .finally(() => {
        setPageCe(count);
        setLoadMoreCe(false);
      });
  };

  const getShipmentsForDashboard = async (count) => {
    if (count === 0) {
      setShipmentList({ list: [], loading: true });
    }
    const params = {
      ShipmentId: "",
      OrgCodes: "",
      EmailId: "",
      OriginPortCode: "",
      DestinationPortCode: "",
      CNPJ: "",
      PONumber: "",
      ShipperCode: "",
      Status: "",
      SortColumnName: "ETA",
      SortDirection: "ASC",
      CurrentPage: count,
      PageSize: 10,
    };
    await instance
      .post(API_PATH.GET_SHIPMENT_FOR_DASHBOARD, params, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          if (count === 0) {
            setShipmentList({
              list: [...res?._Data],
              loading: false,
              TotalCount: res?._TotalItem,
            });
            localStorage.setItem(
              LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD,
              JSON.stringify({
                data: res?._Data,
                TotalCount: res?._TotalItem,
                time: moment().format(),
              })
            );
          } else {
            setShipmentList({
              list: [...shipmentList.list, ...res?._Data],
              loading: false,
              TotalCount: res?._TotalItem,
            });
          }
        } else {
          setShipmentList({
            list: [],
            loading: false,
            TotalCount: 0,
          });
        }
      })
      .catch((err) => {
        setShipmentList({
          list: [],
          loading: false,
          TotalCount: 0,
        });
        console.log("error in shipment list api", err);
      })
      .finally(() => {
        setPage(count);
        setLoadMore(false);
      });
  };

  const getEmpltyReturn = async () => {
    setEmptyCount({ loading: true, data: [] });

    await instance
      .post(API_PATH.GET_EMPTY_RETURN_COUNT, {}, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          setEmptyCount({ data: { ...res._Data[0] }, loading: false });
          localStorage.setItem(
            LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT,
            JSON.stringify({
              data: { ...res._Data[0] },
              time: moment().format(),
            })
          );
        } else {
          setEmptyCount({ data: {}, loading: false });
        }
      })
      .catch(() => {
        setEmptyCount({ data: {}, loading: false });
      });
  };

  const getOutstandingByCurrency = async () => {
    setOutstanding({ ...outstanding, loading: true });
    await instance
      .get(API_PATH.GET_DASHBOARD_OURSTANDING, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          console.log("Get Dashbaord Api success");
          localStorage.setItem(
            LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY,
            JSON.stringify({
              data: [...res._Data],
              time: moment().format(),
            })
          );
          setOutstanding({ data: res._Data, loading: false });
        }
      })
      .catch((err) => {
        console.log("Get Dashboard outstanding api fail", err);
        setOutstanding({ data: [], loading: false });
      });
  };

  const getShipmentStatusCount = async () => {
    setCount({ data: [], loading: true });
    await instance
      .post(API_PATH.GET_SHIPMENT_COUNT, {}, { signal })
      .then((response) => {
        if (response._IsSuccess) {
          const data = response._Data.reverse();
          localStorage.setItem(
            LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT,
            JSON.stringify({ data: data, time: moment().format() })
          );
          modifyData(response._Data);
        } else {
          setCount({ data: [], loading: false });
        }
      })
      .catch((error) => {
        setCount({ data: [], loading: false });
        console.log("error in get shipment count", error);
      });
  };

  const getSearchedResult = async () => {
    setLoadingSearched(true);
    const body = {
      Status: 1,
      SortColumnName: "",
      SortDirection: "",
      HouseBillNumber: housenumber ?? "",
      CurrentPage: 0,
      PageSize: 10,
    };
    // await instance
    //   .post(API_PATH.GET_DOCUMENT_DATA, body, { signal })
    //   .then((res) => {
    //     setLoadingSearched(false);
    //     if (res._IsSuccess) {
    //       setSearcheddata(res?._Data);
    //       localStorage.setItem(
    //         LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA,
    //         JSON.stringify({
    //           data: res?._Data,
    //           totalItem: res?._TotalItem,
    //           time: moment().format(),
    //         })
    //       );
    //       setTotalItem(res?._TotalItem);
    //       Auth.docpending(res?._Data ?? []);
    //     } else {
    //       toast.error(res?._Message ?? "");
    //     }
    //   })
    //   .catch((err) => {
    //     setLoadingSearched(false);
    //     setSearcheddata([]);
    //     console.log("Search docs api fail", err);
    //   });
  };

  // ========================================================= APIs finish =========================================================

  // useEffect(() => {
  //   // getShipmentStatusCount
  //   if (localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT) == null) {
  //     getShipmentStatusCount();
  //   } else {
  //     let time = JSON.parse(
  //       localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT)
  //     );
  //     let elapsedTime = calculateElapsedTime(time.time);
  //     if (elapsedTime >= 300000) {
  //       console.log("getShipmentStatusCount second IF");
  //       getShipmentStatusCount();
  //     } else {
  //       let countData = JSON.parse(
  //         localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT)
  //       );

  //       modifyData(countData.data);
  //     }
  //   }
  //   // getShipmentsForDashboard
  //   if (
  //     localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD) == null
  //   ) {
  //     getShipmentsForDashboard(0);
  //   } else {
  //     let time = JSON.parse(
  //       localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD)
  //     );
  //     let elapsedTime = calculateElapsedTime(time.time);
  //     if (elapsedTime >= 300000) {
  //       getShipmentsForDashboard(0);
  //     } else {
  //       let shipmentListData = JSON.parse(
  //         localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD)
  //       );

  //       setShipmentList({
  //         list: [...shipmentListData.data],
  //         loading: false,
  //       });
  //     }
  //   }
  //   // getEmpltyReturn
  //   if (localStorage.getItem(LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT) == null) {
  //     getEmpltyReturn();
  //   } else {
  //     let time = JSON.parse(
  //       localStorage.getItem(LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT)
  //     );
  //     let elapsedTime = calculateElapsedTime(time.time);
  //     if (elapsedTime >= 300000) {
  //       getEmpltyReturn();
  //     } else {
  //       let EmptyCount = JSON.parse(
  //         localStorage.getItem(LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT)
  //       );
  //       setEmptyCount({ data: EmptyCount.data, loading: false });
  //     }
  //   }
  //   // getOutstandingByCurrency
  //   if (
  //     localStorage.getItem(LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY) ==
  //     null
  //   ) {
  //     getOutstandingByCurrency();
  //   } else {
  //     let time = JSON.parse(
  //       localStorage.getItem(LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY)
  //     );
  //     let elapsedTime = calculateElapsedTime(time.time);
  //     if (elapsedTime >= 300000) {
  //       getOutstandingByCurrency();
  //     } else {
  //       let outstandingCurrency = JSON.parse(
  //         localStorage.getItem(LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY)
  //       );
  //       setOutstanding({ data: outstandingCurrency.data, loading: false });
  //     }
  //   }
  //   // getCeMarcanteData(0);
  //   if (
  //     localStorage.getItem(LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD) == null
  //   ) {
  //     getCeMarcanteData(0);
  //   } else {
  //     let time = JSON.parse(
  //       localStorage.getItem(LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD)
  //     );
  //     let elapsedTime = calculateElapsedTime(time.time);
  //     if (elapsedTime >= 300000) {
  //       getCeMarcanteData(0);
  //     } else {
  //       let ceMercanteData = JSON.parse(
  //         localStorage.getItem(LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD)
  //       );
  //       setCemercante({
  //         list: [...ceMercanteData.data],
  //         loading: false,
  //       });
  //     }
  //   }

  //   if (localStorage.getItem(LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA) == null) {
  //     getSearchedResult();
  //   } else {
  //     let time = JSON.parse(
  //       localStorage.getItem(LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA)
  //     );
  //     let elapsedTime = calculateElapsedTime(time.time);
  //     if (elapsedTime >= 300000) {
  //       getSearchedResult();
  //     } else {
  //       let documentData = JSON.parse(
  //         localStorage.getItem(LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA)
  //       );

  //       setTotalItem(documentData.totalItem);
  //       setSearcheddata(documentData.data);
  //     }
  //   }

  //   return () => {
  //     return controller.abort();
  //   };
  // }, []);

  useEffect(() => {
    const apiCall = async () => {
      // getShipmentStatusCount

      if (
        localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT) == null
      ) {
        getShipmentStatusCount();
      } else {
        let time = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT)
        );
        let elapsedTime = calculateElapsedTime(time.time);
        if (elapsedTime >= 300000) {
          getShipmentStatusCount();
        } else {
          let countData = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT)
          );

          modifyData(countData.data);
        }
      } // getShipmentsForDashboard

      if (
        localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD) == null
      ) {
        await getShipmentsForDashboard(0);
      } else {
        let time = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD)
        );
        let elapsedTime = calculateElapsedTime(time.time);
        if (elapsedTime >= 300000) {
          await getShipmentsForDashboard(0);
        } else {
          let shipmentListData = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD)
          );

          setShipmentList({
            list: [...shipmentListData.data],
            loading: false,
            TotalCount: shipmentListData.TotalCount,
          });
        }
      }

      // getEmpltyReturn
      if (localStorage.getItem(LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT) == null) {
        await getEmpltyReturn();
      } else {
        let time = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT)
        );
        let elapsedTime = calculateElapsedTime(time.time);
        if (elapsedTime >= 300000) {
          await getEmpltyReturn();
        } else {
          let EmptyCount = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT)
          );
          setEmptyCount({ data: EmptyCount.data, loading: false });
        }
      }

      // getOutstandingByCurrency
      if (
        localStorage.getItem(LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY) ==
        null
      ) {
        getOutstandingByCurrency();
      } else {
        let time = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY)
        );
        let elapsedTime = calculateElapsedTime(time.time);
        if (elapsedTime >= 300000) {
          getOutstandingByCurrency();
        } else {
          let outstandingCurrency = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY)
          );
          setOutstanding({ data: outstandingCurrency.data, loading: false });
        }
      }
      // getCeMarcanteData(0);
      if (
        localStorage.getItem(LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD) ==
        null
      ) {
        getCeMarcanteData(0);
      } else {
        let time = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD)
        );
        let elapsedTime = calculateElapsedTime(time.time);
        if (elapsedTime >= 300000) {
          getCeMarcanteData(0);
        } else {
          let ceMercanteData = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD)
          );
          setCemercante({
            list: [...ceMercanteData.data],
            loading: false,
            TotalCount: ceMercanteData.TotalCount,
          });
        }
      }

      if (localStorage.getItem(LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA) == null) {
        getSearchedResult();
      } else {
        let time = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA)
        );
        let elapsedTime = calculateElapsedTime(time.time);
        if (elapsedTime >= 300000) {
          getSearchedResult();
        } else {
          let documentData = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA)
          );

          setTotalItem(documentData.totalItem);
          setSearcheddata(documentData.data);
        }
      }
    };

    apiCall();

    return () => {
      controller.abort();
    };
  }, []);

  const modifyData = (data) => {
    const arr = [{}, {}, {}];
    for (let i = 0; i < data.length; i++) {
      if (data[i].Status === "PreLoad") {
        arr[0] = data[i];
      } else if (data[i].Status === "Loaded") {
        arr[1] = data[i];
      } else if (data[i].Status === "Arrived") {
        arr[2] = data[i];
      }
    }
    setCount({ data: [...arr], loading: false });
  };

  const onClickShowMore = (i) => {
    navigate(routeConstant.PROCESS, {
      state: {
        status: i,
        from: "dashboardseemore",
      },
    });
  };

  const onLoadMore = () => {
    setLoadMore(true);
    const count = page + 1;
    getShipmentsForDashboard(count);
  };

  const onLoadMoreCe = () => {
    setLoadMoreCe(true);
    const count = pageCe + 1;
    getCeMarcanteData(count);
  };

  const { t } = props;
  console.log(
    "cemercante",
    cemercante.list.length,
    cemercante.list.length >= 10
  );
  return (
    <section className="container-section">
      <div className="wrapper-section">
        <div style={{ display: "contents" }}>
          <>
            <>
              <div className="small-card">
                <div className="wapper-card">
                  <div>
                    <img
                      className="small-card-img"
                      src={urlResolver("icon/icon-card-1.svg")}
                      alt=""
                    />
                  </div>
                  <div className="text-card">
                    <h3 className="title-card text-status">
                      {t("PreLoaded" ?? t("N/A"))}
                    </h3>
                    <p className=" text-count">
                      {count.loading ? (
                        <CircularProgress />
                      ) : (
                        count.data.find((user) => user.Status === "PreLoad")
                          ?.TotalCount ?? 0
                      )}
                    </p>
                    <p
                      className={
                        count.data.find((user) => user.Status === "PreLoad")
                          ?.TotalCount
                          ? " text-num text-link-blue text-link"
                          : "text-link text-num"
                      }
                      onClick={() => {
                        if (
                          count.data.find((user) => user.Status === "PreLoad")
                            ?.TotalCount
                        )
                          onClickShowMore(
                            count.data.find((user) => user.Status === "PreLoad")
                              ?.Status
                          );
                      }}
                    >
                      {">"} {t("SeeMore")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="small-card">
                <div className="wapper-card">
                  <div>
                    <img
                      className="small-card-img"
                      src={urlResolver("icon/icon-card-2.svg")}
                      alt=""
                    />
                  </div>
                  <div className="text-card">
                    <h3 className="title-card">{t("Loaded" ?? t("N/A"))}</h3>
                    <p>
                      {count.loading ? (
                        <CircularProgress />
                      ) : (
                        count.data.find((user) => user.Status === "Loaded")
                          ?.TotalCount ?? 0
                      )}
                    </p>
                    <p
                      className={
                        count.data.find((user) => user.Status === "Loaded")
                          ?.TotalCount
                          ? " text-num text-link-blue text-link"
                          : "text-link text-num"
                      }
                      onClick={() => {
                        if (
                          count.data.find((user) => user.Status === "Loaded")
                            ?.TotalCount
                        )
                          onClickShowMore("Loaded");
                      }}
                    >
                      {">"} {t("SeeMore")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="small-card">
                <div className="wapper-card">
                  <div>
                    <img
                      className="small-card-img"
                      src={urlResolver("icon/icon-card-3.svg")}
                      alt=""
                    />
                  </div>
                  <div className="text-card">
                    <h3 className="title-card">{t("Arrived" ?? t("N/A"))}</h3>
                    <p>
                      {count.loading ? (
                        <CircularProgress />
                      ) : (
                        count.data.find((user) => user.Status === "Arrived")
                          ?.TotalCount ?? 0
                      )}
                    </p>
                    <p
                      className={
                        count.data.find((user) => user.Status === "Arrived")
                          ?.TotalCount
                          ? " text-num text-link-blue text-link"
                          : "text-link text-num"
                      }
                      onClick={() => {
                        if (
                          count.data.find((user) => user.Status === "Arrived")
                            ?.TotalCount
                        )
                          onClickShowMore(
                            count.data.find((user) => user.Status === "Arrived")
                              ?.Status
                          );
                      }}
                    >
                      {">"} {t("SeeMore")}
                    </p>
                  </div>
                </div>
              </div>
            </>

            <>
              <div className="small-card">
                <div className="wapper-card">
                  <div>
                    <img
                      className="small-card-img"
                      src={urlResolver("icon/icon-card-4.svg")}
                      alt=""
                    />
                  </div>
                  <div className="text-card">
                    <h3 className="title-card text-link-card">
                      {t("EmptyReturn")}
                    </h3>
                    <p className="text-count">
                      {emptyCount.loading ? (
                        <CircularProgress />
                      ) : (
                        emptyCount?.data?.TotalCount ?? ""
                      )}
                    </p>
                    {/* <p
                      className={
                        emptyCount?.data?.TotalCount
                          ? " text-link text-link-data text-link-blue "
                          : "text-link text-link-data"
                      }
                      onClick={() => {
                        if (!emptyCount.loading)
                          navigate(
                            `${routeConstant.REPORT}/${routeConstant.DEMURRAGE}`
                          );
                      }}
                    >
                      {" "}
                      {t("> See more")}
                    </p> */}
                  </div>
                </div>
              </div>
            </>
          </>
        </div>
        <>
          {outstanding.loading ? (
            <div className="skeleton-type large-card large-card-box">
              <Skeleton
                variant="rectangular"
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "1rem",
                  borderRadius: " 5px",
                }}
              />
            </div>
          ) : (
            <div className="large-card">
              <div className="wrapper-card">
                <div>
                  <img
                    className="large-card-img"
                    src={urlResolver("icon/icon-card-5.svg")}
                    alt=""
                  />
                </div>
                <div className="text-card">
                  <h3 className="title-card">{t("DueAmount")}</h3>

                  {outstanding.data.map((item, index) => {
                    return (
                      <>
                        <p>
                          <span>{item?.Currency ?? "N/A"}</span>
                          {calculateAmmount(item?.Lineamount) ?? "N/A"}
                        </p>
                        {index != outstanding.data.length - 1 ? (
                          <p className="signal-add">+</p>
                        ) : null}
                      </>
                    );
                  })}

                  <p className="text-information">
                    {t("ValuesUnderConfirmation")}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
        {/* <div className="medium-card d-none">
          <a
            onClick={() => {
              navigate(routeConstant.DOCUMENTS);
            }}
          >
            <div className="wrapper-card">
              <div>
                <img
                  className="medium-card-img"
                  src={`${basename}/icon/icon-card-6.svg`}
                  alt=""
                />
              </div>
              <div className="text-card">
                <h3 className="title-card">{t("DocumentsApproval")}</h3>
                {!loadingSearched ? (
                  <>
                    {totalItem ? (
                      <h1>
                        <p>
                          <span className="number-of-document-pending">
                            {totalItem}

                            <span
                              style={{ fontSize: "20px", color: "#959595" }}
                            >
                              {t("DocumentsApproval")}
                            </span>
                          </span>
                        </p>
                      </h1>
                    ) : (
                      <p>{t("You have no documents for approval")}</p>
                    )}
                  </>
                ) : (
                  <Skeleton height={80} />
                )}
              </div>
            </div>
          </a>
        </div> */}
        <>
          {cemercante.loading ? (
            <div className="skeleton-type medium-card-two large-card-box">
              <Skeleton
                variant="rectangular"
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "1rem",
                  borderRadius: " 5px",
                }}
              />
            </div>
          ) : (
            <div className="medium-card-two test">
              <h3 className="title-table">{t("Mercante Number")}</h3>
              <div className="table-hairdresser">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "37%",
                    }}
                  >
                    <h3 className="title-hairdresser">
                      {t("HouseBillofLading")}
                    </h3>
                  </div>

                  <div
                    style={{
                      width: "38%",
                    }}
                  >
                    <h3 className="title-hairdresser">
                      {t("Mercante Number")}
                    </h3>
                  </div>
                  <div
                    style={{
                      width: "20%",
                    }}
                  >
                    <h3 className="title-hairdresser">{t("PONumber")}</h3>
                  </div>
                </div>
              </div>
              <div className="container-table">
                <>
                  {cemercante.list.length ? (
                    <>
                      {cemercante.list.map((i, index) => {
                        return (
                          <div className="itens-table" key={index}>
                            <div
                              className="itens-table"
                              style={{
                                width: "100%",
                                display: "flex",
                                paddingLeft: "3%",
                                alignItems: "center",
                              }}
                              key={index}
                            >
                              <div
                                style={{
                                  width: "36%",
                                  paddingRight: "2%",
                                }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  title={i?.HouseBillNumber ?? "N/A"}
                                >
                                  {i?.HouseBillNumber ?? "N/A"}
                                </p>
                              </div>
                              <div
                                style={{
                                  width: "38%",
                                  paddingRight: "2%",
                                }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  title={i?.CEMerchanteNumber ?? "N/A"}
                                >
                                  {i?.CEMerchanteNumber ?? "N/A"}
                                </p>
                              </div>
                              <div
                                style={{
                                  width: "20%",
                                  paddingRight: "2%",
                                }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  title={i?.POnumber ?? "N/A"}
                                >
                                  {i?.POnumber ?? "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="itens-table">
                      <div className="itens">
                        <Nodata color="#fff" text={"No CE Mercante Updated"} />
                      </div>
                    </div>
                  )}
                </>
              </div>
              {/* {!(cemercante.list.length >= cemercante.TotalCount) ? (
                <p className="mt-3">
                  <div className="d-flex justify-content-center align-item-center ">
                    <div
                      style={{
                        padding: "5px 20px",
                        color: "var(--color-white)",
                        fontSize: "var(--fs-small-lg)",
                        border: "1px solid var(--color-white)",
                        backgroundColor: "transparent",
                        borderRadius: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => onLoadMoreCe()}
                    >
                      {loadMoreCe ? (
                        <CircularProgress
                          size="0.8rem"
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        <>{t("ShowMore")}</>
                      )}
                    </div> */}
              {/* <BtnOutline
                      title={
                        loadMore ? (
                          <CircularProgress
                            size="0.7rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          t("ShowMore")
                        )
                      }
                      onClick={() => onLoadMoreCe()}
                    /> */}
              {/* </div>
                </p>
              ) : null} */}
              {cemercante.TotalCount > cemercante.list.length && (
                <p className="mt-3">
                  <div className="d-flex justify-content-center align-item-center ">
                    <div
                      style={{
                        padding: "5px 20px",
                        color: "var(--color-white)",
                        fontSize: "var(--fs-small-lg)",
                        border: "1px solid var(--color-white)",
                        backgroundColor: "transparent",
                        borderRadius: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => onLoadMoreCe()}
                    >
                      {loadMoreCe ? (
                        <CircularProgress
                          size="0.8rem"
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        <>{t("ShowMore")}</>
                      )}
                    </div>
                    {/* <BtnOutline
                      title={
                        loadMore ? (
                          <CircularProgress
                            size="0.7rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          t("ShowMore")
                        )
                      }
                      onClick={() => onLoadMoreCe()}
                    /> */}
                  </div>
                </p>
              )}
            </div>
          )}
        </>

        <div className="title-list-last-updates">
          <div className="container-list">
            <img
              className="list-card-img"
              src={urlResolver("icon/icon-interrogation.svg")}
              alt=""
            />
            <div className="information-table">
              <h3 className="title">
                {t("LastCargoUpdates")} <span>(48h)</span>
              </h3>
              <div className="description-table">
                <p>{t("Destination")}</p>
                <p>{t("Ship")}</p>
                <p>{t("PONumber")}</p>
                <p>{t("ETD")}</p>
                <p>{t("ETA")}</p>
              </div>
            </div>
          </div>
        </div>
        <>
          {shipmentList.loading ? (
            <div className="skeleton-type list-last-updates test2">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                return (
                  <Skeleton
                    variant="rectangular"
                    height={70}
                    style={{
                      width: "100%",
                      margin: "   3px auto",
                      borderRadius: " 5px",
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className="list-last-updates test2">
              <>
                {shipmentList.list.length ? (
                  <>
                    {shipmentList.list.map((item, index) => {
                      return (
                        <>
                          <div className="testdiv">
                            <div
                              className="list-items"
                              key={index}
                              onClick={() => {
                                console.log("onListClick", item);
                                const path = `${routeConstant.PROCESS}`;
                                navigate(path, {
                                  state: { item: item, from: "dashboardlist" },
                                });
                              }}
                            >
                              {/* LAST CARGO UPDATES */}
                              <div className="container-list">
                                {item.TransportMode === "SEA" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver("icon/icon-ship.svg")}
                                      alt="Sea Shipment icon"
                                    />
                                  </div>
                                ) : item.TransportMode === "AIR" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver(
                                        "icon/icon-plane-white.svg"
                                      )}
                                      alt="Air Shipment icon"
                                    />
                                  </div>
                                ) : item.TransportMode === "ROA" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver("icon/truck_color.svg")}
                                      alt="Road Shipment icon"
                                    />
                                  </div>
                                ) : null}
                                <p className="title-table position-grid-title-destination">
                                  Destination
                                </p>
                                <div className="destination">
                                  <p className="from">
                                    {item?.OriginPortName ?? t("N/A")} {" →"}
                                  </p>
                                  <p className="to">
                                    {item?.DestinationPortName ?? t("N/A")}
                                  </p>
                                </div>
                                <p className="title-table position-grid-title-ship">
                                  Ship
                                </p>
                                <div className="ship">
                                  <p
                                    className="from"
                                    title={item?.CarrierName ?? t("N/A")}
                                  >
                                    {item?.CarrierName ?? t("N/A")}
                                  </p>
                                </div>
                                <p className="title-table position-grid-title-po-number">
                                  PO Number
                                </p>
                                <div className="po-number">
                                  <p
                                    className="from"
                                    title={item?.OrderReference ?? t("N/A")}
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "8rem",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item?.OrderReference ?? t("N/A")}
                                  </p>
                                </div>
                                <p className="title-table position-grid-title-etd">
                                  ETD
                                </p>
                                <div className="etd">
                                  <p className="from">
                                    {DateFormat(item?.ETD ?? null)}
                                  </p>
                                </div>
                                <p className="title-table position-grid-title-eta">
                                  ETA
                                </p>
                                <div className="eta">
                                  <p className="from">
                                    {DateFormat(item?.ETA ?? null)}
                                  </p>
                                </div>
                                <span className="line-list"></span>
                                <button className="btn-dropdown-list-items">
                                  <img
                                    className="img-arrow-dropdown"
                                    src="../../assets/img/dashboard-home/icon-arrow-dropdown.svg"
                                    alt=""
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="testdiv2">
                            <div className="list-items" key={index}>
                              {/* LAST CARGO UPDATES TABLE */}
                              <div
                                className="container-list"
                                style={
                                  openCard === index
                                    ? {
                                        gridTemplateRows:
                                          "14px 50px 36px 30px 17px 14px 32px 16px",
                                      }
                                    : {}
                                }
                              >
                                {item.TransportMode === "SEA" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver("icon/icon-ship.svg")}
                                      alt="Sea Shipment icon"
                                    />
                                  </div>
                                ) : item.TransportMode === "AIR" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver(
                                        "icon/icon-plane-white.svg"
                                      )}
                                      alt="Air Shipment icon"
                                    />
                                  </div>
                                ) : item.TransportMode === "ROA" ? (
                                  <div>
                                    <img
                                      className="list-last-updates-img"
                                      src={urlResolver("icon/truck_color.svg")}
                                      alt="Road Shipment icon"
                                    />
                                  </div>
                                ) : null}
                                <p className="title-table position-grid-title-destination">
                                  Destination
                                </p>
                                <div className="destination">
                                  <p className="from">
                                    {item?.OriginPortName ?? t("N/A")} {" →"}
                                  </p>
                                  <p className="to">
                                    {item?.DestinationPortName ?? t("N/A")}
                                  </p>
                                </div>
                                {openCard === index ? (
                                  <>
                                    <p className="title-table position-grid-title-ship">
                                      Ship
                                    </p>
                                    <div className="ship">
                                      <p
                                        className="from"
                                        title={item?.CarrierName ?? t("N/A")}
                                      >
                                        {item?.CarrierName ?? t("N/A")}
                                      </p>
                                    </div>
                                    <p className="title-table position-grid-title-po-number">
                                      PO Number
                                    </p>
                                    <div className="po-number">
                                      <p
                                        className="from"
                                        title={item?.OrderReference ?? t("N/A")}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "8rem",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {item?.OrderReference ?? t("N/A")}
                                      </p>
                                    </div>
                                  </>
                                ) : null}
                                <p className="title-table position-grid-title-etd">
                                  ETD
                                </p>
                                <div className="etd">
                                  <p className="from">
                                    {DateFormat(item?.ETD ?? null)}
                                  </p>
                                </div>
                                <p className="title-table position-grid-title-eta">
                                  ETA
                                </p>
                                <div className="eta">
                                  <p className="from">
                                    {DateFormat(item?.ETA ?? null)}
                                  </p>
                                </div>
                                <span className="line-list"></span>
                                <button
                                  className="btn-dropdown-list-items"
                                  onClick={() => {
                                    OnClickDropIcon(item, index);
                                  }}
                                >
                                  <img
                                    className="img-arrow-dropdown"
                                    style={{ color: "red" }}
                                    src={urlResolver(
                                      "icon/icon-arrow-dropdown.svg"
                                    )}
                                    alt=""
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <Nodata text={"Shipment list not found"} />
                )}
              </>
              {shipmentList.TotalCount > shipmentList?.list?.length && (
                <div className="d-flex justify-content-center align-item-center my-2">
                  <BtnOutline
                    title={
                      loadMore ? (
                        <CircularProgress
                          size="0.7rem"
                          style={{ color: "var(--color-blue)" }}
                        />
                      ) : (
                        t("ShowMore")
                      )
                    }
                    onClick={() => onLoadMore()}
                  />
                </div>
              )}
              {/* {shipmentList?.list?.length >= 10 &&
              shipmentList?.list?.length % 10 === 0 ? (
                <div className="d-flex justify-content-center align-item-center my-2">
                  <BtnOutline
                    title={
                      loadMore ? (
                        <CircularProgress
                          size="0.7rem"
                          style={{ color: "var(--color-blue)" }}
                        />
                      ) : (
                        t("ShowMore")
                      )
                    }
                    onClick={() => onLoadMore()}
                  />
                </div>
              ) : null} */}
            </div>
          )}
        </>
      </div>
    </section>
  );
};

export default withTranslation()(Dashboard);
