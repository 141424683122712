import { Skeleton } from "@mui/material";
import React from "react";
import "../../process/process.css";

const SkeletonOrder = (props) => {
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-12 col-md-3">
          {/* for Select and search */}
          <Skeleton
            variant="rectangular"
            height={50}
            style={{ width: "100%", marginBottom: "1rem" }}
          />

          {/* for card */}

          <Skeleton
            variant="rectangular"
            height={50}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height={50}
            style={{ width: "100%", marginBottom: "1rem" }}
          />

          {/* for Show more button */}
          <Skeleton
            variant="rectangular"
            height={50}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
        </div>
        <div className="col-12 col-md-6 ">
          {/* for top div */}
          <Skeleton
            variant="rectangular"
            style={{
              width: "100%",
            }}
            height={110}
          />

          {/* for deatil div */}
          <Skeleton
            variant="rectangular"
            height={250}
            style={{ width: "100%", marginTop: "1rem" }}
          />

          {/* for payment div */}
          <Skeleton
            variant="rectangular"
            height={150}
            style={{ width: "100%", marginTop: "1rem" }}
          />

          {/* for cargo document div */}
          <Skeleton
            variant="rectangular"
            height={150}
            style={{ width: "100%", marginTop: "1rem" }}
          />

          {/* for cargo detail div */}
          <Skeleton
            variant="rectangular"
            height={150}
            style={{ width: "100%", marginTop: "1rem" }}
          />
        </div>
        <div className="col-12 col-md-3 ">
          <div className="map-main-comp">
            {/* for first div */}
            {/* <Skeleton
              variant="rectangular"
              height={50}
              style={{ width: "100%", marginBottom: "1rem" }}
            /> */}
            {/* for bottom div */}
            <Skeleton
              variant="rectangular"
              height={798}
              style={{ width: "100%", marginBottom: "1rem" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonOrder;
