import React from "react";
import { Outlet } from "react-router-dom";
import { PageLayout } from "../../styled/layout/PageLayout";
import Header from "../header/Header";

function MainLayout() {
  return (
    <div>
      <Header />
      <PageLayout>
        <Outlet />
      </PageLayout>
    </div>
  );
}

export { MainLayout };
