import React, { useEffect, useState } from "react";
import ShipmentDetailsCard from "../components/Shipmentdetailscard";
import MapComp from "../components/MapComp";
import Documents from "../components/Document";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PackingList from "../components/PackingList";
import { routeConstant } from "../../../routing";

function OrderDetails(props) {
  const navigate = useNavigate();
  const [detailsdata, setDetailsdata] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  console.log("locationnnnn", location);
  const getOrderdetails = () => {
    setLoading(true);
    const payload = {
      OrderId: "",
      SplitNumber: 0,
      OrderNumber: location?.state?.item?.OrderNumber ?? "",
      BuyerCode: location?.state?.item?.BuyerCode,
    };

    instance
      .post(API_PATH.GET_ORDER_DETAILS, payload, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          setDetailsdata(res?._Data ?? {});
        } else {
          setDetailsdata({});
        }
        setLoading(false);
      })
      .catch((err) => {
        setDetailsdata({});
        console.log("get order details error", err);
        if (!checkAbort) setLoading(false);
      });
  };
  useEffect(() => {
    if (location.state === null) navigate(routeConstant.ORDER);
    // if(!location?.state?.item?.OrderNumber) navigate(routeConstant.DASHBOARD);
    getOrderdetails();
    return () => {
      setCheckAbort(true);
      return controller.abort();
    };
  }, [location?.state?.item?.OrderNumber, location?.state?.item]);
  const { t } = props;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-8 ">
          <ShipmentDetailsCard
            t={t}
            detailsdata={detailsdata}
            loading={loading}
          />
          <PackingList t={t} detailsdata={detailsdata} loading={loading} />
          <Documents t={t} />
        </div>
        <div className="col-12 col-lg-4 pe-0">
          <MapComp item={detailsdata} detailLoading={loading} />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(OrderDetails);
