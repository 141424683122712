import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import instance from "../../api/interceptor";
import BtnFilled from "../../components/button/BtnFilled";
import BtnOutline from "../../components/button/BtnOutline";
import NoAccess from "../../components/NoAccess";
import { basename, baseUrl } from "../../Config";
import { API_PATH } from "../../constant/apiPath";
import { routeConstant } from "../../routing";
import SkeletonFinance from "./components/SkeletonFinance";

import "./financial.css";
import { withTranslation } from "react-i18next";
import Nodata from "../../components/Nodata";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import DocUploadShipmentSidebar from "./components/DocUploadShipmentSidebar";
import FinanceInvoice from "./FinanceInvice";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import urlResolver from "../../utils/urlResolver";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DtCalendar, DtPicker } from "react-calendar-datetime-picker";
import "react-calendar-datetime-picker/dist/style.css";
import moment from "moment";
function Finnance(props) {
  const [date, setDate] = useState(null);

  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingGrouping, setLoadingGrouping] = useState(false);
  const [search, setSearch] = useState({
    type: "",
    value: "",
  });
  const [docData, setDocData] = useState({});
  const [amount, setAmount] = useState([]);
  const [outstandingDetails, setOutstandingDetails] = useState([]);
  const [CurrentPage, setCurrentPage] = useState(0);
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [dateDisplay, setDateDisplay] = useState({});
  const [dateDisplayInv, setDateDisplayInv] = useState({});
  const [outstandingTotalCount, setOutstandingTotalCount] = useState(0);
  const location = useLocation();
  const [anchorElInv, setAnchorElInv] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [modal, setModal] = useState(false);

  let dateRangeFrom = Object.keys(dateDisplay).length
    ? `${dateDisplay?.from?.year}-${dateDisplay?.from?.month}-${dateDisplay?.from?.day}`
    : "";
  let dateRangeTo = Object.keys(dateDisplay).length
    ? `${dateDisplay?.to?.year}-${dateDisplay?.to?.month}-${dateDisplay?.to?.day}`
    : "";
  let dateRangeInvFrom = Object.keys(dateDisplayInv).length
    ? `${dateDisplayInv?.from?.year}-${dateDisplayInv?.from?.month}-${dateDisplayInv?.from?.day}`
    : "";
  let dateRangeInvTo = Object.keys(dateDisplayInv).length
    ? `${dateDisplayInv?.to?.year}-${dateDisplayInv?.to?.month}-${dateDisplayInv?.to?.day}`
    : "";
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenInvMenu = () => {
    // setAnchorElInv(event.currentTarget);
    setModal(true);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseInvMenu = () => {
    setModal(false);
  };
  useEffect(() => {
    navigate(routeConstant.FINNANCE_INVOICE);
    getOutStanding(0, search, {}, {});
    getOutStandingGrouping(search);
  }, []);

  useEffect(() => {
    if (location.pathname == "/financial") {
      navigate(routeConstant.FINNANCE_INVOICE);
      getOutStanding(0, search, {}, {});
      getOutStandingGrouping(search);
    }
  }, [location]);

  const getOutStanding = (count, data, dateRange, dateRangeInv) => {
    console.log(count, "count");
    if (count === 0) {
      setLoading(true);
    } else {
      setIsLoadmore(true);
    }
    const params = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "HousebillNumber",
      SortDirection: "ASC",
      CurrentPage: count,
      PageSize: 10,
      ShipmentID: data.type === "Shipment Number" ? data.value : "",
      PONumber: data.type === "PO Number" ? data.value : "",
      CompanyCode: "BRA",
      HousebillNumber: data.type === "HouseBill" ? data.value : "",
      CNPJNumber: data.type === "CNPJNumber" ? data.value : "",
      FullyPaidDateFrom: Object.keys(dateRange).length
        ? moment(
            Object.keys(dateRange).length
              ? `${dateRange?.from?.year}-${dateRange?.from?.month}-${dateRange?.from?.day}`
              : ""
          ).format("YYYY-MM-DD ")
        : "",
      FullyPaidDateTo: Object.keys(dateRange).length
        ? moment(
            Object.keys(dateRange).length
              ? `${dateRange?.to?.year}-${dateRange?.to?.month}-${dateRange?.to?.day}`
              : ""
          ).format("YYYY-MM-DD ")
        : "",

      TransactionDateFrom: Object.keys(dateRangeInv).length
        ? moment(
            Object.keys(dateRangeInv).length
              ? `${dateRangeInv?.from?.year}-${dateRangeInv?.from?.month}-${dateRangeInv?.from?.day}`
              : ""
          ).format("YYYY-MM-DD ")
        : "",
      TransactionDateTo: Object.keys(dateRangeInv).length
        ? moment(
            Object.keys(dateRangeInv).length
              ? `${dateRangeInv?.to?.year}-${dateRangeInv?.to?.month}-${dateRangeInv?.to?.day}`
              : ""
          ).format("YYYY-MM-DD ")
        : "",
    };

    instance
      .post(API_PATH.GET_OUTSTANDING, params)
      .then((response) => {
        console.log(
          response,
          "letsseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        );
        setOutstandingTotalCount(response?._TotalItem);
        console.log(outstandingTotalCount, "outstandingTotalCount");
        if (count === 0) {
          setLoading(false);
        } else {
          setIsLoadmore(false);
        }

        if (response._IsSuccess) {
          console.log(response._IsSuccess, "response._IsSuccess");
          if (count === 0) {
            console.log("shiverta");
            setOutstandingDetails(response?._Data?.outstandingDetails ?? []);
          } else {
            console.log("krishna");
            setOutstandingDetails([
              ...outstandingDetails,
              ...response?._Data?.outstandingDetails,
            ]);
          }
        } else {
          console.log("puneet");
          setOutstandingDetails([...outstandingDetails]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsLoadmore(false);
        if (
          data.value.length > 0 ||
          dateRangeFrom ||
          dateRangeTo ||
          dateRangeInvFrom ||
          dateRangeInvTo
        ) {
          setOutstandingDetails([]);
        } else {
          setOutstandingDetails([...outstandingDetails]);
        }
        // setOutstandingDetails([...outstandingDetails]);
        // console.log("get outstanding api error", error);
      });
  };
  const getOutStandingGrouping = (data) => {
    setLoadingGrouping(true);
    const params = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "HousebillNumber",
      SortDirection: "ASC",
      CurrentPage: 0,
      PageSize: 0,
      ShipmentID: "",
      PONumber: "",
      CompanyCode: "BRA",
      HousebillNumber: "",
      CNPJNumber: "",
    };

    instance
      .post(API_PATH.GET_OURSTANDING_GROUPING, params)
      .then((response) => {
        if (response._IsSuccess) {
          setAmount(response?._Data ?? []);
        } else {
          setAmount([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsLoadmore(false);
        setAmount([]);
        console.log("get outstanding grouping api error", error);
      })
      .finally(() => {
        setLoadingGrouping(false);
      });
  };

  const loadMore = () => {
    const count = CurrentPage + 1;
    getOutStanding(count, search, dateDisplay, dateDisplayInv);
    setCurrentPage(count, search);
  };

  const { t } = props;
  console.log(outstandingDetails, "outstandingDetails");
  return (
    <>
      {sidebar ? (
        <DocUploadShipmentSidebar
          setSidebar={setSidebar}
          sidebar={sidebar}
          item={docData}
        />
      ) : null}
      {loading ? (
        <SkeletonFinance />
      ) : (
        <div className="financial-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 container-first ">
                {/* <p>{t("Financial")}</p> */}
              </div>
            </div>
          </div>
          <div className="container-fluid ">
            <div className="row payment-top-div">
              <div className="col-xs-12 col-md-6 style notification-filter">
                <div className="filters  d-flex justify-content-between align-items-center">
                  <div>
                    <div>
                      <p
                        onClick={() => {
                          setSearch({
                            type: "",
                            value: "",
                          });
                          getOutStanding(0, {}, {}, {});
                          getOutStandingGrouping("");
                          navigate(routeConstant.FINNANCE_INVOICE);
                        }}
                        className={
                          location.pathname.includes(
                            routeConstant.FINNANCE_INVOICE
                          )
                            ? "text-filter active-color"
                            : "text-filter"
                        }
                      >
                        {t("Invoice")}
                      </p>
                    </div>
                    <div
                      className={
                        (location.pathname.includes(
                          routeConstant.FINNANCE_INVOICE
                        )
                          ? " circle-3days "
                          : location.pathname.includes(
                              routeConstant.FINNANCE_PROFORMA
                            )
                          ? "circle-7days"
                          : "circle-5days") + " circle-days"
                      }
                    >
                      {location.pathname.includes(
                        routeConstant.FINNANCE_INVOICE
                      ) && <img src={urlResolver("icon/new.svg")} />}
                    </div>
                  </div>
                  <div>
                    <div>
                      <p
                        onClick={() => {
                          setSearch({
                            type: "",
                            value: "",
                          });
                          navigate(routeConstant.FINNANCE_PROFORMA);
                        }}
                        className={
                          location.pathname.includes(
                            routeConstant.FINNANCE_PROFORMA
                          )
                            ? "text-filter active-color"
                            : "text-filter"
                        }
                      >
                        Proforma (COD)
                      </p>
                    </div>
                    <div
                      className={
                        (location.pathname.includes(
                          routeConstant.FINNANCE_PROFORMA
                        )
                          ? " circle-3days "
                          : location.pathname.includes(
                              routeConstant.FINNANCE_INVOICE
                            )
                          ? "circle-7days"
                          : "circle-5days") + " circle-days"
                      }
                    >
                      {location.pathname.includes(
                        routeConstant.FINNANCE_PROFORMA
                      ) && <img src={urlResolver("icon/new.svg")} />}
                    </div>
                  </div>
                </div>
                {/* <div
                  className="mid-section"
                  style={{
                    width: "150px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    navigate(routeConstant.FINNANCE_INVOICE);
                  }}
                >
                  <p>{t("Invoice")}</p>
                </div>
                <div
                  className="mid-section"
                  style={{
                    width: "200px",
                    borderRadius: "5px",
                    marginLeft: "2px",
                  }}
                  onClick={() => {
                    navigate(routeConstant.FINNANCE_PROFORMA);
                  }}
                >
                  <p>Proforma (COD)</p>
                </div> */}
              </div>
              <div className="col-xs-12 col-md-6 d-flex payment-top-left">
                <div className="card-head-finance d-flex ">
                  <div className="search-filter">
                    <div>
                      <Menu
                        className="check-date-div"
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        <MenuItem
                          sx={{
                            padding: "0 ",
                            margin: "0",
                          }}
                        >
                          <div
                            className="date-picker-section"
                            style={{
                              minWidth: "100%",
                              height: "100%",
                            }}
                          >
                            <div className=" d-flex justify-content-between">
                              <p
                                style={{
                                  color: "var(--color-blue0) ",
                                  fontSize: "var(--fs-label)",
                                  fontFamily: "var(--family-bold)",
                                  padding: "5px",
                                  margin: "0px",
                                  textAlign: "center",
                                }}
                              >
                                Fully paid Date
                              </p>
                              <IconButton>
                                <CloseIcon onClick={handleCloseUserMenu} />
                              </IconButton>
                            </div>

                            <DtCalendar
                              type="range"
                              initValue={
                                Object.keys(dateDisplay).length
                                  ? dateDisplay
                                  : null
                              }
                              onChange={(val) => {
                                if (val) {
                                  setDateDisplay(val);
                                }
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                                padding: "1rem",
                                width: "100%",
                                justifyContent: "end",

                                // margin: "0.5rem 0rem 0.5rem 15rem",
                              }}
                            >
                              <button
                                className="btn-filled-section "
                                onClick={() => {
                                  setDateDisplay({});
                                  getOutStanding(0, search, {}, dateDisplayInv);
                                  handleCloseUserMenu();
                                }}
                              >
                                Clear
                              </button>
                              <button
                                className="btn-filled-section "
                                onClick={() => {
                                  getOutStanding(
                                    0,
                                    search,
                                    dateDisplay,
                                    dateDisplayInv
                                  );
                                  handleCloseUserMenu();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </MenuItem>
                      </Menu>

                      <IconButton>
                        <CalendarMonthIcon
                          onClick={handleOpenUserMenu}
                          style={{
                            color: "#D0DEF0",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    </div>

                    <div className="select">
                      <div>
                        <Menu
                          className="check-date-div"
                          sx={{
                            mt: "15rem",
                            // mr: "100rem",
                            marginLeft: "-40rem",
                          }}
                          id="menu-appbar"
                          anchorEl={anchorElInv}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={modal}
                          onClose={handleCloseInvMenu}
                        >
                          <MenuItem
                            sx={{
                              padding: "0 ",
                              margin: "0",
                            }}
                          >
                            <div
                              className="date-picker-section "
                              style={{
                                minWidth: "100%",
                                height: "100%",
                              }}
                            >
                              <div className=" d-flex justify-content-between">
                                <p
                                  style={{
                                    color: "var(--color-blue0) ",
                                    fontSize: "var(--fs-label)",
                                    fontFamily: "var(--family-bold)",
                                    padding: "5px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  Invoice Date
                                </p>
                                <IconButton>
                                  <CloseIcon onClick={handleCloseInvMenu} />
                                </IconButton>
                              </div>

                              <DtCalendar
                                type="range"
                                initValue={
                                  Object.keys(dateDisplayInv).length
                                    ? dateDisplayInv
                                    : null
                                }
                                onChange={(val) => {
                                  if (val) {
                                    setDateDisplayInv(val);
                                  }
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  padding: "1rem",
                                  width: "100%",
                                  justifyContent: "end",

                                  // margin: "0.5rem 0rem 0.5rem 15rem",
                                }}
                              >
                                <button
                                  className="btn-filled-section "
                                  onClick={() => {
                                    setDateDisplayInv({});
                                    getOutStanding(0, search, dateDisplay, {});
                                    handleCloseInvMenu();
                                  }}
                                >
                                  Clear
                                </button>
                                <button
                                  className="btn-filled-section "
                                  onClick={() => {
                                    getOutStanding(
                                      0,
                                      search,
                                      dateDisplay,
                                      dateDisplayInv
                                    );
                                    handleCloseInvMenu();
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </MenuItem>
                        </Menu>
                      </div>
                      <select
                        onFocus={() => {
                          if (search.type === "InvoiceDate")
                            setSearch({ type: "", value: "" });
                        }}
                        className="title-select"
                        value={search.type}
                        onChange={(event) => {
                          const obj = { ...search };
                          obj.type = event.target.value;
                          setSearch(obj);
                          if (event?.target?.value.includes("InvoiceDate")) {
                            setSearch({
                              type: "InvoiceDate",
                              value: "",
                            });
                            // getOutStanding(
                            //   0,
                            //   { type: "InvoiceDate", value: "" },
                            //   dateDisplay,
                            //   {}
                            // );

                            handleOpenInvMenu();
                          }
                        }}
                      >
                        {location.pathname === "/financial/invoice" ? (
                          <>
                            <option value="" disabled selected hidden>
                              {t("Select")}
                            </option>

                            <option value="PO Number">{t("PONumber")}</option>
                            <option value="Shipment Number">
                              {t("ShipmentNumber")}
                            </option>
                            <option value="HouseBill">{t("HouseBill")}</option>
                            <option value="CNPJNumber">CNPJ</option>
                            <option value="InvoiceDate">
                              {t("Invoice Date")}
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="" disabled selected hidden>
                              {t("Select")}
                            </option>
                            <option value="Shipment Number">
                              {t("ShipmentNumber")}
                            </option>
                            <option value="HouseBill">{t("HouseBill")}</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div className="search-div-finance">
                      <input
                        className="input-search"
                        type="text"
                        placeholder={t("Search")}
                        value={search.value}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            if (search.type && search.value) {
                              getOutStanding(0, search, dateDisplay);
                              getOutStandingGrouping(search);
                            } else {
                              toast.error("Please select both fields");
                            }
                          }
                        }}
                        onChange={(event) => {
                          const obj = { ...search };
                          obj.value = event.target.value;
                          setSearch(obj);
                        }}
                      />
                      {search.value.length ? (
                        <CloseIcon
                          onClick={() => {
                            const obj = { ...search, type: "", value: "" };
                            setDateDisplay({});
                            setSearch(obj);
                            getOutStanding(0, obj, {}, {});
                            getOutStandingGrouping(obj);
                          }}
                        />
                      ) : null}
                      <SearchOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (search.type && search.value) {
                            getOutStanding(0, search, {}, {});
                            getOutStandingGrouping(search);
                          } else {
                            toast.error("Please select both fields");
                          }
                        }}
                        // onClick={() => {
                        //   setPageCount(0);
                        //   getOrder(0);
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Outlet
            context={{
              amount,
              outstandingTotalCount,
              outstandingDetails,
              isLoadmore,
              loadMore,
              t,
              setSidebar,
              setDocData,
              search,
            }}
          />
          {/* <FinanceInvoice
            amount={amount}
            outstandingDetails={outstandingDetails}
            isLoadmore={isLoadmore}
            loadMore={loadMore}
            t={t}
            setSidebar={setSidebar}
            setDocData={setDocData}
          /> */}
        </div>
      )}
    </>
  );
}

export default withTranslation()(Finnance);
