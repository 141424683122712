import React, { useState } from "react";
import { Skeleton } from "@mui/material";
import BtnOutline from "../../../components/button/BtnOutline";
import "../process.css";
import { withTranslation } from "react-i18next";

const CargoDetails = ({ loading, clientNotes, t }) => {
  const [showmore, setShowmore] = useState(true);
  const clickShowmore = () => {
    showmore ? setShowmore(false) : setShowmore(true);
  };
  return (

    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={120}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      ) : (
        <>
        {clientNotes.length ? <div className="payment">
          <p className="color-grey2 payment-heading">{t("CargoDetails")}</p>

          <div
            className="color-grey"
            dangerouslySetInnerHTML={{ __html: clientNotes ?? "" }}
            style={{
              fontSize: "16px",
              fontFamily: "siena-semibold",
              color: "var(--color-gray7)",
            }}
          />
          {/* <div className="div-details-payments documents">
            <div className="div-details-child">
              <p className="color-blue2"></p>
              <p className=" payment-data">Uploaded: {"12/13/2022"}</p>
            </div>
            <div className="div-details-child">
              <p className="color-blue2"> </p>
              <p className=" payment-data ">Uploaded: {"12/13/2022"}</p>
            </div>
            {!showmore ? (
              <>
                <div className="div-details-child">
                  <p className="color-blue2"> </p>
                  <p className=" payment-data">Uploaded: {"12/13/2022"}</p>
                </div>
                <div className="div-details-child">
                  <p className="color-blue2"> </p>
                  <p className=" payment-data">Uploaded: {"12/13/2022"}</p>
                </div>
              </>
            ) : null}
          </div>

          <div className="d-flex justify-content-end buttonline">
            <BtnOutline
              onClick={() => clickShowmore()}
              title={showmore ? "Show More" : "Show Less"}
            ></BtnOutline> */}
          {/* </div> */}
        </div> :<></>}
        </>
       
      )}
    </React.Fragment>
  );
};

export default withTranslation()(CargoDetails);
