import React, { useEffect, useState } from "react";
import DemurageTable from "./DemurageTable";
import DemurageCard from "./DemurageCard.js";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import SkeletonDemurrageTable from "../Skeleton/SkeletonDemurragetable.js";
import SkeletonDemurrageCard from "../Skeleton/SkeletonDemurragecard.js";
import { toast } from "react-hot-toast";
import Nodata from "../../../components/Nodata";
import { CircularProgress } from "@mui/material";
import BtnOutline from "../../../components/button/BtnOutline";
import { withTranslation } from "react-i18next";
import { exportToExcel } from "react-json-to-excel";
import { useRef } from "react";

function Demurrage(props) {
  const [loadingDemurragedata, setLoadingDemurragedata] = useState(false);
  const [demurrage, setDemurrage] = useState({
    data: [],
    loading: false,
  });
  const [page, setPage] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [calender, setCalender] = useState({
    startdate: "",
    enddate: "",
    type: "",
  });
  const totalCount = useRef(null);
  const getDemurrage = (count, reset = false) => {
    const body = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "VesselArrival",
      VessalArrivalFrom: !reset ? calender.startdate : "",
      VessalArrivalTo: !reset ? calender.enddate : "",
      SortDirection: "ASC",
      CurrentPage: count,
      PageSize: 10,
    };
    if (count === 0) {
      setDemurrage({ data: [], loading: true });
    }
    instance
      .post(API_PATH.GET_DEMURRAGE, body)
      .then((res) => {
        if (res?._IsSuccess) {
          totalCount.current = res?._TotalItem;
          if (count === 0) {
            setDemurrage({ data: [...res._Data], loading: false });
          } else {
            setDemurrage({
              data: [...demurrage.data, ...res._Data],
              loading: false,
            });
          }
        } else {
          setDemurrage({ data: [], loading: false });
        }
        setPage(count);
      })
      .catch((err) => {
        setDemurrage({ data: [], loading: false });
        console.log("getDemmurage api error", err);
      })
      .finally(() => {
        setLoadMore(false);
      });
  };

  const exportToXLS = () => {
    setLoadingDemurragedata(true);
    const body = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "VesselArrival",
      VessalArrivalFrom: calender?.startdate ?? "",
      VessalArrivalTo: calender?.enddate ?? "",
      SortDirection: "ASC",
      CurrentPage: 0,
      PageSize: 0,
    };
    instance
      .post(API_PATH.GET_DEMURRAGE, body)
      .then((res) => {
        setLoadingDemurragedata(false);
        if (res?._IsSuccess) {
          const data = res._Data;
          let fileName = "Demurage_Data";
          exportToExcel(data, fileName);
        } else {
        }
      })
      .catch((err) => {
        setLoadingDemurragedata(false);
        console.log("getDemmurage api error", err);
      });
  };

  useEffect(() => {
    getDemurrage(0);
  }, []);

  const onLoadMore = () => {
    const count = page + 1;
    setLoadMore(true);
    getDemurrage(count);
  };

  const onResetFilter = () => {
    setCalender({
      type: "",
      enddate: "",
      startdate: "",
    });
    getDemurrage(0, true);
  };
  const { t } = props;
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="head-trans">{t("Demurrage")}</p>
        {/* <div>
          <BtnOutline
            title={
              loadingDemurragedata ? (
                <CircularProgress
                  size="1rem"
                  style={{ color: "var(--color-blue1)" }}
                />
              ) : (
                t("Export")
              )
            }
            // disabled={!exportType.value}
            onClick={() => exportToXLS()}
          />
        </div> */}
      </div>
      <div className="Demurrage-section d-flex  flex-column flex-md-row  ">
        <div className="d-flex flex-column flex-md-row calender-section justify-content-between justify-content-md-none">
          <input
            className="input-date style-calender"
            max="9999-12-31"
            type="date"
            value={calender.startdate}
            onChange={(event) => {
              const obj = { ...calender };
              obj.startdate = event.target.value;
              setCalender(obj);
            }}
          />

          <input
            className="input-date style-calender"
            type="date"
            max="9999-12-31"
            value={calender.enddate}
            onChange={(event) => {
              const obj = { ...calender };
              obj.enddate = event.target.value;
              setCalender(obj);
            }}
          />
        </div>
        <div className=" transport-mid d-flex  flex-md-grow-0 flex-grow-1 justify-content-end ">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                className="btn-filter"
                onClick={() => {
                  if (
                    calender.startdate.length > 0 &&
                    calender.enddate.length > 0
                  )
                    getDemurrage(0);
                  else {
                    toast.error("Please select both fields");
                  }
                }}
              >
                {t("Search")}
              </button>
            </div>
            <div>
              <button
                className="btn-filter btn-reset"
                onClick={() => {
                  onResetFilter();
                }}
              >
                &gt; {t("Reset")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {demurrage.loading ? (
          <>
            <div className="tableDemorage">
              <SkeletonDemurrageTable />
            </div>
            <div className="cardDemorage">
              <SkeletonDemurrageCard />
            </div>
          </>
        ) : (
          <>
            <div className="tableDemorage">
              <DemurageTable data={demurrage?.data} />
              {totalCount.current > demurrage?.data?.length && (
                <div className="d-flex justify-content-center align-item-center my-3">
                  <BtnOutline
                    title={
                      loadMore ? (
                        <CircularProgress
                          size="0.7rem"
                          style={{ color: "var(--color-blue)" }}
                        />
                      ) : (
                        t("Show More")
                      )
                    }
                    onClick={() => onLoadMore()}
                  />
                </div>
              )}
            </div>
            <div className="cardDemorage">
              {demurrage.data.length > 0 ? (
                <>
                  {demurrage?.data.map((item, index) => {
                    return <DemurageCard item={item} key={index} />;
                  })}
                </>
              ) : (
                <Nodata text={"No Demurrage data found!"} />
              )}

              {demurrage?.data?.length >= 10 &&
              demurrage?.data?.length % 10 === 0 ? (
                <div className="d-flex justify-content-center align-item-center my-3">
                  <BtnOutline
                    title={
                      loadMore ? (
                        <CircularProgress
                          size="0.7rem"
                          style={{ color: "var(--color-blue)" }}
                        />
                      ) : (
                        t("ShowMore")
                      )
                    }
                    onClick={() => onLoadMore()}
                  />
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withTranslation()(Demurrage);
