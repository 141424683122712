import React, { useEffect, useState } from "react";
import "../login/login.css";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "../../components/layout/Auth/AuthLayout";
import TextField from "@mui/material/TextField";
import { API_PATH } from "../../constant/apiPath";
import instance from "../../api/interceptor";
import toast from "react-hot-toast";
import { routeConstant } from "../../routing";
import { Formik } from "formik";
import { UpdateWithPassword } from "../../helpers/validator/LoginWithPassword";
import { CircularProgress } from "@mui/material";
const NewPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const onClickPasswordsend = (values, actions) => {
    // alert(1);
    setLoading(true);
    const body = {
      Guid: id,
      Password: values.newPassword,
      ConfirmPassword: values.confirmPassword,
    };
    instance
      .post(API_PATH.GENERATE_NEW_PASSWORD, body)
      .then((res) => {
        setLoading(false);
        toast.success(res._Message);
        actions.resetForm();
        navigate(routeConstant.LOGIN);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?._Message ?? "Something went wrong!");
        console.log("change password error", err);
      });
  };

  return (
    <AuthLayout>
      <div className="container-form">
        <div>
          <h1 className="title">AS Tracking</h1>
        </div>
        <div className="inputs" />
        <Formik
          initialValues={UpdateWithPassword.initials}
          validationSchema={UpdateWithPassword.validation}
          onSubmit={(values, actions) => {
            onClickPasswordsend(values, actions);
          }}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <div className="input-defalut-login">
                  <TextField
                    label="New Password"
                    variant="standard"
                    value={props.values.newPassword}
                    onChange={(event) => {
                      props.setFieldValue("newPassword", event.target.value);
                    }}
                  />
                  {props.errors.newPassword && (
                    <span className="span-error">
                      {props.errors.newPassword}
                    </span>
                  )}
                </div>
                <div className="input-defalut-login">
                  <TextField
                    label="Confirm Password"
                    variant="standard"
                    value={props.values.confirmPassword}
                    onChange={(event) => {
                      props.setFieldValue(
                        "confirmPassword",
                        event.target.value
                      );
                    }}
                  />
                  {props.errors.confirmPassword && (
                    <span className="span-error">
                      {props.errors.confirmPassword}
                    </span>
                  )}
                </div>
                <div className="submit">
                  <button
                    type="submit"
                    className="btn-login"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        size="1.5rem"
                        style={{ color: "var(--color-white)" }}
                      />
                    ) : (
                      "Send"
                    )}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default NewPassword;
