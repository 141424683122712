import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { routeConstant } from "../utils/routeConstant";
import { AuthContext } from "../../store/context/AuthProvider";

function PrivateRoute(props) {
  const Auth = useContext(AuthContext);
  // const { isAuthenticated } = useSelector((state) => state.users);
  if (Auth.userAuth) {
    // alert(1);
    return props.children;
  }
  return <Navigate to={routeConstant.LOGIN} replace={true} />;
}

export default PrivateRoute;
