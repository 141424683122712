import React, { useEffect, useState } from "react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import { useLocation } from "react-router-dom";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/interceptor";
import { Skeleton } from "@mui/material";

function MapComponent(props) {
  const location = useLocation();
  const [points, setPoints] = useState(new props.google.maps.LatLngBounds());
  const [polylinedata, setPolylinedata] = useState([]);
  const [cordinates, setCordinates] = useState({
    loading: false,
    route: [],
    location: [],
  });
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  // const responce = {
  //   response: "success",

  //   status: "OK",

  //   response_code: "1",

  //   vesseldetails: null,

  //   flightInfo: null,

  //   locations: [
  //     {
  //       locType: "Origin",

  //       locName: "Pipavav Bandar",

  //       LocationOrder: 0,

  //       lat: 20.967,

  //       lng: 71.55,
  //     },

  //     {
  //       locName: "Santos",

  //       locType: "Destination",

  //       LocationOrder: 0,

  //       lat: -23.96083,

  //       lng: -46.33361,
  //     },
  //   ],

  //   route: [
  //     [
  //       [
  //         20.967,

  //         71.55,
  //       ],

  //       [
  //         36.14076,

  //         -5.45623,
  //       ],

  //       [
  //         35.78908,

  //         -5.80527,
  //       ],

  //       [
  //         -23.96083,

  //         -46.33361,
  //       ],
  //     ],
  //   ],
  // };

  const getLocation = () => {
    setCordinates({
      ...cordinates,
      loading: true,
      route: [],
      location: [],
    });
    instance
      .get(
        `${API_PATH.GET_MAP_COORDINATE_PROCESS}/${location?.state?.item?.ShipmentID}`,
        { signal }
      )
      .then((res) => {
        console.log("ranjan",res)
        if (res._IsSuccess) {
          // original
          const r = res._Data.route ?? [];
          const l = res._Data.locations ?? [];
          // change
          // const r = responce?.route ?? [];
          // const l = responce?.locations ?? [];
          getPolylineData(r, l);

          setCordinates({
            ...cordinates,
            loading: false,
            route: [...r],
            location: [...l],
          });
          setTimeout(() => {
            getboundsdata(l);
          }, 300);
        } else {
          setCordinates({
            ...cordinates,
            loading: false,
            route: [],
            location: [],
          });
        }
      })
      .catch((err) => {
        if (!checkAbort)
          setCordinates({
            ...cordinates,
            loading: false,
            route: [],
            location: [],
          });
        getboundsdata([]);
        getPolylineData([], []);

        console.log("Error in get cordinates for process fails", err);
      });
  };

  const getboundsdata = (point) => {
    const bounds = new props.google.maps.LatLngBounds();
    if (point.length >= 3) {
      point.map((item) => {
        if (item.locType !== "Destination") {
          bounds.extend({ lat: item.lat, lng: item.lng });
        }
      });
    } else {
      point.map((item) => {
        bounds.extend({ lat: item.lat, lng: item.lng });
      });
    }
    setPoints(bounds);
  };

  const getPolylineData = (item, l) => {
    const data = [];
    if (item.length) {
      for (let i = 0; i < item.length; i++) {
        for (let j = 0; j < item[i].length; j++) {
          const temp = { lat: item[i][j][0], lng: item[i][j][1] };
          data.push(temp);
        }
      }
    } else {
      for (let j = 0; j < l.length; j++) {
        const temp = { lat: l[j].lat, lng: l[j].lng };
        data.push(temp);
      }
    }

    setPolylinedata(data);
  };

  useEffect(() => {
    getLocation();

    return () => {
      setCheckAbort(true);
      return controller.abort();
    };
  }, [location]);

  const pathOptions = {
    strokeColor: "#0000FF",
    strokeOpacity: 1,
    icons: [
      {
        icon: {
          path: props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeOpacity: 0,
          fillColor: "#0000FF",
          fillOpacity: 1.0,
          scale: 4,
        },
        repeat: "80px",
      },
    ],
  };

  return (
    <>
      <div className="locate-div">
        {cordinates.loading ? (
          <div className="map-layover">
            <h3 style={{ color: "black" }}>Loading Co-ordinates...</h3>
          </div>
        ) : (
          <Map
            google={props.google}
            zoom={14}
            onReady={() => {}}
            initialCenter={{
              lat: 42.39,
              lng: -72.52,
            }}
            className="scdc"
            bounds={points}
          >
            {cordinates?.location.length &&
              cordinates?.location.map((item, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: item.lat, lng: item.lng }}
                    title={item.locName}
                    name={item.locType}
                  />
                );
              })}
            <Polyline
              path={polylinedata}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              options={pathOptions}
              strokeWeight={2}
            />
          </Map>
        )}
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0",
})(React.memo(MapComponent));
