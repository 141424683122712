import { CircularProgress, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useOutletContext } from "react-router-dom";
import instance from "../../../api/interceptor";
import BtnOutline from "../../../components/button/BtnOutline";
import Nodata from "../../../components/Nodata";
import { basename } from "../../../Config";
import { API_PATH } from "../../../constant/apiPath";
import { Date } from "../../../utils/moment";
import { genrateDownloadLink } from "../../../utils/utility";
import urlResolver from "../../../utils/urlResolver";

function Disapproved() {
  const [loading, setLoading] = useState(false);
  const [DisapprovedData, setDisapprovedData] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [searcheddata, setSearcheddata] = useOutletContext();
  const [loadingDownload, setLoadingDownload] = useState(-1);
  const [page, setPage] = useState(0);
  const location = useLocation();

  useEffect(() => {
    getDisapprovedDocData(0);
  }, [location]);

  useEffect(() => {
    setDisapprovedData([...searcheddata]);
  }, [searcheddata]);

  const getDisapprovedDocData = (count = 0) => {
    if (count === 0) {
      setLoading(true);
    }
    const body = {
      Status: 3,
      SortColumnName: "",
      HouseBillNumber: "",
      SortDirection: "",
      CurrentPage: count,
      PageSize: 10,
    };
    instance
      .post(API_PATH.GET_DOCUMENT_DATA, body)
      .then((res) => {
        setLoading(false);
        if (res._IsSuccess) {
          setDisapprovedData([...DisapprovedData, ...res._Data]);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("get disapproved document api fail", err);
      })
      .finally(() => {
        setPage(count);
        setLoadMore(false);
      });
  };

  const onLoadMore = () => {
    setLoadMore(true);
    const count = page + 1;
    getDisapprovedDocData(count);
    setPage(count);
  };

  const onClickDownloadDocs = (item, index) => {
    setLoadingDownload(index);
    const payload = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
      ShipmentID: item?.ParentId ?? "",
      DocType: "HBL",
      DocumentName: "",
      DocumentDate: "",
      DocumentPk: item.PK ?? "",
    };
    instance
      .post(API_PATH.DOWNLOAD_SHIPMENT_DOCS, payload)
      .then((res) => {
        if (res?._IsSuccess && res?._Data?.ImageDataVC) {
          const downloadLink = document.createElement("a");
          const prefix = genrateDownloadLink(res?._Data?.SC_FileName);
          downloadLink.href = `${prefix}${res?._Data?.ImageDataVC}`;
          if (prefix) {
            downloadLink.download = res?._Data?.SC_FileName ?? "";
            downloadLink.click();
          } else {
            toast.error("Invalid document found!");
          }
        } else {
          toast.error("No data found!");
        }
      })
      .catch((err) => {
        console.log("download cargo docs error", err);
      })
      .finally(() => {
        setLoadingDownload(-1);
      });
  };

  return (
    <div className="approved-sections" id="approved-div">
      <div className="container-fluid">
        {loading ? (
          <div className="skeleton-type">
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
            <Skeleton style={{ height: "10rem", marginTop: "0.5rem" }} />
          </div>
        ) : (
          <>
            {DisapprovedData.length ? (
              <>
                {DisapprovedData.map((item, index) => {
                  return (
                    <div className="row  disapproved-section" key={index}>
                      <div className="col-12 col-md-3">
                        <p className="disapproved-color first-p-of-payment">
                          House number:{" "}
                          <span>{item?.HouseBillNumber ?? "N/A"}</span>
                        </p>
                        <p className="approved-color first-p-of-payment">
                          Shipment number:{" "}
                          <span>{item?.ParentId ?? "N/A"}</span>
                        </p>
                      </div>
                      <div className="col-12 col-lg-7 col-md-8">
                        <div className="conatiner-fluid">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="disapproved-color">Data de Saida</p>
                              <p className="disapproved-date">
                                {Date(item?.ETD ?? null)}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="disapproved-color">
                                Data de chegada
                              </p>
                              <p className="disapproved-date">
                                {Date(item?.ETA ?? null)}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="disapproved-color">
                                Data limite para aprovação
                              </p>
                              <p className="disapproved-date">
                                {item?.DeadlineForApprovalDate ?? "N/A"}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              {loadingDownload === index ? (
                                <p className="mb-0 d-flex justify-content-center align-items-center">
                                  Downloading...
                                </p>
                              ) : (
                                <div
                                  className="d-flex image-div "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    onClickDownloadDocs(item, index);
                                  }}
                                >
                                  <div className=" image-section ">
                                    <img
                                      src={urlResolver("icon/icon-pdf.svg")}
                                    />
                                    {/* <p>{item?.DataType ?? "N/A"}</p> */}
                                  </div>
                                  <div>
                                    <p className="pending-color">
                                      <span>{item?.FileName ?? "N/A"}</span>
                                    </p>
                                    <p>Upload:{Date(item?.Date)}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* <div className="col-3">
                          <p className="disapproved-color">
                            Deadline for approval
                          </p>
                          <p className="disapproved-date">10/11/2021</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2  col-md-4 col-lg-4 d-flex align-items-center btn-approval">
                        <ul>
                          <li
                            className="disapproved-list"
                            style={{ color: "red" }}
                          >
                            Disapproved
                          </li>
                        </ul>
                      </div>
                      <div className="disapproved-mid ">
                        <p className="disapproved-motivo">Reason</p>
                        {/* <p className="disapproved-para">
                          {item?.DisplayRemarks ?? "N/A"}
                        </p> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.DisplayRemarks ?? "",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <Nodata text={"Document list not found"} />
            )}
          </>
        )}
        {DisapprovedData.length >= 1 && DisapprovedData.length !== 1 && (
          <div className="d-flex justify-content-center mt-0">
            <BtnOutline
              title={
                loadMore ? (
                  <CircularProgress
                    size="0.7rem"
                    style={{ color: "--color-blue1" }}
                  />
                ) : (
                  // t("ShowMore")
                  "Show More"
                )
              }
              onClick={() => onLoadMore()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Disapproved;
