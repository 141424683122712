import { Skeleton } from "@mui/material";
import React from "react";
import "../../process/process.css";

const SkeletonFinance = (props) => {
  return (
    <div className="financial-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 financial-head">
            {/* <p
              style={{
                fontSize: "2rem",
                color: "var(--color-blue2)",
                margin: "0",
                fontFamily: "var(--family-bold)",
                marginBottom: " 32px",
              }}
            >
              Financial
            </p> */}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row payment-top-div">
          <div className="col-12 col-md-6" style={{ paddingLeft: "0" }}>
            <Skeleton
              variant="rectangular"
              height={50}
              style={{ width: "100%", marginBottom: "1rem" }}
            />
          </div>
          <div
            className="col-12 col-md-6 d-flex payment-top-left"
            style={{ paddingRight: "0" }}
          >
            <Skeleton
              variant="rectangular"
              height={50}
              style={{ width: "100%", marginBottom: "1rem" }}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <Skeleton
          variant="rectangular"
          height={100}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      </div>
      <div className="container-fluid">
        <Skeleton
          variant="rectangular"
          height={70}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      </div>
    </div>
  );
};

export default SkeletonFinance;
