import { localData } from "../../../services/auth-header";
import { UserService } from "../../../services/user-service";
import { ACTION_TYPE } from "../constants/actionType";

export const User = {
  login: (email, password) => (dispatch) => {
    // dispatch({ type: ACTION_TYPE.LOGIN_REQUEST });
    return UserService.login(email, password).then(
      (res) => {
        //dispatch login success
        // dispatch({ type: ACTION_TYPE.LOGIN_SUCCESS, payload: res });
        return Promise.resolve(res);
      },
      (err) => {
        //dispatch login error
        // dispatch({ type: ACTION_TYPE.LOGIN_ERROR, payload: err });
        return Promise.reject(err);
      }
    );
  },
  logout: () => (dispatch) => {
    // dispatch({ type: ACTION_TYPE.LOG });
    UserService.logout();
    //handle logout dispatch
  },
  permissions: () => (dispatch) => {
    dispatch({ type: ACTION_TYPE.PERMISSION_DATA_REQUEST });
    return UserService.permissions().then(
      (res) => {
        //handle login permission dispatch

        dispatch({ type: ACTION_TYPE.PERMISSION_DATA_SUCCESS, payload: res });
        dispatch({
          type: ACTION_TYPE.ALREADY_LOGIN,
          payload: localData(),
        });
        return Promise.resolve(res);
      },
      (error) => {
        // handle permissions error dispatch
        dispatch({ type: ACTION_TYPE.PERMISSION_DATA_FAIL, payload: error });
        console.log("Permission action success");
        return Promise.reject(error);
      }
    );
  },
};
