import { Checkbox, Box } from "@mui/joy";
import React from "react";
import "../financial.css";
// import "./reviewpayment.css";
import { routeConstant } from "../../../routing/utils/routeConstant";
import { useNavigate } from "react-router-dom";
import BtnFilled from "../../../components/button/BtnFilled";
import Btn from "../../../components/button/Btn";
import { withTranslation } from "react-i18next";

const ReviewPayment = (props) => {
  const navigate = useNavigate();
  const { t } = props;
  return (
    <div className="financial-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 container-section">
            <p>{t("Financial")}</p>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row payment-top-div">
          <div className="col-12 col-md-6">
            <div className="mid-section">
              <p>{t("REVIEW")}</p>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex payment-top-left">
            <div className="card-head-finance d-flex ">
              <div className="search-filter">
                <div className="select">
                  <select className="title-select">
                    <option value="">{t("Select")}</option>
                    <option value="CNPJ">{t("CNPJ")}</option>
                    <option value="Origin">{t("Origin")}</option>
                    <option value="Destination">{t("Destination")}</option>
                    <option value="PO Number">{t("PONumber")}</option>
                    <option value="Shipper">{t("Shipper")}</option>
                    <option value="Ship">{t("Ship")}</option>
                  </select>
                </div>
                <div className="search-div">
                  <input
                    className="input-search"
                    type="text"
                    placeholder={t("Search")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {[1, 2, 3].map((item, index) => {
        return (
          <div className="container-fluid" key={index}>
            <div className="row  payment-section">
              <div className="col-12 col-md-4">
                <div className="conatiner">
                  <div className="row">
                    <div className="col-1">
                      <Box sx={{ display: "flex", gap: 3 }}>
                        <Checkbox color="primary" />
                      </Box>
                    </div>
                    <div className="col-10 po-number-text">
                      <p className="payment-color">{t("PONumber")}: </p>
                      <p>AAA01AAA01</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="conatiner">
                  <div className="row">
                    <div className="col-6">
                      <p className="payment-color">{t("ShipmentNumber")}</p>
                      <p>AA01AA01AA110</p>
                    </div>
                    <div className="col-6">
                      <p className="payment-color">
                        {t("House Bill of Lading")}
                      </p>
                      <p>AAA01AAA01</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="conatiner">
                  <div className="row">
                    <div className="col-6">
                      <p className="payment-color">{t("Amount")}</p>
                      <p>R$ 3000</p>
                    </div>
                    <div className="col-6">
                      <p className="payment-color">{t("DueDate")}</p>
                      <p>24/12/2024</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="">
        <Btn
          onClick={() => navigate(routeConstant.GROUPPAYMENT)}
          title="< Back"
        ></Btn>
      </div>
      <div className=" border-div"></div>
      <div className="col-12 col-md-6">
        <div className="mid-section">
          <p>{t("PaymentMethod")}</p>
        </div>
      </div>
      <div className="conatiner payment-conainer">
        <div className="row payment-row">
          <div className="col-6 col-md-2 payment-method">
            <Box sx={{ display: "flex", gap: 3 }}>
              <Checkbox color="primary" />
            </Box>
            <p className="payment-color">{t("PIX")}</p>
          </div>{" "}
          <div className="col-6 col-md-2 payment-method">
            <Box sx={{ display: "flex", gap: 3 }}>
              <Checkbox color="primary" />
            </Box>
            <p className="payment-color">{t("QRCode")}</p>
          </div>{" "}
          <div className="col-6 col-md-2 payment-method">
            <Box sx={{ display: "flex", gap: 3 }}>
              <Checkbox color="primary" />
            </Box>
            <p className="payment-color">{t("RequestTicket")}</p>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <BtnFilled title={t("PayNow")}></BtnFilled>
        <Btn
          onClick={() => navigate(routeConstant.GROUPPAYMENT)}
          title={`> ${t("Cancel")}`}
        ></Btn>
      </div>
    </div>
  );
};

export default withTranslation()(ReviewPayment);
