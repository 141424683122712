import React from "react";
import "./button.css";
function BtnRed(props) {
  const {
    onClick = () => {},
    title = "",
    disabled = false,
    type = "button",
  } = props;
  return (
    <div>
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className="btn-out-section-red"
      >
        {title}
      </button>
    </div>
  );
}

export default BtnRed;
