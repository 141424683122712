// let BUILD_TYPE = "dev"
// let BUILD_TYPE = "clientDash";
let BUILD_TYPE = "prd";
// let BUILD_TYPE = "astrackingv2"
// let BUILD_TYPE = "AsxDash";
let baseUrl = "";
let basename = "";
let LOCAL_STORAGE_KEY = "";
let LOCAL_STORAGE_USER_KEY = "";
let LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT = "";
let LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT = "";
let LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY = "";
let LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD = "";
let LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD = "";
let LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA = "";
if (BUILD_TYPE === "prd") {
  baseUrl = "https://astracking.sflhub.com/AsxGateWayApi";
  basename = "";
  LOCAL_STORAGE_KEY = "as-access-token";
  LOCAL_STORAGE_USER_KEY = "as-user-prd";
  LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT = "shipment-status-count";
  LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT = "empty-return-count";
  LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY = "get-outstanding-currency";
  LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD = "ce-mercente-dashboard";
  LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD = "shipment-for-dashboard";
  LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA = "get-document-data";
  let homepageUrl = "https://www.astracking.co";
} else if (BUILD_TYPE === "astrackingv2") {
  baseUrl = "https://astracking.sflhub.com/AsxGateWayApi";
  basename = "ASTrackingV2";
  LOCAL_STORAGE_KEY = "as-access-token";
  LOCAL_STORAGE_USER_KEY = "as-user-vtwo";
  LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT = "shipment-status-count";
  LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT = "empty-return-count";
  LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY = "get-outstanding-currency";
  LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD = "ce-mercente-dashboard";
  LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD = "shipment-for-dashboard";
  LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA = "get-document-data";
  let homepageUrl = "https://astracking.sflhub.com/ASTrackingV2/";
} else if (BUILD_TYPE === "clientDash") {
  baseUrl = "https://tracking.asiashipping.co/V2ASXDashBoardService";
  basename = "V2ClientDashboard";
  LOCAL_STORAGE_KEY = "as-access-token";
  LOCAL_STORAGE_USER_KEY = "as-user-cd";
  LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT = "shipment-status-count";
  LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT = "empty-return-count";
  LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY = "get-outstanding-currency";
  LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD = "ce-mercente-dashboard";
  LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD = "shipment-for-dashboard";
  LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA = "get-document-data";
  let homepageUrl = "https://tracking.asiashipping.co/V2ClientDashboard";
} else if (BUILD_TYPE === "AsxDash") {
  baseUrl = "https://asdev.sflhub.com/AsxDashboardsApi";
  basename = "AsxDashboardUI";
  LOCAL_STORAGE_KEY = "as-access-token";
  LOCAL_STORAGE_USER_KEY = "as-user-cd";
  LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT = "shipment-status-count";
  LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT = "empty-return-count";
  LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY = "get-outstanding-currency";
  LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD = "ce-mercente-dashboard";
  LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD = "shipment-for-dashboard";
  LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA = "get-document-data";
  let homepageUrl = "https://asdev.sflhub.com/AsxDashboardUI";
}
export {
  baseUrl,
  basename,
  LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT,
  LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT,
  LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY,
  LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD,
  LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD,
  LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA,
  LOCAL_STORAGE_USER_KEY,
};

// old
// export const baseUrl = "https://dev.sflhub.com/AsxGateWayApi";
// export const baseUrl = "https://astracking.sflhub.com/AsxGateWayApi";
// export const baseUrl = "https://mw2.sflhub.com/AsxGateWayApi";
// export const baseUrl = "https://astracking.sflhub.com/AsxGateWayApi";
// export const basename = "ASTrackingV2";
// export const LOCAL_STORAGE_KEY = "as-access-token";
// export const LOCAL_STORAGE_KEY_ShipmentStatusCount
// export const LOCAL_STORAGE_KEY_SHIPMENT_STATUS_COUNT = "shipment-status-count";
// export const LOCAL_STORAGE_KEY_EMPTY_RETURN_COUNT = "empty-return-count";
// getOutstandingByCurrency
// export const LOCAL_STORAGE_KEY_GET_OUTSTANDING_BY_CURRENCY =
//   "get-outstanding-currency";
// export const LOCAL_STORAGE_KEY_CE_MARCENTE_FOR_DASHBOARD =
//   "ce-mercente-dashboard";
// export const LOCAL_STORAGE_KEY_SHIPMENT_FOR_DASHBOARD =
//   "shipment-for-dashboard";
// export const LOCAL_STORAGE_KEY_GET_DOCUMENT_DATA = "get-document-data";
// export const BUILD_TYPE = "dev";
// "homepage": "https://www.as-tracking.co/",
// "proxy":"https://astracking.sflhub.com/",
//  ---prd ui link------
//  "homepage": "https://www.astracking.co"
// -----------
