import React, { useContext, Suspense, lazy } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Dashboard from "../screens/Dashboard/Dashboard";
import { MainLayout } from "../components";
import { routeConstant } from "./index";
import Team from "../screens/team/Team";
import Login from "../screens/login/Login";
import EditUser from "../screens/team/components/EditUser";
import Forgetpassword from "../screens/forgotPassword/Forgetpassword";
import Notification from "../screens/notification/Notification";

import Verification from "../screens/authverification/Verification";
import NewPassword from "../screens/newpassword/NewPassword";
import ReviewPayment from "../screens/finnance/components/ReviewPayment";
import Overview from "../screens/report/component/Overview";
import Transport from "../screens/report/component/Transport";
import Dataexport from "../screens/report/component/Dataexport";
import General from "../screens/report/component/General";
import Deadline from "../screens/report/component/Deadline";
import Incoterm from "../screens/report/component/Incoterm";
import Pending from "../screens/documents/component/Pending";
import Approved from "../screens/documents/component/Approved";
import Disapproved from "../screens/documents/component/Disapproved";
import Threedays from "../screens/notification/component/Threedays";
import Fivedays from "../screens/notification/component/Fivedays";
import Sevendays from "../screens/notification/component/Sevendays";
import { LOCAL_STORAGE_KEY, basename } from "../Config";
import PrivateRoute from "./component/PrivateRoute";
import Payment from "../screens/finnance/components/Payment";
import { AuthContext } from "../store/context/AuthProvider";
import { Skeleton } from "@mui/material";
import AppInitialize from "./component/AppInitialize";
import PublicRoute from "./component/PublicRoute";
import Loading from "../common/Loading.js";
import Demurrage from "../screens/report/component/Demurrage";
import Newpreset from "../screens/report/component/Newpreset";
import NoShipmentComp from "../screens/process/components/NoShipmentComp";
import { Air } from "@mui/icons-material";
import Sea from "../screens/report/component/Sea";

import Thresholdsetting from "../screens/report/component/Thresholdsetting";
import NoRoute from "../common/NoRoute";
import FinanceInvoice from "../screens/finnance/FinanceInvice";
import Documents from "../screens/documents/Documents";
import Process from "../screens/process/Process";
import Order from "../screens/order/Order";
import ProcessDetails from "../screens/process/details/ProcessDetails";
import OrderDetails from "../screens/order/details/OrderDetails";
import Finnance from "../screens/finnance/Finnance";
import FinnanceProforma from "../screens/finnance/FinnanceProforma.js";
import Report from "../screens/report/Report";
import NewUser from "../screens/team/NewUser.js";
import DocumentTracking from "../screens/documents/component/DocumentTracking";
import Corbon from "../screens/report/component/Corbon.js";
// import Documents from "";
// const Documents = lazy(() => import("../screens/documents/Documents"));
// const Process = lazy(() => import("../screens/process/Process"));
// const Order = lazy(() => import("../screens/order/Order"));
// const ProcessDetails = lazy(() =>
//   import("../screens/process/details/ProcessDetails")
// );
// const OrderDetails = lazy(() =>
//   import("../screens/order/details/OrderDetails")
// );
// const Finnance = lazy(() => import("../screens/finnance/Finnance"));
// const FinnanceProforma = lazy(() =>
//   import("../screens/finnance/FinnanceProforma.js")
// );
// const Report = lazy(() => import("../screens/report/Report"));
// const NewUser = lazy(() => import("../screens/team/NewUser.js"));
// const DocumentTracking = lazy(() =>
//   import("../screens/documents/component/DocumentTracking")
// );

function Navigation() {
  const Auth = useContext(AuthContext);
  let userType = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (Auth.loading || Auth.status === "Idle") {
    return (
      <Skeleton
        variant="rectangular"
        height={50}
        style={{ width: "100%", marginBottom: "1rem" }}
      />
    );
  }

  return (
    <BrowserRouter basename={basename}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<AppInitialize />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            <Route path={routeConstant.DASHBOARD} element={<Dashboard />} />
            <Route path={routeConstant.PROCESS} element={<Process />}>
              <Route
                path={routeConstant.PROCESSDETAILS}
                element={<ProcessDetails />}
              />
            </Route>

            <Route path={routeConstant.ORDER} element={<Order />}>
              <Route
                path={routeConstant.ORDERDETAILS}
                element={<OrderDetails />}
              />
            </Route>
            <Route path={routeConstant.FINANCE} element={<Finnance />}>
              <Route
                path={routeConstant.FINNANCE_INVOICE}
                element={<FinanceInvoice />}
              />
              <Route
                path={routeConstant.FINNANCE_PROFORMA}
                element={<FinnanceProforma />}
              />
            </Route>

            <Route path={routeConstant.GROUPPAYMENT} element={<Payment />} />
            <Route
              path={routeConstant.PAYMENTREVIEW}
              element={<ReviewPayment />}
            />
            {userType?.Roles === "Admin" ? (
              <Route path={routeConstant.TEAM} element={<Team />} />
            ) : null}

            <Route path={routeConstant.NEWUSER} element={<NewUser />} />
            <Route path={routeConstant.EDITUSER} element={<EditUser />} />

            {/* <Route path={routeConstant.NOTIFICATION} element={<Notification />}>
              <Route path={routeConstant.THREEDAYS} element={<Threedays />} />
              <Route path={routeConstant.FIVEDAYS} element={<Fivedays />} />
              <Route path={routeConstant.SEVENDAYS} element={<Sevendays />} />
            </Route> */}
            <Route path={routeConstant.DOCUMENTS} element={<Documents />}>
              <Route
                path={routeConstant.DOCUMENTPENDING}
                element={<Pending />}
              />
              <Route
                path={routeConstant.DOCUMENTAPPROVED}
                element={<Approved />}
              />
              <Route
                path={routeConstant.DOCUMENTDISAPPROVED}
                element={<Disapproved />}
              />
            </Route>
            <Route
              path={routeConstant.REPORT}
              element={<Navigate to={routeConstant.OVERVIEW} replace={true} />}
            />
            <Route path={routeConstant.REPORT} element={<Report />}>
              <Route path={routeConstant.OVERVIEW} element={<Overview />} />
              <Route path={routeConstant.TRANSPORT} element={<Transport />} />
              <Route path={routeConstant.DATAEXPORT} element={<Dataexport />} />
              <Route path={routeConstant.GENERAL} element={<General />} />
              <Route path={routeConstant.DEADLINE} element={<Deadline />} />
              <Route path={routeConstant.INCOTERM} element={<Incoterm />} />
              <Route path={routeConstant.CORBON} element={<Corbon />} />
              {/* <Route path={routeConstant.DEMURRAGE} element={<Demurrage />} /> */}
              <Route path={routeConstant.NEWPREST} element={<Newpreset />} />
              <Route
                path={routeConstant.THRESHOLDSETTING}
                element={<Thresholdsetting />}
              />
              <Route path={routeConstant.AIR} element={<Air />} />
              <Route path={routeConstant.SEA} element={<Sea />} />
            </Route>
          </Route>
          <Route
            path={routeConstant.LOGIN}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
           
          <Route
            path={routeConstant.FORGETPASSWORD}
            element={
              <PublicRoute>
                <Forgetpassword />
              </PublicRoute>
            }
          />
          {/* <Route
            path={routeConstant.TWOSTEPVERIFICATIO}
            element={
              <PublicRoute>
                <Verification />
              </PublicRoute>
            }
          /> */}
          <Route
            path={routeConstant.DOCUMENTTRACKING}
            element={<DocumentTracking />}
          />
          <Route
            path={routeConstant.NEWPASSWORD}
            element={
              <PublicRoute>
                <NewPassword />
              </PublicRoute>
            }
          />

          {/* <Route
            path={routeConstant.TWOSTEPVERIFICATIO}
            element={<Verification />}
          /> */}
          <Route path={routeConstant.RESETPASSWORD} element={<NewPassword />} />
          <Route path="*" element={<NoRoute />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export { Navigation };
