import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import "../process.css";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BtnOutline from "../../../components/button/BtnOutline";
import Nodata from "../../../components/Nodata";
import { useLocation } from "react-router-dom";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { Date as DateFormat } from "../../../utils/moment";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import { genrateDownloadLink } from "../../../utils/utility";

const Documents = ({ t }) => {
  const location = useLocation();
  const [length, setLength] = useState(2);
  const [shipmentDocs, setShipmentDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  const clickShowmore = () => {
    if (length === 2) {
      setLength(shipmentDocs.length);
    } else {
      setLength(2);
    }
  };

  const getShipmentDocs = () => {
    setLoading(true);
    const payload = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
      ShipmentID: location?.state?.item?.ShipmentID ?? "",
      DocType: "",
      DocumentName: "",
      DocumentDate: "",
    };
    instance
      .post(API_PATH.GET_SHIPMENT_DOCS, payload, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          if (res._Data && res._Data.length) {
            res._Data.map((item) => {
              item.SC_ImageDataHasValue = false;
            });
          }
          setShipmentDocs(
            res._Data.sort((a, b) => {
              return new Date(b.SC_Date) - new Date(a.SC_Date);
            })
          );
        } else {
          setShipmentDocs([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setShipmentDocs([]);
        console.log("shipment docs api fail", err);
      })
      .finally(() => {
        if (!checkAbort) setLoading(false);
      });
  };

  const onClickDownloadDocs = (item, index) => {
    if (!item.SC_ImageDataHasValue) {
      item.SC_ImageDataHasValue = true;
      const arr = [...shipmentDocs];
      arr[index] = { ...item };
      setShipmentDocs([...arr]);

      const payload = {
        OrgCodes: "",
        EmailId: "",
        SortColumnName: "",
        SortDirection: "",
        CurrentPage: 0,
        PageSize: 10,
        ShipmentID: location?.state?.item?.ShipmentID ?? "",
        DocType: item?.SC_DocType ?? "",
        DocumentName: item?.SC_FileName ?? "",
        DocumentDate: item?.SC_Date ?? "",
        DocumentPk: item?.SC_PK ?? "",
      };
      instance
        .post(API_PATH.DOWNLOAD_SHIPMENT_DOCS, payload, { signal })
        .then((res) => {
          const downloadLink = document.createElement("a");
          const prefix = genrateDownloadLink(item?.SC_FileName);
          downloadLink.href = `${prefix}${res?._Data?.ImageDataVC}`;
          // }
          if (prefix) {
            downloadLink.download = item?.SC_FileName ?? "";
            downloadLink.click();
          } else {
            toast.error("Invalid document found!");
          }

          item.SC_ImageDataHasValue = false;
          const arr = [...shipmentDocs];
          arr[index] = { ...item };
          setShipmentDocs([...arr]);
        })
        .catch((err) => {
          console.log("download cargo docs error", err);
        });
    }
  };

  useEffect(() => {
    getShipmentDocs();

    // return () => {
    //   setCheckAbort(true);
    //   return controller.abort();
    // };
  }, [location?.state?.item?.ShipmentID]);

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={110}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      ) : (
        <div className="payment">
          <p className="color-grey2 payment-heading">{t("CargoDocuments")}</p>
          <div className="div-details-payments documents">
            {shipmentDocs.length ? (
              shipmentDocs.slice(0, length).map((item, index) => {
                return (
                  <div className="div-details-child" key={index}>
                    <p
                      className="color-grey"
                      style={
                        {
                          // marginLeft: "1rem",
                        }
                      }
                    >
                      {item.SC_DocType}
                    </p>
                    <p
                      className="color-blue2"
                      disabled={item.SC_ImageDataHasValue}
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickDownloadDocs(item, index)}
                    >
                      <PictureAsPdfOutlinedIcon />
                      {item.SC_ImageDataHasValue ? (
                        <span>Downloading</span>
                      ) : (
                        <span>{item.SC_FileName}</span>
                      )}
                    </p>
                    <p
                      className="payment-data"
                      style={{ fontFamily: "siena-light" }}
                    >
                      {t("Uploaded")}: {DateFormat(item?.SC_Date ?? null)}
                    </p>
                  </div>
                );
              })
            ) : (
              <Nodata />
            )}
          </div>

          {shipmentDocs && shipmentDocs.length > 2 ? (
            <div className="d-flex justify-content-end buttonline">
              <BtnOutline
                onClick={() => clickShowmore()}
                title={length <= 2 ? t("ShowMore") : t("ShowLess")}
              ></BtnOutline>
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(Documents));
