import React from "react";
import "./button.css";
function Btn(props) {
  const { onClick = () => {}, title = "" } = props;
  return (
    <div>
      <button onClick={onClick} type="button" className="btnn-section ">
        {title}
      </button>
    </div>
  );
}

export default Btn;
