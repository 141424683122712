import React, { useEffect, useState } from "react";
import "./styles.css";
import DoughnutChart from "./chart/DoughnutChart";
import { basename } from "../../../Config";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "../../../routing";
import { withTranslation } from "react-i18next";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { Skeleton } from "@mui/material";
import { toast } from "react-hot-toast";
import urlResolver from "../../../utils/urlResolver";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
function Deadline(props) {
  const navigate = useNavigate();
  const [calender, setCalender] = useState({
    startdate: "",
    enddate: "",
    type: "SEA",
  });
  const { t } = props;

  const [deadLineData, setDeadLineData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDeadlineAnalysis();
  }, []);
  const searchData = () => {
    getDeadlineAnalysis();
  };
  const getDeadlineAnalysis = (filter = calender) => {
    const payload = {
      TransportMode: filter.type,
      CompanyCode: "",
      CNJPNumber: "",
      OriginPortName: "",
      DestinationPortName: "",
      StartDate: filter.startdate,
      EndDate: filter.enddate,
      ShipmentType: filter.type === "AIR" ? "LSE" : "LCL,FCL",
      IsFilterApplied: true,
      ShipmentMode: "",
    };

    setLoading(true);
    instance
      .post(API_PATH.GET_DEADLINE_ANALYSIS, payload)
      .then((res) => {
        if (res._Success) {
          setDeadLineData(res._GenericValue);
        }
      })
      .catch((err) => {
        console.log("deadlineErr", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const dayjs = require("dayjs");
  const language = t("ConditionName");
  return (
    <div>
      <div>
        <p className="head-trans">{t("Deadlineanalysis")}</p>
      </div>
      <div className="deadline-analytics d-flex justify-content-between flex-column flex-md-row">
        <div
          style={{ width: "65%" }}
          className="d-flex  flex-column flex-md-row "
        >
          <div className="d-flex  flex-column flex-md-row">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="date-picker"
                value={calender.startdate}
                onChange={(newValue) => {
                  const obj = { ...calender };
                  obj.startdate = dayjs(newValue).format("YYYY-MM-DD");
                  setCalender(obj);
                }}
                format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="date-picker"
                value={calender.enddate}
                onChange={(newValue) => {
                  const obj = { ...calender };
                  obj.enddate = dayjs(newValue).format("YYYY-MM-DD");
                  setCalender(obj);
                }}
                format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
              />
            </LocalizationProvider>
            {/* <input
              className="input-date calender"
              type="date"
              max="9999-12-31"
              value={calender.startdate}
              onChange={(event) => {
                const obj = { ...calender };
                obj.startdate = event.target.value;
                setCalender(obj);
              }}
            />

            <input
              className="input-date calender"
              type="date"
              max="9999-12-31"
              value={calender.enddate}
              onChange={(event) => {
                const obj = { ...calender };
                obj.enddate = event.target.value;
                setCalender(obj);
              }}
            /> */}
          </div>
          <div className=" transport-mid d-flex justify-content-between justify-content-md-none  ">
            <div className="d-flex justify-content-between align-items-center calender-section  ">
              <p className="props-type">{t("Type")}</p>

              <p
                className={
                  calender.type === "SEA"
                    ? "props-text active-color"
                    : "props-text"
                }
                onClick={() => {
                  const obj = { ...calender };
                  if (obj.type !== "SEA") {
                    obj.type = "SEA";
                    setCalender(obj);
                    getDeadlineAnalysis(obj);
                  }
                }}
              >
                {t("Sea")}
              </p>

              <p
                className={
                  calender.type === "AIR"
                    ? "props-text active-color"
                    : "props-text"
                }
                onClick={() => {
                  const obj = { ...calender };
                  if (obj.type !== "AIR") {
                    obj.type = "AIR";
                    setCalender(obj);
                    getDeadlineAnalysis(obj);
                  }
                }}
              >
                {"Air"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  onClick={() => {
                    if (
                      calender.startdate.length > 0 &&
                      calender.enddate.length > 0
                    ) {
                      getDeadlineAnalysis(calender);
                    } else {
                      toast.error("Please enter start & end date!");
                    }
                  }}
                  className="btn-filter"
                >
                  {t("Search")}
                </button>
              </div>
              <div>
                <button
                  className="btn-filter btn-reset"
                  style={{
                    minWidth: "43px",
                  }}
                  onClick={() => {
                    const obj = {
                      type: "SEA",
                      enddate: "",
                      startdate: "",
                    };
                    setCalender(obj);
                    getDeadlineAnalysis(obj);
                  }}
                >
                  &gt; {t("Reset")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="dead-div">
          <button
            className="dead-button d-flex
            align-items-center"
            onClick={() =>
              navigate(
                `${routeConstant.REPORT}/${routeConstant.THRESHOLDSETTING}`
              )
            }
          >
            {t("EditThreshold")}
            <img
              src={urlResolver("icon/icon-pencil.svg")}
              malt=""
              className="ms-2"
              style={{ width: "1rem" }}
            />
          </button>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row head-deadline">
          {loading ? (
            <Skeleton
              variant="rectangular"
              height={365}
              style={{ width: "100%", marginBottom: "1rem" }}
            />
          ) : (
            deadLineData.map((item, index) => {
              let less = 0;
              let greate = 0;
              if (item.TotalCount) {
                less = (
                  (item.LessThenEqualSeven * 100) /
                  item.TotalCount
                ).toFixed(2);
                greate = (
                  (item.GreaterThenSeven * 100) /
                  item.TotalCount
                ).toFixed(2);
              }

              return (
                <div key={item.Type} className="col-6 mb-3">
                  <div className="head-mid-deadline style-head-mid">
                    <p style={{ paddingBottom: "32px" }}>
                      {item.Type}
                      {/* {t("Cargoreadyxloaded")} */}
                    </p>
                    <div
                      className="d-flex justify-content-between align-items-center flex-column flex-md-row "
                      style={{ paddingBottom: "32px" }}
                    >
                      <DoughnutChart
                        labels={["Delay", "On Time"]}
                        value={[item.GreaterThenSeven, item.LessThenEqualSeven]}
                      />
                      <div
                        className=" display-md-block  pt-5 pt-md-0 "
                        style={{ width: "35%" }}
                      >
                        <div className="information-purpe">
                          <button className="btn-information-purpe">
                            {greate}%
                          </button>
                          <div className="ps-2">
                            <p className="text-size-purpe">
                              &gt; {" " + item.SettingValue + " "} {t("Days")}
                            </p>
                            <p className="text-small-purpe">
                              {item.GreaterThenSeven}
                            </p>
                          </div>
                        </div>
                        <div className="information-green">
                          <button className="btn-information-green">
                            {less}%
                          </button>
                          <div className="ps-2">
                            <p className="text-size-green">
                              &lt; {" " + item.SettingValue + " "} {t("Days")}
                            </p>
                            <p className="text-size-green">
                              {item.LessThenEqualSeven}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Deadline);
