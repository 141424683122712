import { basename } from "../../Config";

const routeConstant = {
  //APP Init
  APP_INIT: "/",

  //dashboard route
  DASHBOARD: `/dashboard`,

  //process route
  PROCESS: `/process`,
  PROCESSDETAILS: `details`,

  // order route
  ORDER: `/orders`,
  ORDERDETAILS: `details`,

  //notification route
  NOTIFICATION: `/notification`,
  THREEDAYS: `threedays`,
  FIVEDAYS: `fivedays`,
  SEVENDAYS: `sevendays`,

  //documents dashboard
  DOCUMENTS: `/documents`,
  DOCUMENTPENDING: `pending`,
  DOCUMENTAPPROVED: `approved`,
  DOCUMENTDISAPPROVED: `disapproved`,
  DOCUMENTTRACKING: "/DocumentTracking/:id",

  // finnance route
  FINANCE: "/financial",
  FINNANCE_INVOICE: `invoice`,
  FINNANCE_PROFORMA: "proforma",
  GROUPPAYMENT: `/financial/group-payment`,
  PAYMENTREVIEW: `/financial/payment-review`,

  //teams routes
  TEAM: `/myteam`,
  NEWUSER: `/myteam/new-user`,
  EDITUSER: "/myteam/edit-user",

  //report routes
  REPORT: `/report`,
  OVERVIEW: `overview`,
  TRANSPORT: `transported`,
  DATAEXPORT: `data`,
  GENERAL: `general`,
  DEADLINE: `deadline`,
  INCOTERM: `incoterm`,
  DEMURRAGE: `demurrage`,
  NEWPREST: `data/newpreset`,
  CORBON: `corbon`,
  THRESHOLDSETTING: `deadline/thresholdsetting`,
  AIR: `air`,
  SEA: `sea`,

  //auth routes
  LOGIN: `/login`,
  FORGETPASSWORD: `/forget-password`,
  // TWOSTEPVERIFICATIO: `/two-step-verification`,
  RESETPASSWORD: `/reset-password/:id`,
};

export { routeConstant };
