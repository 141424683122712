import React from "react";
import Tooltip from "@mui/material/Tooltip";
import AirplaneIcon from "@mui/icons-material/AirplanemodeActive";
import ShipIcon from "@mui/icons-material/LocalShipping";
import TrainIcon from "@mui/icons-material/Train";
import InventoryIcon from "@mui/icons-material/Inventory";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import urlResolver from "../../../../utils/urlResolver";
function CarbonBarChart({ carbonData }) {
  const maxValue = 5000;
  return (
    <>
      {carbonData.map((item, index) => {
        const barPercentage =
          (parseInt(item.CO2Amount) * 100) / parseInt(maxValue);
        if (item.CO2Amount && item.CO2Unit) {
          return (
            <div
              className="carbon-horizontal-bar d-flex align-items-center"
              key={index}
            >
              <div className="pe-2 ">
                {item.TransportMode === "SEA" ? (
                  <img src={urlResolver("icon/icon-ship.svg")} />
                ) : item.TransportMode === "AIR" ? (
                  <img src={urlResolver("icon/icon-plane-white.svg")} />
                ) : item.TransportMode === "ROA" ? (
                  <img
                    className="transport-imgs"
                    src={urlResolver("icon/truck_color.svg")}
                  />
                ) : item.TransportMode === "COU" ? (
                  <img src={urlResolver("icon/icon-plane-white.svg")} />
                ) : null}
              </div>
              <Tooltip
                placement="top"
                title={`${item.TransportMode}: ${item.CO2Amount} ${item.CO2Unit} `}
                arrow
                style={{ color: "red", width: "97%" }}
              >
                <div className="progress carbon-horizontal-progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${barPercentage}%` }}
                    aria-valuemin="0"
                    aria-valuemax={maxValue}
                  />
                </div>
              </Tooltip>
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

export default CarbonBarChart;
