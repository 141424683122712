import React, { useEffect, useState } from "react";
import "./notification.css";
import { withTranslation } from "react-i18next";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { routeConstant } from "../../routing";
import { basename } from "../../Config";
import urlResolver from "../../utils/urlResolver";
function Notification(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = props;
  useEffect(() => {
    navigate(routeConstant.THREEDAYS);
  }, []);

  return (
    <div className="notification-division">
      <div className="notification-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 notification-head p-0">
              <p className="p-0">{t("Notification")}</p>
            </div>
          </div>
        </div>
        <div className="notification-filter">
          <div className="filters  d-flex justify-content-between align-items-center">
            <div>
              <div>
                <p
                  onClick={() => {
                    navigate(routeConstant.THREEDAYS);
                  }}
                  className={
                    location.pathname.includes(routeConstant.THREEDAYS)
                      ? "text-filter active-color"
                      : "text-filter"
                  }
                >
                  {t("3DAYS")}
                </p>
              </div>
              <div
                className={
                  (location.pathname.includes(routeConstant.THREEDAYS)
                    ? " circle-3days "
                    : location.pathname.includes(routeConstant.SEVENDAYS)
                    ? "circle-7days"
                    : "circle-5days") + " circle-days"
                }
              >
                {location.pathname.includes(routeConstant.THREEDAYS) && (
                  <img src={urlResolver("icon/new.svg")} />
                )}
              </div>
            </div>
            <div>
              <div>
                <p
                  onClick={() => {
                    navigate(routeConstant.FIVEDAYS);
                  }}
                  className={
                    location.pathname.includes(routeConstant.FIVEDAYS)
                      ? "text-filter active-color"
                      : "text-filter"
                  }
                >
                  {t("5DAYS")}
                </p>
              </div>
              <div
                className={
                  (location.pathname.includes(routeConstant.THREEDAYS)
                    ? " circle-3days "
                    : location.pathname.includes(routeConstant.SEVENDAYS)
                    ? "circle-7days"
                    : "circle-5days") + " circle-days"
                }
              >
                {location.pathname.includes(routeConstant.FIVEDAYS) && (
                  <img src={urlResolver("icon/new.svg")} />
                )}
              </div>
            </div>
            <div>
              <div>
                <p
                  onClick={() => {
                    navigate(routeConstant.SEVENDAYS);
                  }}
                  className={
                    location.pathname.includes(routeConstant.SEVENDAYS)
                      ? "text-filter active-color"
                      : "text-filter"
                  }
                >
                  {t("7DAYS")}
                </p>
              </div>
              <div
                className={
                  (location.pathname.includes(routeConstant.THREEDAYS)
                    ? " circle-3days "
                    : location.pathname.includes(routeConstant.SEVENDAYS)
                    ? "circle-7days"
                    : "circle-5days") + " circle-days"
                }
              >
                {location.pathname.includes(routeConstant.SEVENDAYS) && (
                  <img src={urlResolver("icon/new.svg")} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default withTranslation()(Notification);
