import React from "react";
import "./nodata.css";
import { withTranslation } from "react-i18next";


const Nodata = (props) => {
  let t = props.t
  return (
    <div
      className="no-data "
      style={{
        color: props?.color ?? "var(--color-blue2)",
        paddingTop: "9rem",
      }}
    >
      {props?.text ?? t("NodataFound")}
    </div>
  );
};

// export default Nodata;
export default withTranslation()(React.memo(Nodata));
