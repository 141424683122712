import React, { useState, useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConstant } from "../../routing/utils/routeConstant";
import "./header.css";
import "../../screens/Dashboard/index.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import {
  LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_USER_KEY,
  basename,
} from "../../Config";
import { UserService } from "../../services/user-service";
import { AuthContext } from "../../store/context/AuthProvider";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import urlResolver from "../../utils/urlResolver";
import { withTranslation } from "react-i18next";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
const drawerWidth = 240;

function Header(props) {
  let navItems = [
    { key: "IsPorcess", value: "Process", name: "process" },
    { key: "IsOrder", value: "Orders", name: "orders" },
    { key: "IsFinance", value: "Financial", name: "financial" },
    { key: "IsReport", value: "Report", name: "report" },
    { key: "IsMyTeam", value: "My team", name: "my team" },
  ];
  const { t } = props;
  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  const [logout, setLogout] = useState(true);
  const Navigate = useNavigate();
  const Auth = useContext(AuthContext);
  const context = useContext(AuthContext);
  const location = useLocation();
  const { permissions } = useSelector((state) => state.permission);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let userType = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  let userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  let userName = userInfo && userInfo?.Name ? userInfo?.Name : "";
  // let Name = "";
  // console.log("nameeee", userInfo);
  const onClickLogout = () => {
    instance
      .post(`${API_PATH.LOGOUT}`)
      .then((res) => {
        if (res?.Success) {
          localStorage.clear();
          Auth.logout();
          Navigate(routeConstant.LOGIN);
          localStorage.setItem("Status", true);
        }
      })
      .catch((err) => {})
      .finally((f) => {});

    // UserService.logout();
    // setLogout(true);
    // Navigate(routeConstant.LOGIN);
  };

  const handleDrawerToggle = (event) => {
    setMobileOpen(!mobileOpen);
  };
  const onClickMenuItem = (item) => {
    const pathOrder = `${routeConstant.ORDER}`;
    const pathProcess = `${routeConstant.PROCESS}`;
    if (item === "dashboard") {
      Navigate(routeConstant.DASHBOARD);
    } else if (item === "process") {
      Navigate(pathProcess);
    } else if (item === "orders") {
      Navigate(pathOrder);
    } else if (item === "financial") {
      Navigate(`${routeConstant.FINANCE}/invoice`);
    } else if (item === "report") {
      Navigate(routeConstant.REPORT);
    } else if (item == "my team") {
      Navigate(routeConstant.TEAM);
    }
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }} className="Cross-img">
        <CloseIcon onClick={handleDrawerToggle} />
      </Typography>
      <Divider />
      <List>
        <ListItem
          disablePadding
          onClick={() => {
            handleDrawerToggle();
            onClickMenuItem("dashboard");
          }}
        >
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText primary={t("Dashboard")} />
          </ListItemButton>
        </ListItem>

        {navItems.map((item, index) => {
          if (permissions[item.key]) {
            return (
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  handleDrawerToggle();
                  onClickMenuItem(item.name);
                }}
              >
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText primary={t(item.value)} />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </List>
      <div className="header-button">
        <button
          className={i18n.language === "en" ? "active-lang" : ""}
          onClick={changeLanguage}
          value="en"
        >
          EN
        </button>
        <button
          className={i18n.language === "pt" ? "active-lang" : ""}
          onClick={changeLanguage}
          value="pt"
        >
          PT
        </button>
        <button
          className={i18n.language === "es" ? "active-lang" : ""}
          onClick={changeLanguage}
          value="es"
        >
          ES
        </button>
      </div>
      {/* <div className="bell-icon" style={{ paddingTop: "2rem" }}>
        <img
          src={`${basename}/icon/icon-bell.svg`}
          onClick={() => {
            Navigate(routeConstant.NOTIFICATION);
            handleDrawerToggle();
          }}
        />
      </div> */}
      <div
        className="user-notification"
        style={{ flexDirection: "column", paddingTop: "2rem" }}
      >
        <p
          className="user-name"
          style={{ paddingBottom: "2rem", fontSize: "1.45rem" }}
        >
          {/* Hello, {userType.Name} */}
          Hello, {userName}
        </p>
        <button>
          <img
            style={{ marginRight: "1rem" }}
            // src={`${basename}/icon/icon-user.svg`}
            src={urlResolver(`icon/icon-user.svg`)}
          />
          {/* {logout ? (
            <ArrowDropUpIcon
              style={{ color: "#fff", fontSize: "3.5rem" }}
              onClick={(e) => {
                setLogout(false);
              }}
            />
          ) : (
            <ArrowDropDownIcon
              style={{ color: "#fff", fontSize: "3.5rem" }}
              onClick={(e) => {
                setLogout(true);
              }}
            />
          )} */}
          <img
            className={
              !logout
                ? "img-arrow-user "
                : "img-arrow-user  active-img-arrow-user"
            }
            onClick={(e) => {
              setLogout(!logout);
            }}
            src={urlResolver("icon/icon-arrow-user.svg")}
          />
        </button>

        {!logout ? (
          <div className="logoutButton-small">
            <div>
              <p onClick={() => onClickLogout()}>Log out</p>
            </div>
          </div>
        ) : null}
      </div>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className=" drawer-nav ">
      <Box sx={{ display: "flex" }}>
        <AppBar
          style={{ backgroundColor: "var(--color-blue2)" }}
          component="nav"
        >
          <div className="container-fluid">
            <Toolbar
              sx={{
                padding: "0 !important",
                // maxWidth: "1640px",
                width: "calc(100% - 40px)",
                margin: "0 auto",
                height: "87px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="main-div-small">
                <IconButton
                  color="inherit"
                  className="mui-button-small"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { lg: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <img
                  src={urlResolver("icon/logo-asia-tracking-white.svg")}
                  alt="logo"
                  className="small-screen-logo"
                />
              </div>

              <Typography
                className="mui-typography"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: "none", lg: "block" } }}
              >
                <img
                  src={urlResolver("icon/logo-asia-tracking-white.svg")}
                  alt="logo"
                />
              </Typography>
              <Box
                sx={{ display: { xs: "none", lg: "block" } }}
                className="mui-box-div"
              >
                <div className="header-page">
                  <div className="header-page-div">
                    <div className="header-page-first-child">
                      <button
                        className="header-btn "
                        style={
                          location.pathname === routeConstant.DASHBOARD
                            ? { color: "white" }
                            : { color: "#ffffff6b" }
                        }
                        onClick={() => {
                          onClickMenuItem("dashboard");
                        }}
                      >
                        {t("Dashboard")}
                      </button>
                      <button
                        className="header-btn "
                        // key={index}
                        style={
                          location.pathname.includes(
                            "process".replace(/\s+/g, "")
                          )
                            ? { color: "white" }
                            : { color: "#ffffff6b" }
                        }
                        onClick={() => {
                          onClickMenuItem("process");
                        }}
                      >
                        {t("Process")}
                      </button>
                      <button
                        className="header-btn "
                        // key={index}
                        style={
                          location.pathname.includes(
                            "orders".replace(/\s+/g, "")
                          )
                            ? { color: "white" }
                            : { color: "#ffffff6b" }
                        }
                        onClick={() => {
                          onClickMenuItem("orders");
                        }}
                      >
                        {t("Orders")}
                      </button>
                      <button
                        className="header-btn "
                        // key={index}
                        style={
                          location.pathname.includes(
                            "financial".replace(/\s+/g, "")
                          )
                            ? { color: "white" }
                            : { color: "#ffffff6b" }
                        }
                        onClick={() => {
                          onClickMenuItem("financial");
                        }}
                      >
                        {t("Financial")}
                      </button>
                      <button
                        className="header-btn "
                        // key={index}
                        style={
                          location.pathname.includes(
                            "report".replace(/\s+/g, "")
                          )
                            ? { color: "white" }
                            : { color: "#ffffff6b" }
                        }
                        onClick={() => {
                          onClickMenuItem("report");
                        }}
                      >
                        {t("Report")}
                      </button>
                      {userType?.Roles === "Admin" ? (
                        <button
                          className="header-btn "
                          // key={index}
                          style={
                            location.pathname.includes(
                              "my team".replace(/\s+/g, "")
                            )
                              ? { color: "white" }
                              : { color: "#ffffff6b" }
                          }
                          onClick={() => {
                            onClickMenuItem("my team");
                          }}
                        >
                          {t("My team")}
                        </button>
                      ) : null}

                      {/* {navItems.map((item, index) => {
                        if (permissions[item.key]) {
                          return (
                            <button
                              className="header-btn "
                              key={index}
                              style={
                                location.pathname.includes(
                                  item.value.replace(/\s+/g, "")
                                )
                                  ? { color: "white" }
                                  : { color: "#ffffff6b" }
                              }
                              onClick={() => {
                                onClickMenuItem(item.value);
                              }}
                            >
                              {item.value}
                            </button>
                          );
                        }
                      })} */}
                    </div>
                  </div>
                  <div className="user-main">
                    <div className="user-notification">
                      <p className="user-name">
                        {t("Hello")}, {userName}
                      </p>
                      <button
                        onClick={(e) => {
                          setLogout(!logout);
                        }}
                      >
                        <img
                          style={{ marginRight: "1rem" }}
                          src={urlResolver("icon/icon-user.svg")}
                        ></img>

                        <img
                          className={
                            logout === true
                              ? "img-arrow-user "
                              : "img-arrow-user  active-img-arrow-user"
                          }
                          src={urlResolver("icon/icon-arrow-user.svg")}
                        />
                      </button>

                      {!logout ? (
                        <div className="logoutButton">
                          <p onClick={() => onClickLogout()}>Log out</p>
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="bell-icon">
                      <img
                        alt="notificationicon"
                        style={
                          location.pathname.includes("notification")
                            ? {
                                opacity: "1",
                              }
                            : {
                                opacity: "0.7",
                              }
                        }
                        src={`${basename}/icon/icon-bell.svg`}
                        onClick={() => {
                          Navigate(routeConstant.NOTIFICATION);
                        }}
                      />
                    </div> */}
                    <div className="header-button">
                      <button
                        className={i18n.language === "en" ? "active-lang" : ""}
                        onClick={changeLanguage}
                        value="en"
                      >
                        EN
                      </button>
                      <button
                        className={i18n.language === "pt" ? "active-lang" : ""}
                        onClick={changeLanguage}
                        value="pt"
                      >
                        PT
                      </button>
                      <button
                        className={i18n.language === "es" ? "active-lang" : ""}
                        onClick={changeLanguage}
                        value="es"
                      >
                        ES
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </Toolbar>
          </div>
        </AppBar>

        <Box component="nav">
          <div className="container-fluid drawer-sidebar">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              hideBackdrop={true}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xl: "block", lg: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default withTranslation()(Header);
