import axios from "axios";
import { baseUrl, LOCAL_STORAGE_KEY } from "../Config";
import authHeader from "../services/auth-header";

const instance = axios.create({
  baseURL: baseUrl,
});
let isRefershing = false;
let concurrentReferesh = [];

instance.interceptors.request.use((config) => {
  const token = authHeader();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response.data;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // return Promise.reject(error);
    let count = 1;
    if (error.response.status === 401 && !isRefershing) {
      isRefershing = true;
      alert("Session timed out!");
      setTimeout(() => {
        isRefershing = false;
      }, 1000);

      localStorage.removeItem(LOCAL_STORAGE_KEY);
      window.location.reload();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
    // if (error.response.status === "401") {
    //   const originalRequest = error.config;

    //   waitingForTokenRefresh(originalRequest);
    //   if (!isRefershing) {
    //     isRefershing = true;
    //     axios
    //       .post("referesh token url")
    //       .then((response) => {
    //         // update the token into local storage, request and call the original request

    //         // originalRequest.Authorization = "Bearer token";
    //         concurrentReferesh.map((config) => {
    //           axios(config)
    //             .then((res) => {
    //               return res.data;
    //             })
    //             .catch((err) => {
    //               return err;
    //             });
    //         });
    //       })
    //       .catch((error) => {
    //         // logout here
    //       });
    //   }
    // }
  }
);
const waitingForTokenRefresh = (config) => {
  concurrentReferesh.push(config);
};

const callRequestOnWait = (re) => {};

export default instance;
