import React, { useState } from "react";
import { Date } from "../../../utils/moment";
import "./styles.css";

const DemurageCard = ({ item }) => {
  const [details, setDetails] = useState(false);
  const showMore = () => {
    setDetails(!details);
  };
  return (
    <div className="card-demu-top">
      <div className="card-demu">
        <div>
          <p>Shipment ID</p>
          <p>{item?.Jobnum ?? "N/A"}</p>
        </div>
        <div>
          <p>House Bill of Lading</p>
          <p> {item?.HouseBillNumber ?? "N/A"} </p>
        </div>
        <div>
          <p>Master Bill of Lading </p>
          <p>{item?.MasterBillNumber ?? "N/A"}</p>
        </div>
        <div>
          <p>Container</p>
          <p>{item?.Containernum ?? "N/A"} </p>
        </div>
        <div>
          <p>Type</p>
          <p>{item?.ContainerType ?? "N/A"}</p>
        </div>
        <div>
          <p>Vessel Arrival</p>
          <p>{Date(item?.VesselArrival ?? null)}</p>
        </div>
        <div>
          <p>Free time</p>
          <p>{item?.DemurrageFreeTime ?? "N/A"}</p>
        </div>
        <div>
          <p>Empty required</p>
          <p>{Date(item?.EmptyRequiredBy ?? null)}</p>
        </div>
        <div>
          <p>Empty return</p>
          <p>{Date(item?.EmptyReturnedOn ?? null)}</p>
        </div>
        <div>
          <p>Demurrage</p>
          <p>{item?.DaysPastFreeTime ?? "N/A"}</p>
        </div>
        <div>
          <p>Client Rate</p>
          <p>{item?.ClientRate ?? "N/A"}</p>
        </div>
        <div>
          <p>Total</p>
          <p>{item?.Total ?? "N/A"}</p>
        </div>
        {details ? (
          <>
            <div>
              <p>Origin</p>
              <p>{item?.Origin ?? "N/A"}</p>
            </div>
            <div>
              <p>Destination</p>
              <p>{item?.Destination ?? "N/A"}</p>
            </div>
            <div>
              <p>Carrier Name</p>
              <p>{`${item?.CarrierName ?? "N/A"} (${
                item?.CarrierCode ?? "N/A"
              })`}</p>
            </div>
            <div>
              <p>Consignee Name</p>
              <p>{`${item?.ConsigneeName ?? "N/A"} (${
                item?.ConsigneeCode ?? "N/A"
              })`}</p>
            </div>
          </>
        ) : null}
      </div>
      <div>
        <button type="button" className="btn-out-section" onClick={showMore}>
          {!details ? "Show Details" : "Show Less"}
        </button>
      </div>
    </div>
  );
};

export default DemurageCard;
