import moment from "moment/moment";

const Date = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
  return "N/A";
};

const Time = (date) => {
  if (date) {
    return moment(date).format("hh:mm a");
  }

  return "-";
};

export { Date, Time };
