import React, { useEffect, useState } from "react";
import "./styles.css";
import AddIcon from "@mui/icons-material/Add";
import BtnOutline from "../../../components/button/BtnOutline";
import ButtonIcon from "../../../components/button/ButtonIcon";
import { routeConstant } from "../../../routing";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { CircularProgress, Skeleton } from "@mui/material";
import { basename } from "../../../Config";
import { exportToExcel } from "react-json-to-excel";
import urlResolver from "../../../utils/urlResolver";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
function Dataexport(props) {
  const [loadingReportdata, setLoadingReportdata] = useState(false);
  const [calender, setCalender] = useState({
    startdate: "",
    enddate: "",
  });
  const [demuragecol, setDemuragecol] = useState([]);
  const [financecol, setFinancecol] = useState([]);
  const [shipmentcol, setShipmentcol] = useState([]);
  const [demuragecolView, setDemuragecolView] = useState([]);
  const [financecolView, setFinancecolView] = useState([]);
  const [shipmentcolView, setShipmentcolView] = useState([]);
  useEffect(() => {
    const data1 = demuragecol.filter((item) => {
      return item.value;
    });
    setDemuragecolView(data1);
    const data2 = shipmentcol.filter((item) => {
      return item.value;
    });
    setShipmentcolView(data2);
    const data3 = financecol.filter((item) => {
      return item.value;
    });
    setFinancecolView(data3);
  }, [demuragecol, financecol, shipmentcol]);

  const [loading, setLoading] = useState({
    finance: false,
    shipment: false,
    demurage: false,
  });

  const [exportType, setExportType] = useState({
    type: "",
    value: false,
  });

  const navigate = useNavigate();
  const { t } = props;

  useEffect(() => {
    GetColumnsForDemurage();
    GetColumnsForFinance();
    GetColumnsForShipment();
  }, []);

  const GetColumnsForDemurage = () => {
    setLoading({ ...loading, demurage: true });
    instance
      .post(API_PATH.GET_COLUMN_FOR_DEMURAGE)
      .then((res) => {
        setLoading({ ...loading, demurage: false });
        if (res._IsSuccess) {
          setDemuragecol(res?._Data ?? []);
        } else {
          setDemuragecol([]);
        }
      })
      .catch((err) => {
        setLoading({ ...loading, demurage: false });
        setDemuragecol([]);
        console.log("api for get columns for demurage error ", err);
      });
  };

  const GetColumnsForFinance = () => {
    setLoading({ ...loading, finance: true });
    instance
      .post(API_PATH.GET_COLUMN_FOR_FINANCE)
      .then((res) => {
        setLoading({ ...loading, finance: false });
        if (res._IsSuccess) {
          setFinancecol(res?._Data ?? []);
        } else {
          setFinancecol([]);
        }
      })
      .catch((err) => {
        setLoading({ ...loading, finance: false });
        setFinancecol([]);
        console.log("api for get columns for demurage error ", err);
      });
  };

  const GetColumnsForShipment = () => {
    setLoading({ ...loading, shipment: true });
    instance
      .post(API_PATH.GET_COLUMN_FOR_SHIPMENT)
      .then((res) => {
        setLoading({ ...loading, shipment: false });
        if (res._IsSuccess) {
          setShipmentcol(res?._Data ?? []);
        } else {
          setShipmentcol([]);
        }
      })
      .catch((err) => {
        setLoading({ ...loading, shipment: false });
        setShipmentcol([]);
        console.log("api for get columns for demurage error ", err);
      });
  };

  const onClickEdit = (data = [], title = "", header = "") => {
    navigate(`${routeConstant.REPORT}/${routeConstant.NEWPREST}`, {
      state: {
        data,
        title,
        header,
      },
    });
  };

  const exportToXLS = () => {
    setLoadingReportdata(true);
    const API_URL =
      exportType.type === "Demurrage"
        ? API_PATH.GET_DEMURRAGE_REPORT_DATA
        : exportType.type === "Finnance"
        ? API_PATH.GET_FINANCE_REPORT_DATA
        : API_PATH.GET_SHIPMENT_REPORT_DATA;
    const body = {
      StartDate: calender.startdate ?? "",
      EndDate: calender.enddate ?? "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 0,
    };
    instance
      .post(API_URL, body)
      .then((res) => {
        setLoadingReportdata(false);
        if (res._IsSuccess) {
          const data = res._Data;
          let fileName = `${exportType.type}_Report_Data`;
          exportToExcel(data, fileName);
        } else {
        }
      })
      .catch((err) => {
        setLoadingReportdata(false);
        console.log("Get Report data api fail");
      });
  };

  const onExportTypeChange = (type, e) => {
    const obj = { ...exportType };
    obj.type = type;
    obj.value = e.target.checked;

    setExportType({ ...obj });
  };
  const dayjs = require("dayjs");
  const language = t("ConditionName");
  return (
    <div>
      <div className=" header-data d-flex justify-content-between align-items-center">
        <p className="head-trans">{t("ExportData")}</p>
        {/* <ButtonIcon
          onClick={() =>
            navigate(`${routeConstant.REPORT}/${routeConstant.NEWPREST}`)
          }
          title="New Preset"
        /> */}
      </div>
      {loading.demurage || loading.finance || loading.shipment ? (
        <div className="skeleton-type">
          <Skeleton style={{ height: "40rem" }} />
        </div>
      ) : (
        <div className="export-section">
          <p className="date-sec">{t("Date")}</p>
          <div className=" d-flex ">
            <div className="d-flex justify-content-between align-items-center calender-section">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="date-picker"
                  value={calender.startdate}
                  onChange={(newValue) => {
                    const obj = { ...calender };
                    obj.startdate = dayjs(newValue).format("YYYY-MM-DD");
                    setCalender(obj);
                  }}
                  format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="date-picker"
                  value={calender.enddate}
                  onChange={(newValue) => {
                    const obj = { ...calender };
                    obj.enddate = dayjs(newValue).format("YYYY-MM-DD");
                    setCalender(obj);
                  }}
                  format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                />
              </LocalizationProvider>

              {/* <input
                className="input-date calender"
                type="date"
                max="9999-12-31"
                value={calender.startdate}
                onChange={(event) => {
                  const obj = { ...calender };
                  obj.startdate = event.target.value;
                  setCalender(obj);
                }}
              />
              <input
                className="input-date calender"
                type="date"
                max="9999-12-31"
                value={calender.enddate}
                onChange={(event) => {
                  const obj = { ...calender };
                  obj.enddate = event.target.value;
                  setCalender(obj);
                }}
              /> */}
            </div>
          </div>
          <div className="mid-export-section">
            <p className="date-sec">{t("Preset")}</p>
            <div className="checkbox-section">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckShipment"
                  checked={
                    exportType.type === "Shipment" ? exportType.value : false
                  }
                  onChange={(e) => onExportTypeChange("Shipment", e)}
                />
                <div
                  className="d-flex justify-content-between"
                  style={{ marginLeft: "1.8rem" }}
                >
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckShipment"
                  >
                    <p className="checkbox-title">{t("StandardReport")}</p>

                    <p className="checkbox-text">
                      {shipmentcolView.map((item, index) => {
                        return index < shipmentcolView.length - 1
                          ? `${t(item?.DisplayColumnName ?? "")} , `
                          : `${t(item?.DisplayColumnName ?? "")} `;
                      })}
                    </p>
                  </label>
                  <div className="button-section d-flex p-0">
                    <button
                      className="edit-btn d-flex"
                      onClick={() =>
                        onClickEdit(shipmentcol, "Shipment", "StandardReport")
                      }
                    >
                      {t("edit")}
                      <img
                        className="edit-img"
                        src={urlResolver("icon/icon-pencil.svg")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckFinnance"
                  checked={
                    exportType.type === "Finnance" ? exportType.value : false
                  }
                  onChange={(e) => onExportTypeChange("Finnance", e)}
                />
                <div
                  className="d-flex justify-content-between"
                  style={{ marginLeft: "1.8rem" }}
                >
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckFinnance"
                  >
                    <p className="checkbox-title">{t("Financeiro")}</p>
                    <p className="checkbox-text">
                      {financecolView.map((item, index) => {
                        return index < financecolView.length - 1
                          ? `${t(item?.DisplayColumnName ?? "")} , `
                          : `${t(item?.DisplayColumnName ?? "")} `;
                      })}
                    </p>
                  </label>
                  <div className="button-section d-flex p-0">
                    <button
                      className="edit-btn d-flex"
                      onClick={() =>
                        onClickEdit(financecol, "Finance", "Financeiro")
                      }
                    >
                      {t("edit")}
                      <img
                        className="edit-img"
                        src={urlResolver("icon/icon-pencil.svg")}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDemurrage"
                  checked={
                    exportType.type === "Demurrage" ? exportType.value : false
                  }
                  onChange={(e) => onExportTypeChange("Demurrage", e)}
                />
                <div
                  className="d-flex justify-content-between"
                  style={{ marginLeft: "1.8rem" }}
                >
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDemurrage"
                  >
                    <p className="checkbox-title">{t("Demurrage")}</p>
                    <p className="checkbox-text">
                      {demuragecolView.map((item, index) => {
                        return index < demuragecolView.length - 1
                          ? `${item?.DisplayColumnName ?? ""} , `
                          : `${item?.DisplayColumnName ?? ""} `;
                      })}
                    </p>
                  </label>
                  <div className="button-section d-flex p-0">
                    <button
                      className="edit-btn d-flex"
                      onClick={() =>
                        onClickEdit(demuragecol, "Demmurage", "Demurrage")
                      }
                    >
                      {t("edit")}
                      <img
                        className="edit-img"
                        src={`${basename}/icon/icon-pencil.svg`}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <BtnOutline
            title={
              loadingReportdata ? (
                <CircularProgress
                  size="1rem"
                  style={{ color: "var(--color-blue1)" }}
                />
              ) : (
                t("Export")
              )
            }
            disabled={!exportType.value}
            onClick={exportToXLS}
          />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Dataexport);
