import React, { useState, useEffect } from "react";
import "./styles.css";
import HorizontalBarChart from "./chart/HorizontalBarChart";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { Skeleton, TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
function Incoterm(props) {
  const [loading, setLoading] = useState(false);
  const [calender, setCalender] = useState({
    startdate: "",
    enddate: "",
    mode: "SEA",
    type: "LCL,FCL",
  });
  const [datasets, setDatasets] = useState({
    maxValue: 0,
    data: [],
  });
  useEffect(() => {
    getIncotermAnalytics(calender);
  }, []);
  const dayjs = require("dayjs");
  const searchData = () => {
    getIncotermAnalytics(calender);
  };
  const getShipmentloadData = (data) => {
    if (data !== null) {
      let maxValue = 0;
      const arr = [];
      data.map((item) => {
        if (maxValue < item.Shipments) {
          maxValue = item.Shipments;
        }
        const temp = {
          lable: item.Incoterm,
          value: item.Shipments,
        };
        arr.push(temp);
      });
      setDatasets({ ...datasets, maxValue: maxValue, data: [...arr] });
    } else {
      setDatasets({ maxValue: 0, data: [] });
    }
  };
  const getIncotermAnalytics = (calender) => {
    setLoading(true);
    const params = {
      TransportMode: calender.mode,
      CompanyCode: "",
      CNJPNumber: "",
      OriginPortName: "",
      DestinationPortName: "",
      StartDate: calender.startdate ?? "",
      EndDate: calender.enddate ?? "",
      ShipmentType: calender.type,
      IsFilterApplied: false,
      ShipmentMode: "",
    };
    instance
      .post(API_PATH.GET_INCOTERM_ANALYTICS, params)
      .then((response) => {
        setLoading(false);
        if (response._Success) {
          getShipmentloadData(response?._GenericValue ?? null);
        } else {
          setDatasets({ maxValue: 0, data: [] });
        }
      })
      .catch((error) => {
        setLoading(false);
        setDatasets({ maxValue: 0, data: [] });
        console.log("get inco terms api fail", error);
      });
  };

  const { t } = props;
  const language = t("ConditionName");

  return (
    <>
      <div>
        <div>
          <p className="head-trans">{t("Incotermanalysis")}</p>
        </div>
        <div className="Transport-section d-flex  flex-column flex-md-row">
          <div className="d-flex  calender-section flex-column flex-md-row justify-content-between justify-content-md-none">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="date-picker"
                value={calender.startdate}
                onChange={(newValue) => {
                  const obj = { ...calender };
                  obj.startdate = dayjs(newValue).format("YYYY-MM-DD");
                  setCalender(obj);
                }}
                format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="date-picker"
                value={calender.enddate}
                onChange={(newValue) => {
                  const obj = { ...calender };
                  obj.enddate = dayjs(newValue).format("YYYY-MM-DD");
                  setCalender(obj);
                }}
                format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
              />
            </LocalizationProvider>
            {/* <input
              className="input-date calender"
              type="date"
              max="9999-12-31"
              value={calender.startdate}
              onChange={(event) => {
                const obj = { ...calender };
                obj.startdate = event.target.value;
                setCalender(obj);
              }}
            /> */}
            {/* <input
              className="input-date calender"
              type="date"
              max="9999-12-31"
              value={calender.enddate}
              onChange={(event) => {
                const obj = { ...calender };
                obj.enddate = event.target.value;
                setCalender(obj);
              }}
            /> */}
          </div>
          <div className=" transport-mid d-flex flex-md-grow-0 flex-grow-1  ">
            <div className="d-flex justify-content-between align-items-center calender-section  ">
              <p className="props-type ">{t("Type")}</p>

              <p
                className={
                  calender.mode === "SEA"
                    ? "props-text active-color"
                    : "props-text"
                }
                onClick={() => {
                  const obj = { ...calender };
                  if (obj.mode != "SEA") {
                    obj.mode = "SEA";
                    obj.type = "LCL,FCL";
                    setCalender(obj);
                    getIncotermAnalytics(obj);
                  }
                }}
              >
                {t("Sea")}
              </p>

              <p
                className={
                  calender.mode === "AIR"
                    ? "props-text active-color"
                    : "props-text"
                }
                onClick={() => {
                  const obj = { ...calender };
                  if (obj.mode != "AIR") {
                    obj.mode = "AIR";
                    obj.type = "LSE";
                    setCalender(obj);
                    getIncotermAnalytics(obj);
                  }
                }}
              >
                {t("Air")}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  className="btn-filter"
                  onClick={() => {
                    if (
                      calender.startdate.length > 0 &&
                      calender.enddate.length > 0
                    ) {
                      searchData();
                    } else {
                      toast.error("Please enter start & end date!");
                    }
                  }}
                >
                  {t("Search")}
                </button>
              </div>
              <div>
                <button
                  className="btn-filter btn-reset"
                  onClick={() => {
                    const obj = {
                      mode: "SEA",
                      type: "FCL,LCL",
                      enddate: "",
                      startdate: "",
                    };
                    if (calender.mode != "SEA") {
                      getIncotermAnalytics(obj);
                    }
                    setCalender({
                      ...obj,
                    });
                  }}
                >
                  &gt; {t("Reset")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="skeleton-type mt-4">
            <Skeleton style={{ height: "40rem" }} />
          </div>
        ) : (
          <div className="container-fluid main-container-chart">
            <HorizontalBarChart datasets={datasets} />
          </div>
        )}
      </div>
    </>
  );
}

export default withTranslation()(Incoterm);
