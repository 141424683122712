import * as yup from "yup";

export const LoginWithPassword = {
  initials: {
    email: "",
    password: "",
    remember: false,
  },
  validation: yup.object().shape({
    email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email id"
      )
      .required("email is required"),
    // password: yup.string().required("password is required"),
  }),
};

export const ForgotWithEmail = {
  initials: {
    email: "",
  },
  validation: yup.object().shape({
    email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email id"
      )
      .required("email is required"),
  }),
};

export const UpdateWithPassword = {
  initials: {
    newPassword: "",
    confirmPassword: "",
  },
  validation: yup.object().shape({
    newPassword: yup
      .string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$/,
        "Min length 8, atleast 1 upper, 1 lower character, 1 digit and 1 special character"
      )
      .required("New password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "Passwords do not match")
      .required("Confirm password is required"),
  }),
};

export const TwoStepVerification = {
  initials: {
    otp: "",
  },
  validation: yup.object().shape({
    otp: yup.number().required("otp is required"),
  }),
};
