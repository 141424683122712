import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import instance from "../../api/interceptor";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

 function DeleteModal({t,
  setDeleteModal,
  deleteModal,
  onClickDelete,
}) {
  const handleclose = () =>
    setDeleteModal({
      ...deleteModal,
      show: false,
      data: {},
    });

  return (
    <div>
      <Modal
        open={deleteModal.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal-main"
      >
        <Box sx={style}>
          <div className="delete-model">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Delete Account")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            An email will be sent to the costumer service, please wait for the action of our analyst.
            </Typography>
            <div className="btn-delete-modal">
              <button className="btn btn-primary" onClick={() => handleclose()}>
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={() => onClickDelete()}
              >
                Delete
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default DeleteModal