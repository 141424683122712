import React, { useState } from "react";
import "../../process/process.css";
import BtnOutline from "../../../components/button/BtnOutline";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/interceptor";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Nodata from "../../../components/Nodata";
import { Skeleton } from "@mui/material";
import { Date } from "../../../utils/moment";
import { withTranslation } from "react-i18next";

const Documents = ({ t }) => {
  const location = useLocation();
  const [length, setLength] = useState(2);
  const [shipmentDocs, setShipmentDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  const clickShowmore = () => {
    if (length === 2) {
      setLength(shipmentDocs.length);
    } else {
      setLength(2);
    }
  };

  const getOrderDocs = () => {
    setLoading(true);
    const payload = {
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
      ShipmentID: "",
      DocType: "",
      DocumentName: "",
      DocumentDate: "",
      SplitNumber: location?.state?.item?.OrderSplitNumber ?? "",
      OrderNumber: location?.state?.item?.OrderNumber ?? "",
      BuyerCode: location?.state?.item?.BuyerCode ?? "",
      OrderID: location?.state?.item?.OrderID ?? "",
    };

    instance
      .post(API_PATH.GET_ORDER_DOCS, payload, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          setShipmentDocs(
            res._Data.sort((a, b) => {
              return new Date(b.SC_Date) - new Date(a.SC_Date);
            })
          );
        } else {
          setShipmentDocs([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setShipmentDocs([]);
        console.log("order docs api fail", err);
        if (!checkAbort) setLoading(false);
      });
  };

  useEffect(() => {
    getOrderDocs();
    return () => {
      if (!checkAbort) setLoading(false);

      return controller.abort();
    };
  }, [location?.state?.item?.OrderNumber]);

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={120}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      ) : (
        <div className="payment">
          <p className="color-grey2 payment-heading">{t("Documents")}</p>
          <div className="div-details-payments documents">
            {shipmentDocs.length ? (
              shipmentDocs.slice(0, length).map((item, index) => {
                return (
                  <div className="div-details-child" key={index}>
                    <p className="color-grey">{item.SC_DocType}</p>
                    <p className="color-blue2">
                      <PictureAsPdfOutlinedIcon /> {item.SC_FileName}
                    </p>
                    <p className="payment-data">
                      {t("Uploaded")} : {Date(item?.SC_Date ?? null)}
                    </p>
                  </div>
                );
              })
            ) : (
              <Nodata />
            )}
          </div>

          {shipmentDocs && shipmentDocs.length > 2 ? (
            <div className="d-flex justify-content-end buttonline">
              <BtnOutline
                onClick={() => clickShowmore()}
                title={length <= 2 ? t("ShowMore") : t("ShowLess")}
              ></BtnOutline>
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Documents);
