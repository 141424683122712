import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { routeConstant } from "../utils/routeConstant";
import { AuthContext } from "../../store/context/AuthProvider";

function PublicRoute(props) {
  const Auth = useContext(AuthContext);
  // const { isAuthenticated } = useSelector((state) => state.users);

  if (!Auth.userAuth) {
    return props.children;
  }
  // return props.children;
  return <Navigate to={routeConstant.DASHBOARD} replace={true} />;
}

export default PublicRoute;
