import React, { useEffect, useState } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import BtnOutline from "../../../components/button/BtnOutline";
import Btn from "../../../components/button/Btn";
import { withTranslation } from "react-i18next";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { Skeleton } from "@mui/material";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";

function Newpreset(props) {
  const [checkBox, setCheckBox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const { t } = props;
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setCheckBox(location.state?.data ?? []);
    }
  }, []);

  const onSaveColumn = () => {
    setCircularLoading(true);
    const payload = [...checkBox];

    instance
      .post(`/api/v1/${location?.state?.title}/SaveReportColumns`, payload)
      .then((res) => {
        if (res._IsSuccess) {
          toast.success(res._Message);
          navigate(-1);
        } else {
          toast.err(res._Message);
        }
        setCircularLoading(false);
      })
      .catch((err) => {
        setCircularLoading(false);
        console.log(err);
        toast.err("Something went wrong");
      });
  };

  const GetColumns = () => {
    setLoading(true);
    instance
      .get(API_PATH.GET_COLUMNS)
      .then((res) => {
        setLoading(false);
        if (res._Success) {
          console.log("api for get columns success");
          setCheckBox(res?._GenericValue ?? []);
        } else {
          setCheckBox([]);
          console.log("api for get columns error ");
        }
      })
      .catch((err) => {
        setLoading(false);
        setCheckBox([]);
        console.log("api for get columns error ", err);
      });
  };

  const onCheckBoxClick = (e, index) => {
    const data = [...checkBox];
    data[index].value = !data[index].value;
    setCheckBox([...data]);
  };

  const onSelectAll = (e) => {
    setSelectAll(!selectAll);
    const data = [...checkBox];
    console.log(data, e.target.checked);
    data.map((item, index) => {
      if (
        item.DisplayColumnName !== "ShipmentId" ||
        item.DisplayColumnName !== "TransactionNumber" ||
        item.DisplayColumnName !== "Containernum"
      ) {
        item.value = e.target.checked;
      }
    });
    setCheckBox([...data]);
  };

  return (
    <div>
      <div className="heading">
        <p className="head-trans">{t("ExportData")}</p>
      </div>
      {loading ? (
        <div className="skeleton-type">
          <Skeleton style={{ height: "30rem" }} />
        </div>
      ) : (
        <div className="newpreset">
          <p className="header_preset">{t(location.state?.header ?? "")}</p>
          {/* <div className="input-text mb-4">
            <input
              type="text"
              id="name"
              className="form-design"
              required=""
              placeholder="Name"
              autocomplete="off"
            />
          </div> */}
          {checkBox.length ? (
            <>
              <div className="container-fluid mb-3">
                <div className="row">
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectAll}
                        // id="flexCheckDefault"
                        onChange={onSelectAll}
                      />
                      <label
                        className="form-check-label header_preset"
                        htmlFor="flexCheckDefault"
                      >
                        {t("Select All")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {checkBox.map((item, index) => {
                    return (
                      <div
                        className="col-xl-2 col-lg-3 col-md-4 col-6"
                        key={index}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item.value}
                            // id="flexCheckDefault"
                            onChange={(e) => {
                              if (
                                item.DisplayColumnName !== "ShipmentId" &&
                                item.DisplayColumnName !==
                                  "TransactionNumber" &&
                                item.DisplayColumnName !== "Containernum"
                              ) {
                                onCheckBoxClick(e, index);
                              }
                            }}
                          />
                          <label
                            className="form-check-label header_preset"
                            htmlFor="flexCheckDefault"
                          >
                            {t(item?.DisplayColumnName ?? "N/A")}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <div>No data found!</div>
          )}
          <div className="d-flex align-items-center ">
            {circularLoading ? (
              <CircularProgress />
            ) : (
              <>
                <div className="me-3">
                  <BtnOutline
                    onClick={
                      () => onSaveColumn()
                      // navigate(
                      //   `${routeConstant.REPORT}/${routeConstant.DATAEXPORT}`
                      // )
                    }
                    title={t("Save")}
                  />
                </div>
                <div>
                  <Btn
                    title={`> ${t("Cancel")}`}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Newpreset);
