import { CircularProgress } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";
import BtnOutline from "../../components/button/BtnOutline";
import Nodata from "../../components/Nodata";
import { basename } from "../../Config";
import urlResolver from "../../utils/urlResolver";
import { Date } from "../../utils/moment";

const FinanceInvoice = () => {
  const {
    amount,
    outstandingTotalCount,
    outstandingDetails,
    isLoadmore,
    loadMore,
    t,
    setSidebar,
    setDocData,
    search,
  } = useOutletContext();
  const calculateAmmount = (ammount) => {
    const amt = Number(parseFloat(ammount).toFixed(2)).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });
    return amt;
  };
  console.log(outstandingDetails, "outstandingDetails11111");
  return (
    <>
      <div className="container-fluid">
        <div className="row flex-wrap payment-section">
          {amount.map((item, index) => {
            return (
              <div
                className="col-xs-12 col-sm-6 col-md-4 col-lg-4 group-payment-top"
                key={index}
              >
                <div>
                  <p className="due-color">{t("Due")}</p>
                  <p className="text-color1">{`${
                    item?.CompanyCurrencyCode ?? ""
                  }${" "}${calculateAmmount(item?.DueAmount) ?? 0}`}</p>
                </div>
                <div>
                  <p className="due-color">{t("Overdue")}</p>
                  <p className="text-color2">
                    {`${item?.CompanyCurrencyCode ?? ""}${" "}${
                      calculateAmmount(item?.OverDueAmount) ?? 0
                    }`}
                    <span>
                      <img src={urlResolver("icon/icon-alert.svg")} alt="" />
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {outstandingDetails.length ? (
        <div className="container-fluid">
          {outstandingDetails.map((item, index) => {
            return (
              <div className="row  payment-section" key={index}>
                <div className="col-12">
                  <div className="conatiner-fluid">
                    <div className="row flex-wrap justify-content-between">
                      <div className="col-1" style={{ minWidth: "12rem" }}>
                        <p className="payment-color">{t("PONumber")}</p>
                        <p
                          className="ellipsisTable"
                          title={item?.PONumber ?? "N/A"}
                        >
                          {item?.PONumber ?? "N/A"}
                        </p>
                      </div>
                      <div className="col-1" style={{ minWidth: "12rem" }}>
                        <p className="payment-color">{t("ShipmentNumber")}</p>
                        <p title={item?.ShipmentID ?? "N/A"}>
                          {item?.ShipmentID ?? "N/A"}
                        </p>
                      </div>
                      <div className="col-1" style={{ minWidth: "12rem" }}>
                        <p className="payment-color">
                          {t("House Bill of Lading")}
                        </p>
                        <p title={item?.HouseBillNumber ?? "N/A"}>
                          {item?.HouseBillNumber ?? "N/A"}
                        </p>
                      </div>
                      <div className="col-2" style={{ minWidth: "12rem" }}>
                        <p className="payment-color">Debtor Name</p>
                        <p
                          className="ellipsisTable"
                          title={`${item?.OrganisationName ?? ""}`}
                        >{`${item?.OrganisationName ?? ""}`}</p>
                      </div>
                      <div className="col-1" style={{ minWidth: "12rem" }}>
                        <p className="payment-color">CNPJ Number</p>
                        <p
                          className="ellipsisTable"
                          title={`${item?.CNPJNumber ?? ""}`}
                        >{`${item?.CNPJNumber ?? ""}`}</p>
                      </div>
                      <div className="col-1" style={{ minWidth: "12rem" }}>
                        <p className="payment-color">Invoice</p>
                        <p title={`${item?.TransactionNumber ?? ""}`}>{`${
                          item?.TransactionNumber ?? ""
                        }`}</p>
                      </div>
                      <div className="col-1" style={{ minWidth: "9rem" }}>
                        <p className="payment-color">{t("Amount")}</p>
                        <p
                          title={`${item?.OverseasCurrency ?? ""}${" "}${
                            item?.OverseasOutstandingAmount ?? 0
                          }`}
                        >
                          {`${item?.OverseasCurrency ?? ""}${" "}${
                            calculateAmmount(item?.OverseasOutstandingAmount) ??
                            0
                          }`}
                        </p>
                      </div>
                      <div className="col-1" style={{ minWidth: "12rem" }}>
                        <p className="payment-color">{t("FullyPaidDate")}</p>
                        <p title={`${Date(item?.FullyPaidDate) ?? ""}`}>
                          {`${Date(item?.FullyPaidDate) ?? ""}`}
                        </p>
                      </div>
                      <div className="col-1  document_button ">
                        <div>
                          <BtnOutline
                            onClick={() => {
                              setSidebar(true);
                              setDocData(item);
                            }}
                            title={t("Documents")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="show-more-finnance">
            {outstandingDetails.length < outstandingTotalCount ? (
              <div>
                <button
                  type="button"
                  className="btn-out-section"
                  onClick={loadMore}
                >
                  {isLoadmore ? (
                    <CircularProgress
                      size="0.7rem"
                      style={{ color: "var(--color-blue)" }}
                    />
                  ) : (
                    t("ShowMore")
                  )}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <Nodata />
      )}
    </>
  );
};

export default FinanceInvoice;
