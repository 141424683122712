import { ACTION_TYPE } from "../constants/actionType";
const initialState = {
  loading: false,
  isLoadMore: false,
  shipment: [],
  list: [],
  error: false,
  totalCount: 0,
};
export const shipmentReducer = (state = initialState, action, error) => {
  console.log(state, "state is 2222", action?.payload?._TotalItem);
  switch (action.type) {
    case ACTION_TYPE.SHIPMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        shipment: [],
        totalCount: action?.payload?._TotalItem ?? 0,
      };
    case ACTION_TYPE.LOADING_MORE_SHIPMENT:
      return {
        ...state,
        isLoadMore: true,
        shipment: [...state.shipment],
        totalCount: action?.payload?._TotalItem ?? 0,
      };
    case ACTION_TYPE.SHIPMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoadMore: false,
        shipment: [...state.shipment, ...action.payload._Data],
        list: [...state.list, ...action.payload._Data],
        totalCount: action?.payload?._TotalItem ?? 0,
      };
    case ACTION_TYPE.SHIPMENT_LIST_SUCCESS_PAGE:
      return {
        ...state,
        loading: false,
        isLoadMore: false,
        shipment: [...state.shipment, ...action.payload._Data],
        totalCount: action?.payload?._TotalItem ?? 0,
      };
    case ACTION_TYPE.SHIPMENT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        isLoadMore: false,
        error: true,
        shipment: [...state.shipment],
        totalCount: action?.payload?._TotalItem ?? 0,
      };
    default:
      return {
        ...state,
      };
  }
};
