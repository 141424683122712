export const ACTION_TYPE = {
  // Login
  LOGIN_REQUEST: "login-request",
  LOGIN_SUCCESS: "login-success",
  LOGIN_ERROR: "login-error",
  ALREADY_LOGIN: "already-login",

  // Logout
  LOGOUT_REQUEST: "logout-request",
  LOGOUT_SUCCESS: "logout-success",
  LOGOUT_FAIL: "logout-fail",

  INITIALIZE_APP: "init-app",

  //Permission
  PERMISSION_DATA_SUCCESS: "permissions-data-success",
  PERMISSION_DATA_REQUEST: "permissions-data-request",
  PERMISSION_DATA_FAIL: "permissions-data-fail",

  // Team
  TEAM_DATA_REQUEST: "team-data-request",
  TEAM_DATA_SUCCESS: "team-data-success",
  TEAM_DATA_SUCCESS_PAGE: "team-data-success-page",
  TEAM_DATA_FAIL: "team-data-fail",
  LOADING_MORE: "loading-more",

  // Shipment List
  SHIPMENT_LIST_REQUEST: "shipment-list-request",
  SHIPMENT_LIST_SUCCESS: "shipment-list-success",
  SHIPMENT_LIST_SUCCESS_PAGE: "shipment-list-success-page",
  SHIPMENT_LIST_FAIL: "shipment-list-fail",
  LOADING_MORE_SHIPMENT: "loading-more",

  // Order List
  ORDER_LIST_REQUEST: "order-list-request",
  ORDER_LIST_SUCCESS: "order-list-success",
  ORDER_LIST_FAIL: "order-list-fail",
};
