import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import BtnOutline from "../../../components/button/BtnOutline";
import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { Textarea } from "@mui/joy";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function DocModal({
  LoadingForDisapprove,
  DocApproval,
  moalopen,
  setMoalopen,
  currentDoc,
  title,
}) {
  const [textValue, setTextValue] = useState("");

  return (
    <div>
      <Modal
        open={moalopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3> {title}</h3>

          <Textarea
            minRows={4}
            className="mb-3"
            id="standard-basic"
            label="Standard"
            placeholder="type message here...."
            variant="standard"
            style={{ border: "1px solid grey" }}
            fullWidth
            value={textValue}
            onChange={(e) => {
              setTextValue(e.target.value);
            }}
          />
          <div className="w-100 d-flex justify-content-between align-items-center">
            <BtnOutline title={"Cancel"} onClick={() => setMoalopen(false)} />
            <BtnOutline
              title={
                LoadingForDisapprove ? (
                  <CircularProgress
                    size="0.7rem"
                    style={{ color: "var(--color-blue1)" }}
                  />
                ) : (
                  "Submit"
                )
              }
              onClick={() => {
                if (textValue.length === 0) {
                  toast.error("Please provide reason");
                } else {
                  DocApproval(currentDoc, textValue);
                }
              }}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
