import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n/config";
import "./fonts/FSSienaWeb-Bold.woff";
import "./fonts/FSSienaWeb-Bold.woff2";
import "./fonts/FSSienaWeb-Light.woff";
import "./fonts/FSSienaWeb-Light.woff2";
import "./fonts/FSSienaWeb-Medium.woff";
import "./fonts/FSSienaWeb-Medium.woff2";
import "./fonts/FSSienaWeb-Regular.woff";
import "./fonts/FSSienaWeb-Regular.woff2";
import "./fonts/FSSienaWeb-SemiBold.woff";
import "./fonts/FSSienaWeb-SemiBold.woff2";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
