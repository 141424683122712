import React, { useState, useContext, useEffect } from "react";
import "./login.css";
import { routeConstant } from "../../routing/utils/routeConstant";
import { useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../../components/layout/Auth/AuthLayout";
import { useDispatch } from "react-redux";
import { User } from "../../store/redux/actions/UserAction";
import { CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import { AuthContext } from "../../store/context/AuthProvider";
import { Formik } from "formik";
import { LoginWithPassword } from "../../helpers/validator/LoginWithPassword";
import axios from "axios";
import {
  LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_USER_KEY,
  baseUrl,
} from "../../Config";
import { API_PATH } from "../../constant/apiPath";
import AlreadyDialoge from "./AlreadyDialoge";
import { jwtDecode } from "jwt-decode";
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const Auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [nextDisable, setNextDisable] = useState(false);
  const [userAlreadyLoggedIn, setUserAlreadyLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    console.log("testState", localStorage.getItem("Status"));
    const statusdata = localStorage.getItem("Status");
    if (statusdata) {
      window.location.reload();
    }
    localStorage.clear();
  }, []);

  const onClickLogin = (values, actions) => {
    setLoading(true);
    if (!isPasswordSet) {
      axios
        .get(`${baseUrl}${API_PATH.CHECK_PASSWORD}${values.email}`)
        .then((res) => {
          if (res.data.Success) {
            // setIsPasswordSet(true);
            if (
              res.data.Message === "Password exists" &&
              res.data.Is_EmailExists
            ) {
              setIsPasswordSet(true);
            } else {
              // setNextDisable(true);
              // const timer = setTimeout(() => {
              //   setNextDisable(false);
              // }, 6000);
              // timer();
              toast.success(res?.data?.Message ?? "");
            }
          } else {
            toast.error(res?.data?.Message ?? "");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? "Something went wrong!");
          console.log("check password error", err);

          setLoading(false);
        });
    } else {
      setCredentials({
        email: values.email,
        password: values.password,
      });
      dispatch(User.login(values.email, values.password))
        .then((res) => {
          if (res.Success) {
            const decoded = jwtDecode(res?.Data?.AccessToken);
            let obj = {
              Role: "",
              Name: "",
              Email: "",
            };

            if (Object.keys(decoded).length) {
              Object.keys(decoded).map((item) => {
                if (item.includes("role")) {
                  obj.Role = decoded[item];
                } else if (item.includes("name")) {
                  obj.Name = decoded[item];
                } else if (item.includes("emailaddress")) {
                  obj.Email = decoded[item];
                }
              });
            }
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res.Data));
            localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(obj));
            Auth.login(res?.data?.Data ?? {});
            navigate(routeConstant.APP_INIT);
          } else if (res.Message === "User Already Login From Another Screen") {
            setUserAlreadyLoggedIn(true);
          } else {
            toast.error(res?.Message ?? "");
          }
          // setLoading(false);
          // if (res.Success) {
          //   const token = res.Data.AccessToken;
          //   const header = {
          //     Authorization: `Bearer ${token}`,
          //   };
          //   axios
          //     .post(
          //       `${baseUrl}${API_PATH.SEND_TOKEN}${values.email}`,
          //       {},
          //       {
          //         headers: header,
          //       }
          //     )
          //     .then((res) => {
          //       if (res.data._IsSuccess) {
          //         setLoading(false);
          //         console.log("Send token success");
          //         toast.success(res?.data?._Message ?? "");
          //         navigate(routeConstant.TWOSTEPVERIFICATIO, {
          //           state: {
          //             token: token,
          //             email: values.email,
          //           },
          //         });
          //       } else {
          //         setLoading(false);
          //         console.log("send token fail");
          //         toast.error(res?.data?.Message ?? "");
          //       }
          //     })
          //     .catch((err) => {
          //       setLoading(false);
          //       console.log("send token error", err);
          //       toast.error(
          //         err?.response?.data?.Message ?? "Something went wrong!"
          //       );
          //     });
          // } else {
          //   setLoading(false);
          //   toast.error(res?.Message ?? "");
          // }
        })
        .catch((err) => {
          setLoading(false);
          console.log("login dispatch error ", err);
          toast.error(err?.response?.data?.Message ?? "Something went wrong!");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  console.log("userAlreadyLoggedIn", userAlreadyLoggedIn);
  return (
    <AuthLayout>
      <div className="container-form">
        <div>
          <h1 className="title">AS Tracking</h1>
        </div>
        <Formik
          initialValues={LoginWithPassword.initials}
          validationSchema={LoginWithPassword.validation}
          onSubmit={(values, actions) => {
            onClickLogin(values, actions);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className="input-defalut-login" id="username">
                <TextField
                  label="Username"
                  value={props.values.email}
                  variant="standard"
                  disabled={isPasswordSet}
                  onChange={(event) => {
                    props.setFieldValue("email", event.target.value);
                  }}
                />
                {props.errors.email && (
                  <span className="span-error">{props.errors.email}</span>
                )}
              </div>
              {!isPasswordSet && (
                <button
                  type="submit"
                  className="btn-login"
                  disabled={nextDisable}
                >
                  {!loading ? (
                    "Next"
                  ) : (
                    <CircularProgress
                      size="1.5rem"
                      style={{ color: "var(--color-white)" }}
                    />
                  )}
                </button>
              )}
              <></>
              {isPasswordSet && (
                <>
                  {" "}
                  <div className="input-defalut-login" id="password">
                    <TextField
                      label="Password"
                      type="password"
                      value={props.values.password}
                      variant="standard"
                      onChange={(event) => {
                        props.setFieldValue("password", event.target.value);
                      }}
                      autoFocus={isPasswordSet}
                    />
                    {props.errors.password && (
                      <span className="span-error">
                        {props.errors.password}
                      </span>
                    )}
                  </div>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="scales"
                      name="remember"
                      checked={props.values.remember}
                      onChange={() => {
                        props.setFieldValue("remember", !props.values.remember);
                      }}
                    />
                    <label htmlFor="remember">Remember</label>
                  </div>
                  <div className="submit">
                    <button
                      type="submit"
                      disabled={loading  || !props.values.password}
                      className="btn-login"
                    >
                      {!loading ? (
                        "Login"
                      ) : (
                        <CircularProgress
                          size="1.5rem"
                          style={{ color: "var(--color-white)" }}
                        />
                      )}
                    </button>
                    <a
                      className="pointer"
                      onClick={() => navigate(routeConstant.FORGETPASSWORD)}
                    >
                      &gt; Forgot password?
                    </a>
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      </div>
      {userAlreadyLoggedIn && (
        <AlreadyDialoge
          open={userAlreadyLoggedIn}
          setOpen={setUserAlreadyLoggedIn}
          credentials={credentials}
        />
      )}
    </AuthLayout>
  );
}

export default Login;
