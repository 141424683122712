import React from "react";
import "./styles.css";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, Skeleton } from "@mui/material";
import BtnOutline from "../../../components/button/BtnOutline";
function Air({
  thresoldSettings,
  loading,
  onChangeThresold,
  onThresoldSave,
  updating,
}) {
  return (
    <>
      {loading ? (
        <div className="skeleton-type">
          <Skeleton style={{ height: "20rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <div className="air-deadline ">
          <div className="form-deadline">
            {thresoldSettings.map((item, index) => {
              if (item.TransportMode === "AIR") {
                return (
                  <div className="mb-3" key={item.ChartName}>
                    <label htmlFor="cgrdy">
                      <span>{item.ChartName}</span>
                    </label>
                    <div className="input-air d-flex">
                      <span>{item.SettingValues}</span>
                      <div className="button-add-sub d-flex">
                        <div
                          className="thresold-add-btn px-2"
                          onClick={() => onChangeThresold(item, index, "ADD")}
                        >
                          <AddIcon />
                        </div>
                        <div
                          className="thresold-add-btn px-2"
                          onClick={() => onChangeThresold(item, index, "SUB")}
                        >
                          <RemoveIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {/* <div className="button_save"> */}
            <BtnOutline
              title={
                updating ? (
                  <CircularProgress
                    size="0.7rem"
                    style={{ color: "var(--color-blue)" }}
                  />
                ) : (
                  "Save Change"
                )
              }
              onClick={() => {
                onThresoldSave(thresoldSettings);
              }}
            />
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Air;
