import React from "react";
import "./auth.css";
import { basename } from "../../../Config";
import urlResolver from "../../../utils/urlResolver";
const AuthLayout = (props) => {
  return (
    <section className="section-login">
      <div className="login-main">
        <div className="container-logo">
          <img
            className="img-fluid"
            src={urlResolver("icon/logo-asia-tracking.svg")}
            alt=""
            width="260"
            height="62"
          />
        </div>
        {props.children}
      </div>
    </section>
  );
};

export default AuthLayout;
