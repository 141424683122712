import { Skeleton } from "@mui/material";
import React from "react";

const SkeletonDemurrageTable = (props) => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
      <Skeleton
        variant="rectangular"
        height={60}
        style={{ width: "100%", marginTop: "1rem" }}
      />
    </>
  );
};

export default SkeletonDemurrageTable;
