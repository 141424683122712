import React, { useEffect, useState } from "react";
import "./styles.css";
import HorizontalBarChart from "./chart/HorizontalBarChart";
import { basename } from "../../../Config";
import { withTranslation } from "react-i18next";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { Skeleton } from "@mui/material";
import { toast } from "react-hot-toast";
import urlResolver from "../../../utils/urlResolver";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
function General(props) {
  const [calender, setCalender] = useState({
    startdate: "",
    enddate: "",
    type: "SEA",
  });
  const [loading, setLoading] = useState(false);
  const [datasets, setDatasets] = useState({
    maxValue: 0,
    data: [],
  });

  const { t } = props;

  useEffect(() => {
    getgeneralstatistics(calender);
  }, []);
  const searchData = () => {
    getgeneralstatistics(calender);
  };
  const getgeneralstatistics = (calender) => {
    setLoading(true);
    const body = {
      TransportMode: calender?.type ?? "",
      CNJPNumber: "",
      OriginPortName: "",
      DestinationPortName: "",
      StartDate: calender?.startdate ?? "",
      EndDate: calender?.enddate ?? "",
      ShipmentType: "",
      IsFilterApplied: true,
      ShipmentMode: "",
    };

    instance
      .post(API_PATH.GET_GENERAL_STATISTICS, body)
      .then((res) => {
        setLoading(false);
        if (res._Success) {
          getstatisticsdata(res);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("get general statistics api error", err);
      });
  };

  const getstatisticsdata = (data) => {
    let maxValue = 0;
    let arr = [];
    if (data._GenericValue.length) {
      data._GenericValue.map((item) => {
        if (maxValue < item.TotalCOunt) {
          maxValue = item.TotalCOunt;
        }
        if (item.CarrierName !== null && item.CarrierCode !== "") {
          const temp = {
            lable: item.CarrierName,
            value: item.TotalCOunt,
          };
          arr.push(temp);
        }
      });
    }
    setDatasets({ ...datasets, maxValue: maxValue, data: [...arr] });
  };

  const dayjs = require("dayjs");
  const language = t("ConditionName");

  return (
    <div>
      <div>
        <p className="head-trans">{t("GeneralStatistics")}</p>
      </div>
      <div className="Transport-section d-flex  flex-column flex-md-row">
        <div className="d-flex  calender-section flex-column flex-md-row">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="date-picker"
              value={calender.startdate}
              onChange={(newValue) => {
                const obj = { ...calender };
                obj.startdate = dayjs(newValue).format("YYYY-MM-DD");
                setCalender(obj);
              }}
              format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="date-picker"
              value={calender.enddate}
              onChange={(newValue) => {
                const obj = { ...calender };
                obj.enddate = dayjs(newValue).format("YYYY-MM-DD");
                setCalender(obj);
              }}
              format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
            />
          </LocalizationProvider>

          {/* <input
            className="input-date calender"
            type="date"
            max="9999-12-31"
            value={calender.startdate}
            onChange={(event) => {
              const obj = { ...calender };
              obj.startdate = event.target.value;
              setCalender(obj);
            }}
          />

          <input
            className="input-date calender"
            type="date"
            max="9999-12-31"
            value={calender.enddate}
            onChange={(event) => {
              const obj = { ...calender };
              obj.enddate = event.target.value;
              setCalender(obj);
            }}
          /> */}
        </div>
        <div className=" transport-mid d-flex  flex-md-grow-0 flex-grow-1 ">
          <div className="d-flex justify-content-between align-items-center calender-section  ">
            <p className="props-type ">{t("Type")}</p>

            <p
              className={
                calender.type === "SEA"
                  ? "props-text active-color"
                  : "props-text"
              }
              onClick={() => {
                const obj = { ...calender };
                obj.type = "SEA";
                getgeneralstatistics(obj);
                setCalender(obj);
              }}
            >
              {t("Sea")}
            </p>

            <p
              className={
                calender.type === "AIR"
                  ? "props-text active-color"
                  : "props-text"
              }
              onClick={() => {
                const obj = { ...calender };
                obj.type = "AIR";
                getgeneralstatistics(obj);
                setCalender(obj);
              }}
            >
              {t("Air")}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                className="btn-filter"
                onClick={() => {
                  if (
                    calender.startdate.length > 0 &&
                    calender.enddate.length > 0
                  ) {
                    searchData(calender);
                  } else {
                    toast.error("Please enter start & end date!");
                  }
                }}
              >
                {t("Search")}
              </button>
            </div>
            <div>
              <button
                className="btn-filter btn-reset"
                onClick={() => {
                  setCalender({
                    type: "",
                    enddate: "",
                    startdate: "",
                  });
                }}
              >
                &gt; {t("Reset")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="head-general">
        <div>
          <p>{t("Carrier")}</p>
        </div>
        <img
          className="general-bottom"
          src={urlResolver("icon/line-center.svg")}
        />
      </div>

      {loading ? (
        <div className="skeleton-type">
          <Skeleton style={{ height: "50rem" }} />
        </div>
      ) : (
        <div className="container-fluid main-container-chart">
          <HorizontalBarChart datasets={datasets} />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(General);
