import React, { useState } from "react";
import { Skeleton } from "@mui/material";
import "../../process/process.css";
import { basename } from "../../../Config";
import { useLocation } from "react-router-dom";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { useEffect } from "react";
import { Date } from "../../../utils/moment";
import { withTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import urlResolver from "../../../utils/urlResolver";

const ShipmentDetailsCard = ({ detailsdata, loading, t }) => {
  const location = useLocation();
  const [isCopied, setIsCopied] = useState(false);
  // const [detailsdata, setDetailsdata] = useState({});
  // const [loading, setLoading] = useState(false);

  // const getOrderdetails = () => {
  //   setLoading(true);
  //   const payload = {
  //     OrderId: "",
  //     SplitNumber: 0,
  //     OrderNumber: location?.state?.item?.OrderNumber ?? "",
  //     BuyerCode: location?.state?.item?.BuyerCode,
  //   };

  //   instance
  //     .post(API_PATH.GET_ORDER_DETAILS, payload)
  //     .then((res) => {
  //       if (res._IsSuccess) {
  //         console.log("get order details success");
  //         setDetailsdata(res._Data);
  //       } else {
  //         setDetailsdata({});
  //         console.log("get order details fail" + res._IsSuccess);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setDetailsdata({});
  //       console.log("get order details error", err);
  //       setLoading(false);
  //     });
  // };
  // useEffect(() => {
  //   getOrderdetails();
  // }, [location?.state?.item?.OrderNumber]);

  return (
    <>
      {loading ? (
        <>
          <Skeleton
            variant="rectangular"
            height={120}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height={450}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
        </>
      ) : (
        <>
          <div className="mid-container-process d-flex">
            <div className="process-page-image d-flex">
              {location?.state?.item?.TransportMode === "SEA" ? (
                <img src={urlResolver("icon/SEABACKGROUNDBLUE.svg")} />
              ) : location?.state?.item?.TransportMode === "AIR" ? (
                <img src={urlResolver("icon/AIRBACKGROUNDBLUE.svg")} />
              ) : location?.state?.item?.TransportMode === "ROA" ? (
                <img src={urlResolver("icon/ROADBACKGROUNDBLUE.svg")} />
              ) : null}
              <div>
                <h3 className="test-head">{t("Order")}</h3>
                <p className="test-para">
                  {detailsdata?.OrderNumber ?? t("N/A")}
                </p>
              </div>
            </div>
            <div className="test-section ">
              <div>
                <h4 className="test-section-head">{t("ETD")}</h4>
                <p className=" test-section-para">
                  {Date(detailsdata?.ETD ?? null)}
                </p>
              </div>
              <div>
                <h4 className="test-section-head">{t("ETA")}</h4>
                <p className=" test-section-para">
                  {Date(detailsdata?.ETA ?? null)}
                </p>
              </div>
            </div>
          </div>
          <div className="last-container">
            <div className="last-container-first-child">
              <div className="last-container-child">
                <p className="last-container-paraone2">{t("Supplier")}</p>
                <p
                  className="color-blue2 last-container-childmid"
                  title={detailsdata?.SupplierName ?? t("N/A")}
                >
                  {detailsdata?.SupplierName ?? t("N/A")}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="last-container-paraone2">{t("Buyer")}</p>
                  <p className="last-container-childmid">
                    {detailsdata?.BuyerName ?? t("N/A")}
                    {detailsdata.BuyerName ? (
                      <>
                        <span
                          onClick={() => {
                            navigator.clipboard.writeText(
                              detailsdata?.BuyerName ?? t("N/A")
                            );
                            setIsCopied(!isCopied);
                            // setTimeout(() => {
                            //   setIsCopied(false);
                            // }, 3000);
                          }}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        >
                          {isCopied ? (
                            <ContentCopyTwoToneIcon />
                          ) : (
                            <ContentCopyIcon />
                          )}
                        </span>
                        {/* <img
                        
                        src=
                        alt={t("N/A")}
                        title={t("Click to copy")}
                        
                      /> */}
                      </>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
            <div className="div-details-main">
              <div className="div-details-child">
                <p
                  className="color-grey2"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t("Pickupfrom")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.OriginPortName ?? t("N/A")},{" "}
                  {detailsdata?.OriginCountryName ?? t("N/A")}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey2"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t("Deliverto")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.DestinationPortName ?? t("N/A")},{" "}
                  {detailsdata?.DestinationCountryName ?? t("N/A")}.
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey2"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t("ConfirmationNo")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.ConfirmationNumber ?? t("N/A")}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t("INCOTerms")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.INCOTerm ?? t("N/A")}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {" "}
                  {t("AdditionalTerms")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.AdditionalTerm ?? t("N/A")}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t("TransportMode")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.TransportMode ?? t("N/A")}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t("Controllingcustomer")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.ControllingCustomerName ?? t("N/A")}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t("Orderdate")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {Date(detailsdata?.OrderDate ?? null)}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {t(" Req.ExWorks")}
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.ReqExWorks ?? t("N/A")}
                </p>
              </div>
              <div className="div-details-child">
                <p
                  className="color-grey"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  Req. in store
                </p>
                <p
                  className="color-blue"
                  style={{ fontSize: "var(--fs-label)" }}
                >
                  {detailsdata?.RequiredInStoreDate
                    ? Date(detailsdata?.RequiredInStoreDate ?? null)
                    : t("N/A")}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withTranslation()(ShipmentDetailsCard);
