import React from "react";
import { withTranslation } from "react-i18next";
import Nodata from "../../../components/Nodata";
import { Date } from "../../../utils/moment";
import "./styles.css";

const DemurageTable = (props) => {
  const { t } = props;
  return (
    <>
      {props.data.length > 0 ? (
        <table className="demurrage table">
          <thead>
            <tr className="theadingbelow">
              <th className="theading">Shipment ID</th>
              <th>House Bill of Lading</th>
              <th>Master Bill of Lading </th>
              <th>Container</th>
              <th>Type</th>
              <th>Vessel Arrival</th>
              <th>Free time</th>
              <th>Empty required</th>
              <th>Empty return</th>
              <th>Demurrage</th>
              <th>Client Rate</th>
              <th className="theadingbelow">Total</th>
            </tr>
          </thead>
          <>
            {props.data.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr className="theadingbelow">
                    <td className="theading">{item?.Jobnum ?? t("N/A")}</td>
                    <td> {item?.HouseBillNumber ?? t("N/A")} </td>
                    <td> {item?.MasterBillNumber ?? t("N/A")}</td>
                    <td>{item?.Containernum ?? t("N/A")} </td>
                    <td>{item?.ContainerType ?? t("N/A")}</td>
                    <td>{Date(item?.VesselArrival ?? null)}</td>
                    <td>{item?.DemurrageFreeTime ?? t("N/A")}</td>
                    <td>{Date(item?.EmptyRequiredBy ?? null)}</td>
                    <td>{Date(item?.EmptyReturnedOn ?? null)}</td>
                    <td>{item?.DaysPastFreeTime ?? t("N/A")}</td>
                    <td>{item?.ClientRate ?? t("N/A")}</td>
                    <td className="theadingbelow">{item?.Total ?? t("N/A")}</td>
                  </tr>
                </tbody>
              );
            })}
          </>
        </table>
      ) : (
        <Nodata text="No Demurrage data found!" />
      )}
    </>
  );
};

export default withTranslation()(DemurageTable);
