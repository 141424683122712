import React, { useEffect, useState } from "react";
import HorizontalBarChart from "./chart/HorizontalBarChart";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./styles.css";
import { basename } from "../../../Config";
import { withTranslation } from "react-i18next";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { CalendarMonth } from "@mui/icons-material";
import { max } from "moment";
import { CircularProgress, Skeleton } from "@mui/material";
import BtnOutline from "../../../components/button/BtnOutline";
import { toast } from "react-hot-toast";
import urlResolver from "../../../utils/urlResolver";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
function Transport(props) {
  const [nav, setNav] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [calender, setCalender] = useState({
    startdate: "",
    enddate: "",
    TransportMode: "SEA",
    ShipmentType: "LCL",
  });
  const [showMoreButton, setShowMoreButton] = useState(true);
  const [dataLcl, setDataLcl] = useState({
    pageCount: 1,
    maxValue: 0,
    data: [],
  });
  const [dataFcl, setDataFcl] = useState({
    pageCount: 1,
    maxValue: 0,
    data: [],
  });
  const [dataLse, setDataLse] = useState({
    pageCount: 1,
    maxValue: 0,
    data: [],
  });

  const [search, setSearch] = useState({
    type: "",
    value: "",
  });

  const closeNav = () => {
    if (nav) {
      setNav(false);
    } else {
      setNav(true);
    }
  };

  const { t } = props;

  useEffect(() => {
    getVolumeData(calender, dataLcl.pageCount);
  }, []);
  const searchData = () => {
    getVolumeData(calender, dataLcl.pageCount);
  };
  const getVolumeData = (calender, count) => {
    if (count > 1) {
      setLoadMore(true);
    } else {
      setLoading(true);
    }
    const body = {
      TransportMode: calender?.TransportMode ?? "",
      CNJPNumber: "",
      OriginPortName: "",
      DestinationPortName: "",
      StartDate: calender.startdate ?? "",
      EndDate: calender.enddate ?? "",
      OrgAddressTypeCode: "CNR",
      ShipmentType: calender?.ShipmentType ?? "",
      VolumeTab:
        calender?.TransportMode === "SEA" && calender?.ShipmentType === "LCL"
          ? "M3"
          : calender?.TransportMode === "SEA" &&
            calender?.ShipmentType === "FCL"
          ? "CNT"
          : calender?.TransportMode === "AIR" &&
            calender?.ShipmentType === "LSE"
          ? "KG"
          : "",
      IsFilterApplied: calender.startdate ? true : false,
      PageSize: 10,
      pageNo: count,
      ShipmentMode: "",
    };
    instance
      .post(API_PATH.GET_VOLUME_STATISTICS, body)
      .then((res) => {
        if (res._Success) {
          console.log(
            "response of volume",
            res._GenericValue.VolumeStatistics.length
          );
          if (!res?._GenericValue?.VolumeStatistics?.length) {
            setShowMoreButton(false);
          }
          if (
            calender.TransportMode === "SEA" &&
            calender.ShipmentType === "LCL"
          ) {
            getLclData(res, count);
          } else if (
            calender.TransportMode === "SEA" &&
            calender.ShipmentType === "FCL"
          ) {
            getFclData(res, count);
          } else if (
            calender.TransportMode === "AIR" &&
            calender.ShipmentType === "LSE"
          ) {
            getLseData(res, count);
          }
        } else {
        }
        setLoading(false);
        setLoadMore(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoadMore(false);
        console.log("get volume statistics api fail", err);
      });
  };

  const getLseData = (data, count) => {
    let maxValue = dataLse.maxValue;
    let arr = [];
    if (data._GenericValue.VolumeStatistics.length) {
      data._GenericValue.VolumeStatistics.map((item) => {
        if (maxValue < item.Total) {
          maxValue = item.Total;
        }
        const temp = {
          lable: item.Name,
          value: item.Total,
        };
        arr.push(temp);
      });
    }
    if (count != 1) {
      setDataLse({
        ...dataLse,
        maxValue: maxValue,
        data: [...dataLse.data, ...arr],
        pageCount: count,
      });
    } else {
      setDataLse({
        ...dataLse,
        maxValue: maxValue,
        data: [...arr],
        pageCount: 1,
      });
    }
  };

  const getFclData = (data, count) => {
    let maxValue = dataFcl.maxValue;
    let arr = [];
    if (data._GenericValue.VolumeStatistics.length) {
      data._GenericValue.VolumeStatistics.map((item) => {
        if (maxValue < item.Total) {
          maxValue = item.Total;
        }
        const temp = {
          lable: item.Name,
          value: item.Total,
        };
        arr.push(temp);
      });
    }
    if (count != 1) {
      setDataFcl({
        ...dataFcl,
        maxValue: maxValue,
        data: [...dataFcl.data, ...arr],
        pageCount: count,
      });
    } else {
      setDataFcl({
        ...dataFcl,
        maxValue: maxValue,
        data: [...arr],
        pageCount: 1,
      });
    }
  };

  const getLclData = (data, count) => {
    let maxValue = dataLcl.maxValue;
    let arr = [];
    if (data._GenericValue.VolumeStatistics.length) {
      data._GenericValue.VolumeStatistics.map((item) => {
        if (maxValue < item.Total) {
          maxValue = item.Total;
        }
        const temp = {
          lable: item.Name,
          value: item.Total,
        };
        arr.push(temp);
      });
    }

    if (count != 1) {
      setDataLcl({
        ...dataLcl,
        maxValue: maxValue,
        data: [...dataLcl.data, ...arr],
        pageCount: count,
      });
    } else {
      setDataLcl({
        ...dataLcl,
        maxValue: maxValue,
        data: [...arr],
        pageCount: 1,
      });
    }
  };
  const dayjs = require("dayjs");
  const language = t("ConditionName");
  return (
    <div>
      <div>
        <p className="head-trans">{t("Volumetransported")}</p>
      </div>
      <div className="Transport-section d-flex  flex-column flex-md-row  ">
        <div className="d-flex  calender-section  flex-column flex-md-row justify-content-between justify-content-md-none">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="date-picker"
              value={calender.startdate}
              onChange={(newValue) => {
                const obj = { ...calender };
                obj.startdate = dayjs(newValue).format("YYYY-MM-DD");
                setCalender(obj);
              }}
              format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="date-picker"
              value={calender.enddate}
              onChange={(newValue) => {
                const obj = { ...calender };
                obj.enddate = dayjs(newValue).format("YYYY-MM-DD");
                setCalender(obj);
              }}
              format={language == "en" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
            />
          </LocalizationProvider>
          {/* <input
            className="input-date calender"
            type="date"
            required
            max="9999-12-31"
            value={calender.startdate}
            onChange={(event) => {
              const obj = { ...calender };
              obj.startdate = event.target.value;
              setCalender(obj);
            }}
          />
          <input
            className="input-date calender"
            type="date"
            max="9999-12-31"
            value={calender.enddate}
            onChange={(event) => {
              const obj = { ...calender };
              obj.enddate = event.target.value;
              setCalender(obj);
            }}
          /> */}
        </div>
        <div className=" transport-mid d-flex  flex-md-grow-0 flex-grow-1  ">
          <div className="d-flex justify-content-between align-items-center calender-section">
            <p className="props-type ">{t("Type")}</p>
            <p
              className={
                calender.TransportMode === "SEA"
                  ? "props-text active-color"
                  : "props-text"
              }
              onClick={() => {
                const obj = { ...calender };
                obj.TransportMode = "SEA";
                obj.ShipmentType = "LCL";
                if (!dataLcl.data.length) {
                  getVolumeData(obj, dataLcl.pageCount);
                }
                setCalender(obj);
              }}
            >
              {t("Sea")}
            </p>

            <p
              className={
                calender.TransportMode === "AIR"
                  ? "props-text active-color"
                  : "props-text"
              }
              onClick={() => {
                const obj = { ...calender };
                obj.TransportMode = "AIR";
                obj.ShipmentType = "LSE";
                if (!dataLse.data.length) {
                  getVolumeData(obj, dataLse.pageCount);
                }
                setCalender(obj);
              }}
            >
              {t("Air")}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                className="btn-filter"
                onClick={() => {
                  if (
                    calender.startdate.length > 0 &&
                    calender.enddate.length > 0
                  ) {
                    searchData();
                  } else {
                    toast.error("Please enter start & end date!");
                  }
                }}
              >
                {t("Search")}
              </button>
            </div>
            <div>
              <button
                className="btn-filter btn-reset"
                onClick={() => {
                  setCalender({
                    TransportMode: "SEA",
                    ShipmentType: "LCL",
                    enddate: "",
                    startdate: "",
                  });
                }}
              >
                &gt; {t("Reset")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between transport-mid-sec flex-column flex-md-row ">
        {calender.TransportMode === "AIR" ? (
          <div className="head-transport ">
            <div className="d-flex ">
              <p
                className={
                  calender.ShipmentType === "LSE"
                    ? "props-text active-color"
                    : "props-text"
                }
                // onClick={() => {
                //   const obj = { ...calender };
                //   obj.ShipmentType = "LSE";
                //   if (!dataLse.data.length) {
                //     getVolumeData(obj, dataLse.pageCount);
                //   }
                //   setCalender(obj);
                // }}
              >
                LSE
              </p>
            </div>
            <img
              className="transport-bottom"
              src={urlResolver("icon/line-center.svg")}
            />
          </div>
        ) : (
          <div className="head-transport order-md-1 order-2 ">
            <div className="d-flex justify-content-around">
              <div>
                <div>
                  <p
                    className={
                      calender.ShipmentType === "LCL"
                        ? "props-text active-color"
                        : "props-text"
                    }
                    onClick={() => {
                      const obj = { ...calender };
                      obj.ShipmentType = "LCL";
                      if (!dataLcl.data.length) {
                        getVolumeData(obj, dataLcl.pageCount);
                      }
                      setCalender(obj);
                    }}
                  >
                    LCL
                  </p>
                </div>
                <div>
                  {calender.ShipmentType === "LCL" && (
                    <img
                      className="transport-bottom"
                      src={urlResolver("icon/new.svg")}
                    />
                  )}
                </div>
              </div>
              <div>
                <div>
                  <p
                    className={
                      calender.ShipmentType === "FCL"
                        ? "props-text active-color"
                        : "props-text"
                    }
                    onClick={() => {
                      const obj = { ...calender };
                      obj.ShipmentType = "FCL";
                      if (!dataFcl.data.length) {
                        getVolumeData(obj, dataFcl.pageCount);
                      }
                      setCalender(obj);
                    }}
                  >
                    FCL
                  </p>
                </div>
                <div>
                  {calender.ShipmentType === "FCL" && (
                    <img
                      className="transport-bottom"
                      src={urlResolver("icon/new.svg")}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="search-section  order-md-2 order-1 mb-md-0 mb-3">
          <div className="search-filter">
            <div className="select">
              <select
                className="title-select"
                value={search.type}
                onChange={(event) => {
                  const obj = { ...search };
                  obj.type = event.target.value;
                  setSearch(obj);
                }}
              >
                <option value="">{t("Select")}</option>
                <option value="CNPJ">{t("CNPJ")}</option>
                <option value="Origin">{t("Origin")}</option>
                <option value="Destination">{t("Destination")}</option>
                <option value="PO Number">{t("PONumber")}</option>
                <option value="Shipper">{t("Shipper")}</option>
                <option value="Ship">{t("Ship")}</option>
              </select>
            </div>
            <div className="search-div">
              <input
                className="input-search"
                type="text"
                placeholder="Search"
                value={search.value}
                onChange={(event) => {
                  const obj = { ...search };
                  obj.value = event.target.value;
                  setSearch(obj);
                }}
              />
              <SearchOutlinedIcon
                className="search-icon"
                style={{ cursor: "pointer", color: "var(--color--blue2)" }}
              />
            </div>
          </div>
        </div> */}
      </div>
      {!loading ? (
        <div className="container-fluid main-container-chart">
          {calender.ShipmentType === "LCL" ? (
            <div className="centerButtonLoadmore">
              <HorizontalBarChart datasets={dataLcl} />
              {showMoreButton ? (
                <BtnOutline
                  title={
                    loadMore ? (
                      <CircularProgress
                        size="0.7rem"
                        style={{ color: "var(--color-blue)" }}
                      />
                    ) : (
                      t("ShowMore")
                    )
                  }
                  onClick={() => {
                    let temp = dataFcl.pageCount + 1;
                    getVolumeData(calender, count + 1);
                    setCount(count + 1);
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
          ) : calender.ShipmentType === "FCL" ? (
            <div className="centerButtonLoadmore">
              <HorizontalBarChart datasets={dataFcl} />
              {showMoreButton ? (
                <BtnOutline
                  title={
                    loadMore ? (
                      <CircularProgress
                        size="0.7rem"
                        style={{ color: "var(--color-blue)" }}
                      />
                    ) : (
                      t("ShowMore")
                    )
                  }
                  onClick={() => {
                    let temp = dataFcl.pageCount + 1;
                    getVolumeData(calender, temp);
                    // setCount(temp);
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
          ) : calender.ShipmentType === "LSE" ? (
            <div className="centerButtonLoadmore">
              <HorizontalBarChart datasets={dataLse} />
              {showMoreButton ? (
                <BtnOutline
                  title={
                    loadMore ? (
                      <CircularProgress
                        size="0.7rem"
                        style={{ color: "var(--color-blue)" }}
                      />
                    ) : (
                      t("ShowMore")
                    )
                  }
                  onClick={() => {
                    let temp = dataLse.pageCount + 1;
                    getVolumeData(calender, temp);
                    // setCount(temp);
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="skeleton-type">
          <Skeleton style={{ height: "50rem" }} />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Transport);
