import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import BtnOutline from "../../components/button/BtnOutline";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
import toast from "react-hot-toast";
import CancelIcon from "@mui/icons-material/Cancel";
import { CircularProgress } from "@mui/material";
import { withTranslation } from "react-i18next";

const NewUser = (props) => {
  const [loading, setLoading] = useState(false);
  const newUser = {
    OrgCodes: "",
    EmailId: "",
    ContactName: "",
    IsPorcess: false,
    IsOrder: false,
    IsFinance: false,
    IsReport: false,
    IsMyTeam: false,
  };

  const [mutltipleUser, setMutltipleUser] = useState([{ ...newUser }]);

  const onClickAddUser = () => {
    setLoading(true);

    if (!checkEmpty()) {
      const path = `${API_PATH.CREATE_NEW_USER}`;
      instance
        .post(path, mutltipleUser)
        .then((res) => {
          setLoading(false);
          toast.success(res._Message);
          setMutltipleUser([{ ...newUser }]);
        })
        .catch((err) => {
          console.log("create new user api fail");
          setLoading(false);
          toast.error(err?.response?.data?._Message ?? "Something went wrong!");
        });
    } else {
      setLoading(false);
      toast.error("Name and email is required");
    }
  };

  const onClickAddMultiple = () => {
    if (!checkEmpty()) {
      let arr = [...mutltipleUser];
      arr.push(newUser);
      setMutltipleUser(arr);
    } else {
      toast.error("Name and email is required");
    }
  };

  const checkEmpty = () => {
    let empty = false;
    for (let i = 0; i < mutltipleUser.length; i++) {
      if (!mutltipleUser[i].ContactName || !mutltipleUser[i].EmailId) {
        empty = true;
        break;
      } else {
        empty = false;
      }
    }
    return empty;
  };

  const onClickRemoveOne = (index) => {
    const arr = [...mutltipleUser];
    arr.splice(index, 1);
    setMutltipleUser([...arr]);
  };

  const onChangeEntry = (e, index, key) => {
    const arr = [...mutltipleUser];
    if (key === "ContactName" || key === "EmailId") {
      arr[index][key] = e.target.value;
    } else {
      arr[index][key] = e.target.checked;
    }
    setMutltipleUser([...arr]);
  };
  const { t } = props;
  return (
    <div className="new-user">
      <div className="new-user-text">
        <p className="m-0">{t("Newuser")}</p>
      </div>
      {mutltipleUser.map((i, index) => {
        return (
          <div key={index}>
            <div className="add-user-div d-flex" key={index}>
              <div className="d-flex add-user-text">
                <div className="add-user-textfield">
                  <TextField
                    // id="standard-basic"
                    // disabled={true}
                    label="Name"
                    variant="standard"
                    value={i.ContactName}
                    onChange={(e) => onChangeEntry(e, index, "ContactName")}
                  />
                </div>
                <div className="add-user-textfield">
                  <TextField
                    // id="standard-basic"
                    // disabled={true}
                    label="Email"
                    variant="standard"
                    value={i.EmailId}
                    onChange={(e) => onChangeEntry(e, index, "EmailId")}
                  />
                </div>
              </div>
              <div className="btn-add-user">
                <AddCircleOutlinedIcon onClick={() => onClickAddMultiple()} />
              </div>
              {mutltipleUser.length > 1 ? (
                <div className="btn-add-user">
                  <CancelIcon
                    style={{ color: "red" }}
                    onClick={() => onClickRemoveOne(index)}
                  />
                </div>
              ) : null}
            </div>
            <div className="user-permissions-div">
              <div>
                <p className="user-permission-head m-0">{t("Permission")}</p>
              </div>
              <div className="user-permissions-options">
                <div>
                  <AntSwitch
                    checked={i.IsPorcess}
                    onChange={(e) => onChangeEntry(e, index, "IsPorcess")}
                  />
                  <p>{t("Process")}</p>
                </div>
                <div>
                  <AntSwitch
                    checked={i.IsOrder}
                    onChange={(e) => onChangeEntry(e, index, "IsOrder")}
                  />
                  <p>{t("Order")}</p>
                </div>
                <div>
                  <AntSwitch
                    checked={i.IsFinance}
                    onChange={(e) => onChangeEntry(e, index, "IsFinance")}
                  />
                  <p>{t("Financial")}</p>
                </div>
                <div>
                  <AntSwitch
                    checked={i.IsReport}
                    onChange={(e) => onChangeEntry(e, index, "IsReport")}
                  />
                  <p>{t("Report")}</p>
                </div>
                <div>
                  <AntSwitch
                    checked={i.IsMyTeam}
                    onChange={(e) => onChangeEntry(e, index, "IsMyTeam")}
                  />
                  <p>{t("MyTeam")}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="save-button">
        <button
          disabled={loading}
          title={
            !loading ? (
              t("Save")
            ) : (
              <CircularProgress
                size="1.5rem"
                style={{ color: "var(--color-blue)" }}
              />
            )
          }
          onClick={() => onClickAddUser()}
          className="btn-newuser"
        >
          {t("Save")}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(NewUser);

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#006bff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "var(--color-white)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
