import React, { useState, useEffect } from "react";
import "./styles.css";
import { basename } from "../../../Config";
import Air from "./Air";
import Sea from "./Sea";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import toast from "react-hot-toast";
import urlResolver from "../../../utils/urlResolver";

const Thresholdsetting = () => {
  const [currentApp, setCurrentApp] = useState("AIR");
  const [thresoldSettings, setThresoldSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const onChangeThresold = (item, index, type) => {
    const arr = [...thresoldSettings];
    const value = parseInt(item.SettingValues);
    if (type === "ADD" && item.SettingValues < 100) {
      const i = { ...item, SettingValues: (value + 1).toString() };
      arr[index] = { ...i };
    } else {
      if (item.SettingValues > 1) {
        const j = { ...item, SettingValues: (value - 1).toString() };
        arr[index] = { ...j };
      }
    }

    setThresoldSettings([...arr]);
  };

  const onThresoldSave = (payload) => {
    setUpdating(true);
    instance
      .post(API_PATH.SET_THRESOLD, payload)
      .then((res) => {
        setUpdating(false);
        if (res._ResultMessage) {
          toast.success(res._ResultMessage);
        } else {
          toast.success("Something went wrong!!!");
        }
      })
      .catch((err) => {
        setUpdating(false);
        toast.success("Something went wrong!!!");
      });
  };

  const getThresold = () => {
    setLoading(true);
    instance
      .post(API_PATH.GET_THRESOLD_SETTINGS)
      .then((res) => {
        setLoading(false);
        if (res._Success) {
          setThresoldSettings(res._GenericValue);
        }
      })
      .catch((err) => {
        setLoading(false);
        setThresoldSettings([]);
      });
  };

  useEffect(() => {
    getThresold();
  }, []);

  return (
    <div className="new-user">
      <div className="edit-user-dead">
        <p>Threshold Setting</p>
      </div>
      <div className="deadline-filter">
        <div className="d-flex justify-content-around">
          <div>
            <div>
              <p
                className={
                  currentApp === "AIR"
                    ? "text-secdeadline active-color"
                    : "text-secdeadline"
                }
                onClick={() => {
                  setCurrentApp("AIR");
                }}
              >
                AIR
              </p>
            </div>
            <div>
              {currentApp === "AIR" && (
                <img className="line-image" src={urlResolver("icon/new.svg")} />
              )}
            </div>
          </div>
          <div>
            <div>
              <p
                className={
                  currentApp === "SEA"
                    ? "text-secdeadline active-color"
                    : "text-secdeadline"
                }
                onClick={() => {
                  setCurrentApp("SEA");
                }}
              >
                SEA
              </p>
            </div>
            <div>
              {currentApp === "SEA" && (
                <img className="line-image" src={urlResolver("icon/new.svg")} />
              )}
            </div>
          </div>
        </div>
      </div>

      {currentApp === "AIR" ? (
        <Air
          thresoldSettings={thresoldSettings}
          loading={loading}
          onChangeThresold={onChangeThresold}
          onThresoldSave={onThresoldSave}
          updating={updating}
        />
      ) : (
        <Sea
          thresoldSettings={thresoldSettings}
          loading={loading}
          onChangeThresold={onChangeThresold}
          onThresoldSave={onThresoldSave}
          updating={updating}
        />
      )}
    </div>
  );
};

export default Thresholdsetting;
