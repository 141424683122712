import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, CircularProgress, IconButton, Skeleton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/interceptor";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { genrateDownloadLink } from "../../../utils/utility";
import Nodata from "../../../components/Nodata";
import { useLocation } from "react-router-dom";

export default function DocUploadShipmentSidebar({
  setSidebar,
  sidebar,
  item,
}) {
  const [Notadoc, setNotadoc] = useState({});
  const [invoice, setInvoice] = useState([]);
  const [loadingboleto, setLoadingboleto] = useState(false);
  const [loadingNota, setLoadingNota] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/financial/invoice") {
      notaDocumentData();
      invoiceDocument();
    }
  }, [location.pathname]);

  const notaDocumentData = () => {
    setLoadingNota(true);
    instance
      .post(`${API_PATH.GET_DOCUMENT_NOTA_LINK}${item.ShipmentID}`)
      .then((res) => {
        if (res._IsSuccess) {
          setNotadoc(res?._Data ?? {});
        } else {
          setNotadoc({});
        }
      })
      .catch((err) => {
        setNotadoc({});
        console.log("Nota Doc Api error", err);
      })
      .finally(() => {
        setLoadingNota(false);
      });
  };

  const invoiceDocument = () => {
    setLoadingboleto(true);
    const body = {
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
      ShipmentID: "",
      DocType: "",
      Ledger: item?.Ledger ?? "",
      CompanyCode: item?.CompanyCode ?? "",
      TransactionNubmer: item?.TransactionNumber ?? "",
      TransactionType: item?.TransactionType ?? "",
    };
    instance
      .post(API_PATH.GET_ARBOLINV_DOCUMENTS, body)
      .then((res) => {
        if (res._IsSuccess) {
          setInvoice(res?._Data ?? {});
        } else {
          setInvoice({});
        }
      })
      .catch((err) => {
        setInvoice({});
        console.log("Arobolinve Document Api error", err);
      })
      .finally(() => {
        setLoadingboleto(false);
      });
  };

  const onClickDownload = (data, items, index) => {
    setLoadingDownload(index);
    const payload = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
      ShipmentID: "",
      DocType: data?.SC_DocType ?? "",
      DocumentName: "",
      DocumentDate: "",
      DocumentPk: data?.SC_PK ?? "",
      Ledger: items?.Ledger ?? "",
      CompanyCode: items?.CompanyCode ?? "",
      TransactionNubmer: items?.TransactionNumber ?? "",
      TransactionType: items?.TransactionType ?? "",
    };
    instance
      .post(API_PATH.DOWNLOAD_INVOICE_DOCS, payload)
      .then((res) => {
        if (res?._IsSuccess && res?._Data?.ImageDataVC) {
          const downloadLink = document.createElement("a");
          const prefix = genrateDownloadLink(res?._Data?.SC_FileName);
          downloadLink.href = `${prefix}${res?._Data?.ImageDataVC}`;
          if (prefix) {
            try {
              downloadLink.download =
                item.SC_DocType === "BOL"
                  ? res?._Data?.SC_FileName
                  : res?._Data?.SC_FileName ?? "";
            } catch (error) {
              console.log(error);
            }
            // downloadLink.download =
            //   item.SC_DocType === "BOL"
            //     ? res?._Data?.SC_DisplayDescription
            //     : res?._Data?.SC_FileName ?? "";
            downloadLink.click();
          } else {
            toast.error("Invalid document found!");
          }
        } else {
          toast.error("No data found!");
        }
      })
      .catch((err) => {
        console.log("download invoices error", err);
      })
      .finally(() => {
        setLoadingDownload(-1);
      });
  };
  return (
    <Drawer
      anchor={"right"}
      width={500}
      open={sidebar}
      onClose={() => {
        setSidebar(false);
      }}
    >
      <Box
        sx={{ width: { xs: 250, sm: 300, md: 500 } }}
        // style={{ padding: "1rem", backgroundColor: "green" }}
      >
        <div
          style={{
            height: "5rem",
            backgroundColor: "var(--color-blue2)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "10px solid var(--color-blue1)",
          }}
        >
          <div>
            {" "}
            <h3
              style={{
                color: "var(--color-white)",
                paddingLeft: "10px",
              }}
            >
              Documents List
            </h3>{" "}
          </div>
          <IconButton size="large">
            <CloseOutlinedIcon
              style={{
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => setSidebar(false)}
            />
          </IconButton>
        </div>
        <div
        // style={{
        //   backgroundColor: "yellow",
        // }}
        >
          {!loadingNota && !loadingboleto ? (
            <>
              {(Notadoc?.NotaLink &&
                Notadoc?.NotaLink !== null &&
                Notadoc?.NotaLink !== "") ||
              invoice.length ? (
                <>
                  {Notadoc?.NotaLink &&
                  Notadoc?.NotaLink !== null &&
                  Notadoc?.NotaLink !== "" ? (
                    <div className="pe-2">
                      <div className="card my-2">
                        <div className="card-body p-2">
                          <h5 className="card-title">NOTA FISCAL</h5>
                        </div>

                        <div className="row  mx-2 mb-1">
                          <div className="col px-0">
                            <a
                              href={
                                Notadoc?.NotaLink.includes("http://")
                                  ? Notadoc?.NotaLink
                                  : `http://${Notadoc?.NotaLink}`
                              }
                              target="blank"
                              className="notaLink"
                            >
                              <button
                                className="docupload-btn"
                                tabindex="0"
                                type="button"
                                target="blank"
                              >
                                <span className="MuiButton-label">View</span>
                                {/* <span className="MuiTouchRipple-root"></span> */}
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {invoice.length ? (
                    invoice.map((i, index) => {
                      return (
                        <div>
                          <div className="px-2" key={index}>
                            <div
                              className="card my-2 d-flex flex-row align-items-center"
                              style={{ height: "5rem" }}
                            >
                              <div className="card-body p-2">
                                <h5 className="card-title">
                                  {i?.SC_DocType === "BOL"
                                    ? i?.SC_DisplayDescription
                                    : i?.SC_FileName ?? "N/A"}
                                </h5>
                              </div>
                              <div className="row  mx-2 ">
                                <div className="col px-0">
                                  {/* <a className="notaLink"> */}
                                  <button
                                    className="docupload-btn"
                                    tabindex="0"
                                    type="button"
                                    style={{ width: "100px" }}
                                  >
                                    <span
                                      className="MuiButton-label"
                                      onClick={() => {
                                        onClickDownload(i, item, index);
                                      }}
                                    >
                                      {loadingDownload === index ? (
                                        <CircularProgress
                                          size="1rem"
                                          style={{
                                            color: "var(--color-white)",
                                          }}
                                        />
                                      ) : (
                                        "Download"
                                      )}
                                    </span>
                                  </button>
                                  {/* </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Nodata />
                  )}
                </>
              ) : (
                <Nodata />
              )}
            </>
          ) : (
            <div className="skeleton-type mt-2">
              {[1, 2, 3].map(() => {
                return (
                  <Skeleton style={{ height: "5rem", marginTop: "0.5rem" }} />
                );
              })}
            </div>
          )}
        </div>
      </Box>
    </Drawer>
  );
}
