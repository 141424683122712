import { color } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import NoAccess from "../../components/NoAccess";
import { withTranslation } from "react-i18next";
import Sidebar from "./Sidebar";

function Report(props) {
  const location = useLocation();
  const { permissions } = useSelector((state) => state.permission);
  const { t } = props;
  return (
    <>
      <div className="container-fluid report-sec">
        <div className="row">
          <div className="col-12 col-lg-2 report-sec-first">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-12 p-1 report-sec-second">
            <Outlet  />
          </div>
        </div>
      </div>
      {location.pathname.includes("demurrage") ? (
        <marquee
          style={{
            position: "fixed",
            bottom: "0",
            fontSize: "var(--fs-label)",
            color: "red",
            backgroundColor: "#ededed",
          }}
        >
          {t("Disclaimer")}
        </marquee>
      ) : null}
    </>
  );
}

export default withTranslation()(Report);
