import React, { useState } from "react";
import "./document.css";

import { routeConstant } from "../../routing";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { basename } from "../../Config";
import { useEffect } from "react";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import urlResolver from "../../utils/urlResolver";

function Document() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searcheddata, setSearcheddata] = useState([]);
  const [housenumber, setHousenumber] = useState("");
  const [loadingSearched, setLoadingSearched] = useState(false);

  useEffect(() => {
    navigate(routeConstant.DOCUMENTPENDING);
  }, []);

  useEffect(() => {
    setHousenumber("");
  }, [location.pathname]);

  const getSearchedResult = () => {
    setLoadingSearched(true);
    const body = {
      Status:
        location.pathname === "/documents/approved"
          ? 2
          : location.pathname === "/documents/disapproved"
          ? 3
          : null,
      SortColumnName: "",
      SortDirection: "",
      HouseBillNumber: housenumber ?? "",
      CurrentPage: 0,
      PageSize: 0,
    };
    instance
      .post(API_PATH.GET_DOCUMENT_DATA, body)
      .then((res) => {
        setLoadingSearched(false);
        if (res._IsSuccess) {
          setSearcheddata(res?._Data);
        } else {
          toast.error(res?._Message ?? "");
        }
      })
      .catch((err) => {
        // toast.error(err?.response?.data?._Message ?? "");
        setLoadingSearched(false);
        setSearcheddata([]);
        console.log("Search docs api fail", err);
      });
  };

  return (
    <div className="documents-head-sections">
      <div className="document-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 document-head p-0">
              <p>Documents Approval</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between document-mid-section flex-wrap">
          <div className="document-filter">
            <div className="text-head d-flex justify-content-between">
              <p
                onClick={() => {
                  navigate(routeConstant.DOCUMENTPENDING);
                }}
                className={
                  location.pathname.includes(routeConstant.DOCUMENTPENDING)
                    ? "text-sec document-active "
                    : "text-sec document-inactive "
                }
              >
                Pending documents
              </p>

              <p
                onClick={() => {
                  navigate(routeConstant.DOCUMENTAPPROVED);
                }}
                className={
                  location.pathname === "/documents/approved"
                    ? "text-sec document-active "
                    : "text-sec document-inactive "
                }
              >
                Approved documents
              </p>
              <p
                onClick={() => {
                  navigate(routeConstant.DOCUMENTDISAPPROVED);
                }}
                className={
                  (location.pathname === "/documents/disapproved"
                    ? " document-active "
                    : "document-inactive ") + "text-sec"
                }
              >
                Disapproved documents
              </p>
            </div>
            <div
              className={
                (location.pathname.includes(routeConstant.DOCUMENTPENDING)
                  ? " circle-pending "
                  : location.pathname.includes(
                      routeConstant.DOCUMENTDISAPPROVED
                    )
                  ? "circle-disapproved"
                  : "circle-approved") + " circle-document"
              }
            >
              <img src={urlResolver("icon/line-center.svg")} />
            </div>
          </div>
          <div className="document-search">
            <input
              className="input-document-search "
              type="text"
              placeholder="House Number"
              value={housenumber}
              onChange={(event) => {
                setHousenumber(event.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  getSearchedResult();
                }
              }}
            />
            {housenumber.length !== 0 ? (
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setHousenumber("");
                  navigate(location.pathname);
                }}
              />
            ) : null}
            <img
              src={urlResolver("icon/icon-magnifying-glass.svg")}
              alt="Search icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                getSearchedResult();
              }}
            />
          </div>
        </div>
      </div>
      <Outlet context={[searcheddata, setSearcheddata]} />
    </div>
  );
}

export default Document;
