import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { shipmentReducer } from "./reducers/ShipmentReducer";
import { permissionReducer } from "./reducers/UserReducer";
import { teamReducer } from "./reducers/MyTeamReducer";
import { orderReducer } from "./reducers/OrderReducer";

const reducer = combineReducers({
  shipments: shipmentReducer,
  permission: permissionReducer,
  myteam: teamReducer,
  orders: orderReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
