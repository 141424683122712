import React from "react";

const NoRoute = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      No page found!
    </div>
  );
};

export default NoRoute;
