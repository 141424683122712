import React, { useState, useEffect } from "react";
import "./process.css";
import { withTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SkeletonProcess from "./components/SkeletonProcess";
import { routeConstant } from "../../routing";
import BtnOutline from "../../components/button/BtnOutline";
import { useDispatch, useSelector } from "react-redux";
import { getShipments } from "../../store/redux/actions/ShipmentAction";
import { basename } from "../../Config";
import Nodata from "../../components/Nodata";
import { CircularProgress } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { toast } from "react-hot-toast";
import { useRef } from "react";
import urlResolver from "../../utils/urlResolver";

function Process(props) {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const [shipmentList, setShipmentList] = useState([]);
  const [shipmentData, setShipmentData] = useState([]);
  const [search, setSearch] = useState({
    type: "",
    value: "",
  });
  const [placeholderText, setPlaceholderText] = useState("Search");
  const TotalCount = useRef(null);
  const [advancesearch, setAdvancesearch] = useState({
    sort: "LastEditedDateTime",
    order: "DESC",
    mode: "",
    status: "",
    direction: "",
    activeA: false,
    activeS: false,
    activeR: false,
    activeP: false,
    activeL: false,
    activeAr: false,
    activeI: false,
    activeE: false,
  });
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [showstatus, setShowstatus] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  const { shipment, loading, isLoadMore, error, list, totalCount } =
    useSelector((state) => {
      return state.shipments;
    });
  const [selectedListItem, setSelectedListItem] = useState(0);
  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    let isSearch = 0;
    // if the user pastes this url "/process"
    if (location.pathname === "/process") {
      if (location.state === null) {
        dispatch(
          getShipments(count, search, advancesearch, "", isSearch, signal)
        );
      }
    }
  }, [location]);
  useEffect(() => {
    let isSearch = 0;

    if (
      location?.pathname == "/process/details" &&
      location?.state?.item?.status === false
    ) {
      dispatch(
        getShipments(count, search, advancesearch, "", isSearch, signal)
      );
    }
    // if user pastes this in url /process/details
    if (location?.pathname === "/process/details" && location.state === null) {
      dispatch(
        getShipments(count, search, advancesearch, "", isSearch, signal)
      );
    }
    // if uuser refreshes the page
    if (
      location?.pathname === "/process/details" &&
      location?.state?.from === undefined
    ) {
      dispatch(
        getShipments(count, search, advancesearch, "", isSearch, signal)
      );
    }
  }, []);
  useEffect(() => {
    setShipmentData(shipment);
    setShipmentList(list);
  }, [shipment]);

  const { t } = props;

  useEffect(() => {
    let isSearch = 0;
    // if the user navigates from order
    if (location?.state?.from === "order") {
      let temp = {
        type: "ShipmentId",
        value: location?.state?.item?.ShipmentID,
      };
      dispatch(getShipments(count, temp, advancesearch, "", isSearch, signal));
    }
    // onClick search
    if (
      location?.state?.from === "onClickSearchProcess" ||
      location?.state?.from === "onEnterSearchProcess"
    ) {
      let isSearch = search.type !== "" ? 1 : 0; // 1 gives all the shipments while 0 gives the last 3 months data.
      dispatch(getShipments(0, search, advancesearch, "", isSearch, signal));
    }

    // if the user navigates from 48 hours dashboard list
    if (location?.state?.from === "dashboardlist") {
      let temp = {
        type: "ShipmentId",
        value: location?.state?.item?.ShipmentID,
      };
      dispatch(getShipments(count, temp, advancesearch, "", isSearch, signal));
    }
    // if the user navigates from dashboard seemore button in status
    if (location?.state?.from === "dashboardseemore") {
      setShowstatus(location?.state?.status);
      setActiveFilter(location?.state?.status);
      let tempVar = location?.state?.status;
      dispatch(
        getShipments(count, search, advancesearch, tempVar, isSearch, signal)
      );
    }
    if (location?.state?.from === "notification") {
      let temp = {
        type: "ShipmentId",
        value: location?.state?.item?.ShipmentID,
      };
      dispatch(getShipments(count, temp, advancesearch, "", isSearch, signal));
    }
    // if the user clicks on show more button in shipment list (process)
    if (showMore) {
      dispatch(
        getShipments(count, search, advancesearch, showstatus, isSearch, signal)
      );
      setShowMore(false);
    }
    if (!loading && shipment.length === 0) {
      navigate(routeConstant.PROCESS);
    }
    return () => {
      setCheckAbort(true);
      return controller.abort();
    };
  }, [dispatch, count]);

  const closeNav = () => {
    if (nav) {
      setDisabled(false);
      setNav(false);
    } else {
      setNav(true);
      setDisabled(true);
      setSearch({
        type: "",
        value: "",
      });
    }
  };

  const handleCardClick = (item, index) => {
    setSelectedListItem(index ?? 0);
    const path = `${routeConstant.PROCESS}/${routeConstant.PROCESSDETAILS}`;
    navigate(path, {
      state: { item: item },
    });
  };

  useEffect(() => {
    if (shipment.length) handleCardClick(shipment[0]);
    else handleCardClick({ status: false });
  }, [shipment]);

  const onSearch = (e) => {
    if (e.key === "Enter") {
      if (search.type && search.value) {
        if (search.type === "" && search.value.length > 1) {
          const path = `${routeConstant.PROCESS}/${routeConstant.PROCESSDETAILS}`;
          navigate(path, {
            state: {
              item: {
                ShipmentID: search.value,
              },
              from: "onEnterSearchProcess",
            },
          });
          setSelectedListItem(null);
        } else {
          let isSearch = search.type !== "" ? 1 : 0;
          if (e.key === "Enter") {
            const path = `${routeConstant.PROCESS}/${routeConstant.PROCESSDETAILS}`;
            navigate(path, {
              state: {
                from: "onEnterSearchProcess",
              },
            });
            dispatch(
              getShipments(0, search, advancesearch, "", isSearch, signal)
            );
          }
        }
      } else {
        toast.error("Please select both fields");
      }
    }
  };
  const onClickSearch = () => {
    let isSearch = search.type !== "" ? 1 : 0; // 1 gives all the shipments while 0 gives the last 3 months data.
    navigate(routeConstant.PROCESS, {
      state: {
        from: "onClickSearchProcess",
      },
    });
    dispatch(getShipments(0, search, advancesearch, "", isSearch, signal));
  };
  const handleClear = () => {
    let isSearch = 0;
    const tempSearch = {
      type: "",
      value: "",
    };

    dispatch(
      getShipments(count, tempSearch, advancesearch, "", isSearch, signal)
    );
  };
  return (
    <>
      <div>
        {loading ? (
          <div
            style={{
              width: "calc(100% - 40px)",
              margin: "0 auto",
              paddingTop: "50px",
            }}
          >
            <SkeletonProcess />
          </div>
        ) : (
          <div
            style={{          
              width: "calc(100% - 40px)",
              margin: "0 auto",
              paddingTop: "50px",
            }}
            className="container-fluid"
          >
            <div className="row ">
              <div
                className="col-12  col-lg-3 left-row-process"
                id="left-process-div"
              >
                <div className="card-head d-flex">
                  <div
                    className="search-filter "                    
                  >
                    <div className="select">
                      <select
                        disabled={nav}
                        className="title-select text-ellipsis"
                        value={search.type}
                        onChange={(event) => {
                          const obj = { ...search };
                          obj.type = event.target.value;
                          if (event.target.value === "Ref") {
                            setPlaceholderText("HBL No | Shipment No | PO No");
                          } else if (event.target.value === "ShipperReff") {
                            setPlaceholderText(
                              "HBL No | Shipment No | PO No | Shipper Ref #"
                            );
                          } else {
                            setPlaceholderText("Search");
                          }
                          setSearch(obj);
                        }}
                      >
                        <option value="" disabled selected hidden>
                          {t("Select")}
                        </option>
                        <option value="CNPJ">
                          <span>{t("CNPJ")}</span>
                        </option>
                        <option value="Origin Port">{t("Origin Name")}</option>
                        <option value="Destination Port">
                          {t("Dest Name")}
                        </option>
                        <option value="PO Number">{t("PONumber")}</option>
                        <option value="ShipperName">{t("Shipper")}</option>
                        <option value="ConsigneeName">{t("Consignee")}</option>
                        <option value="Ship">{t("Vessel")}</option>
                        <option value="ShipmentId">
                          Shipment #
                        </option>
                        <option value="Ref">
                          Ref #
                        </option>
                        {/* <option value="ShipperReff">Shipper Ref #</option> */}                      
                      </select>
                    </div>
                    <div className="search-div">
                      <input
                        disabled={nav}
                        className="input-search"
                        type="text"
                        placeholder={
                          placeholderText === "Search"
                            ? t(placeholderText)
                            : placeholderText
                        }
                        value={search.value}
                        onKeyPress={(e) => onSearch(e)}
                        onChange={(event) => {
                          const obj = { ...search };
                          obj.value = event.target.value;
                          setSearch(obj);
                        }}
                      />
                      {search.value.length ? (
                        <CloseOutlinedIcon
                          style={{ cursor: "pointer", marginLeft: "-20px" }}
                          onClick={() => {
                            const obj = { ...search, type: "", value: "" };
                            setSearch(obj);
                            handleClear();
                          }}
                        />
                      ) : null}
                      <SearchOutlinedIcon
                        onClick={() => {
                          if (search.type && search.value) {
                            onClickSearch();
                          } else {
                            toast.error("Please select both fields");
                          }
                        }}
                        className="search-icon"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="filter-icon">
                      <TuneOutlinedIcon
                        className="filter-sec"
                        style={{ cursor: "pointer" }}
                        onClick={() => closeNav()}
                      />
                    </div>
                  </div>
                  {nav ? (
                    <div className="sort-box">
                      <div className="div-sort" value={advancesearch.sort}>
                        <p>{t("SortBy")} :</p>
                        <div className="sort-option-div ">
                          <div className="d-flex" id="radiodiv">
                            <input
                              type="radio"
                              id="CreatedDateTime"
                              onChange={(event) => {
                                const obj = { ...advancesearch };
                                obj.sort = event.target.value;

                                setAdvancesearch(obj);
                              }}
                              checked={
                                advancesearch.sort === "CreatedDateTime"
                                  ? true
                                  : false
                              }
                              value="CreatedDateTime"
                            />
                            <label
                              htmlFor="CreatedDateTime"
                              style={{
                                color:
                                  advancesearch.sort === "CreatedDateTime"
                                    ? "#120b5e"
                                    : "",
                              }}
                            >
                              {t("Created")}
                            </label>
                          </div>
                          <div className="d-flex" id="radiodiv">
                            <input
                              type="radio"
                              id="LastEditedDateTime"
                              onChange={(event) => {
                                const obj = { ...advancesearch };
                                obj.sort = event.target.value;

                                setAdvancesearch(obj);
                              }}
                              checked={
                                advancesearch.sort === "LastEditedDateTime"
                                  ? true
                                  : false
                              }
                              value="LastEditedDateTime"
                            />
                            <label
                              htmlFor="LastEditedDateTime"
                              style={{
                                color:
                                  advancesearch.sort === "LastEditedDateTime"
                                    ? "#120b5e"
                                    : "",
                              }}
                            >
                              {t("LastUpdated")}
                            </label>
                          </div>
                          <div className="d-flex" id="radiodiv">
                            <input
                              type="radio"
                              id="ETA"
                              onChange={(event) => {
                                const obj = { ...advancesearch };
                                obj.sort = event.target.value;

                                setAdvancesearch(obj);
                              }}
                              checked={
                                advancesearch.sort === "ETA" ? true : false
                              }
                              value="ETA"
                            />
                            <label
                              htmlFor="ETA"
                              style={{
                                color:
                                  advancesearch.sort === "ETA" ? "#120b5e" : "",
                              }}
                            >
                              {t("ETA")}
                            </label>
                          </div>
                          <div className="d-flex" id="radiodiv">
                            <input
                              type="radio"
                              id="ETD"
                              onChange={(event) => {
                                const obj = { ...advancesearch };
                                obj.sort = event.target.value;

                                setAdvancesearch(obj);
                              }}
                              checked={
                                advancesearch.sort === "ETD" ? true : false
                              }
                              value="ETD"
                            />
                            <label
                              htmlFor="ETD"
                              style={{
                                color:
                                  advancesearch.sort === "ETD" ? "#120b5e" : "",
                              }}
                            >
                              {t("ETD")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="div-sort" value={advancesearch.order}>
                        <p className="order">{t("OrderBy")} :</p>
                        <div className="sort-option-div">
                          <div className="d-flex" id="radiodiv">
                            <input
                              type="radio"
                              id="ASC"
                              onChange={(event) => {
                                const obj = { ...advancesearch };
                                obj.order = event.target.value;
                                setAdvancesearch(obj);
                              }}
                              checked={
                                advancesearch.order === "ASC" ? true : false
                              }
                              value="ASC"
                            />
                            <label
                              htmlFor="ASC"
                              style={{
                                color:
                                  advancesearch.order === "ASC"
                                    ? "#120b5e"
                                    : "",
                              }}
                            >
                              {t("Ascending")}
                            </label>
                          </div>
                          <div className="d-flex" id="radiodiv">
                            <input
                              type="radio"
                              id="DESC"
                              onChange={(event) => {
                                const obj = { ...advancesearch };
                                obj.order = event.target.value;
                                setAdvancesearch(obj);
                              }}
                              checked={
                                advancesearch.order === "DESC" ? true : false
                              }
                              value="DESC"
                            />

                            <label
                              htmlFor="DESC"
                              style={{
                                color:
                                  advancesearch.order === "DESC"
                                    ? "#120b5e"
                                    : "",
                              }}
                            >
                              {t("Descending")}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="filterby-div">
                        <p>{t("FilterBy")} :</p>

                        <div className="filterby-options">
                          <div className="filterby-div-inner">
                            <div
                              className={
                                advancesearch.mode === "AIR" &&
                                advancesearch.activeA
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id1"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.mode = "AIR";
                                obj.activeA = !obj.activeA;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    advancesearch.mode === "AIR" &&
                                    advancesearch.activeA
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("Air")}
                              </p>
                            </div>
                            <div
                              className={
                                advancesearch.mode === "SEA" &&
                                advancesearch.activeS
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id2"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.mode = "SEA";
                                obj.activeS = !obj.activeS;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    advancesearch.mode === "SEA" &&
                                    advancesearch.activeS
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("Sea")}
                              </p>
                            </div>
                            <div
                              className={
                                advancesearch.mode === "ROA" &&
                                advancesearch.activeR
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id3"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.mode = "ROA";
                                obj.activeR = !obj.activeR;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    advancesearch.mode === "ROA" &&
                                    advancesearch.activeR
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("Land")}
                              </p>
                            </div>
                          </div>
                          <div className="filter-divider"></div>
                          <div className="filterby-div-inner">
                            <div
                              className={
                                (advancesearch.status === "PreLoad" &&
                                  advancesearch.activeP) ||
                                activeFilter === "PreLoad"
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id4"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.status = "PreLoad";
                                obj.activeP = !obj.activeP;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    (advancesearch.status === "PreLoad" &&
                                      advancesearch.activeP) ||
                                    activeFilter === "PreLoad"
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("PreLoaded")}
                              </p>
                            </div>
                            <div
                              className={
                                (advancesearch.status === "Loaded" &&
                                  advancesearch.activeL) ||
                                activeFilter === "Loaded"
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id5"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.status = "Loaded";
                                obj.activeL = !obj.activeL;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    (advancesearch.status === "Loaded" &&
                                      advancesearch.activeL) ||
                                    activeFilter === "Loaded"
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("Loaded")}
                              </p>
                            </div>
                            <div
                              className={
                                (advancesearch.status === "Arrived" &&
                                  advancesearch.activeAr) ||
                                activeFilter === "Arrived"
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id6"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.status = "Arrived";
                                obj.activeAr = !obj.activeAr;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    (advancesearch.status === "Arrived" &&
                                      advancesearch.activeAr) ||
                                    activeFilter === "Arrived"
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("Arrived")}
                              </p>
                            </div>
                          </div>
                          <div className="filter-divider"></div>
                          <div className="filterby-div-inner">
                            <div
                              className={
                                (advancesearch.direction === "IMP" &&
                                  advancesearch.activeI) ||
                                activeFilter === "IMP"
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id7"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.direction = "IMP";
                                obj.activeI = !obj.activeI;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    (advancesearch.direction === "IMP" &&
                                      advancesearch.activeI) ||
                                    activeFilter === "IMP"
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("Impo")}
                              </p>
                            </div>

                            <div
                              className={
                                (advancesearch.direction === "EXP" &&
                                  advancesearch.activeE) ||
                                activeFilter === "EXP"
                                  ? "filter-child-div active-mode"
                                  : "filter-child-div"
                              }
                              id="id8"
                              onClick={() => {
                                const obj = { ...advancesearch };
                                obj.direction = "EXP";
                                obj.activeE = !obj.activeE;
                                setAdvancesearch(obj);
                                setActiveFilter("");
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    (advancesearch.direction === "EXP" &&
                                      advancesearch.activeE) ||
                                    activeFilter === "EXP"
                                      ? "#120b5e"
                                      : "",
                                }}
                              >
                                {t("Expo")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end">
                        <BtnOutline
                          title="Search"
                          onClick={() => onClickSearch()}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                {shipmentData && shipmentData.length > 0 ? (
                  shipmentData.map((item, index) => {
                    return (
                      <div
                        className={
                          index === selectedListItem
                            ? "card-mid-selected"
                            : "card-mid "
                        }
                        key={index}
                        onClick={() => handleCardClick(item, index)}
                      >
                        <div className="d-flex">
                          <div className="d-flex card-mid-img">
                            {item.TransportMode === "SEA" ? (
                              <img src={urlResolver("icon/icon-ship.svg")} />
                            ) : item.TransportMode === "AIR" ? (
                              <img
                                src={urlResolver("icon/icon-plane-white.svg")}
                              />
                            ) : item.TransportMode === "ROA" ? (
                              <img src={urlResolver("icon/truck_color.svg")} />
                            ) : null}

                            <div className="line  m-2"></div>
                          </div>
                          <div className="order-number-cont">
                            <div>
                              <p className="card-mid-head">
                                {`${t("PONumber")}:`}
                              </p>
                            </div>
                            <div
                              style={{
                                minWidth: "10%",
                                width: "10rem",
                              }}
                            >
                              <p
                                className="card-mid-para"
                                title={item?.OrderReference ?? t("N/A")}
                              >
                                {item?.OrderReference ?? t("N/A")}
                              </p>
                            </div>
                          </div>
                          <div></div>
                        </div>

                        <div className="card-div">
                          <ul
                            style={{
                              paddingLeft: "0rem",
                            }}
                          >
                            <li
                              className={
                                item?.Status === "PreLoaded"
                                  ? "card-mid-list colorPreload"
                                  : item.Status === "Loaded"
                                  ? "card-mid-list colorLoaded"
                                  : item.Status === "Arrived"
                                  ? "card-mid-list colorArrived"
                                  : "card-mid-list"
                              }
                            >
                              {t(item?.Status ?? t("N/A"))}
                              {/* {item?.Status ?? "N/A"} */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Nodata />
                )}
                {(totalCount > shipmentData.length ||
                  (totalCount === 0 && shipmentData.length != 0)) && (
                  <div className=" d-flex justify-content-center buttonline btn-style">
                    <BtnOutline
                      title={
                        isLoadMore ? (
                          <CircularProgress
                            size="0.7rem"
                            style={{ color: "var(--color-blue)" }}
                          />
                        ) : (
                          t("ShowMore")
                        )
                      }
                      disabled={isLoadMore}
                      onClick={() => {
                        const counts = count + 1;
                        setCount(counts);
                        setShowMore(true);
                      }}
                    />
                  </div>
                )}
                {/* {(!loading &&
                  shipmentData.length > 19 &&
                  shipmentData.length % 20 === 0) ||
                shipmentData.length > 19 ? (
                  <div className=" d-flex justify-content-center buttonline btn-style">
                    <BtnOutline
                      title={
                        isLoadMore ? (
                          <CircularProgress
                            size="0.7rem"
                            style={{ color: "var(--color-blue)" }}
                          />
                        ) : (
                          t("ShowMore")
                        )
                      }
                      disabled={isLoadMore}
                      onClick={() => {
                        const counts = count + 1;
                        setCount(counts);
                        setShowMore(true);
                      }}
                    />
                  </div>
                ) : null} */}
              </div>
              <div
                className="col-12  col-lg-9 mid-container d-flex "
                id="left-process-div"
              >
                <Outlet />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default withTranslation()(Process);
