import React, { useState, useEffect } from "react";
import "../../process/process.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Milestone from "../../../components/Milestone/Milestone";
import { basename } from "../../../Config";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { routeConstant } from "../../../routing";
import urlResolver from "../../../utils/urlResolver";

const MapComp = ({ item, detailLoading }) => {
  const [milestoneData, setMilestoneData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  useEffect(() => {
    getMilestoneData();
    return () => {
      setCheckAbort(true);
      return controller.abort();
    };
  }, [location?.state?.item?.OrderNumber]);

  const getMilestoneData = () => {
    setLoading(true);
    const params = {
      OrderId: "",
      SplitNumber: location?.state?.item?.OrderSplitNumber ?? "",
      OrderNumber: location?.state?.item?.OrderNumber,
      BuyerCode: location?.state?.item?.BuyerCode,
      SortColumnName: "ShipmentID",
      SortDirection: "ASC",
      CurrentPage: 0,
      PageSize: 10,
    };
    instance
      .post(API_PATH.GET_ORDER_MILESTONE, params, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          setMilestoneData(res._Data);
        } else {
          setMilestoneData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setMilestoneData([]);
        if (!checkAbort) setLoading(false);
      });
  };

  return (
    <>
      <div className="map-main-comp">
        {detailLoading ? (
          <Skeleton
            variant="rectangular"
            height={50}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
        ) : (
          <div
            className="map-head d-flex pointer"
            onClick={() => {
              const path = `${routeConstant.PROCESS}/${routeConstant.PROCESSDETAILS}`;

              navigate(path, {
                state: { item: item, from: "order" },
              });
            }}
          >
            <div className="d-flex card-mid-img  map-img">
              <img
                alt="icon paper"
                src={urlResolver("icon/icon-paper.svg")}
                style={{
                  paddingLeft: "none",
                  marginLeft: "none",
                }}
              />
              <div className="line map-line  m-2"></div>
            </div>

            <div className="map-top-div">
              <p className="color-grey">Process:</p>
              <p
                className="color-blue2"
                style={{
                  maxWidth: "15rem",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item?.OrderReference}
              </p>
            </div>
            <div className="map-card-div">
              <ArrowForwardIosIcon className="map-card-arrow" />
            </div>
          </div>
        )}
        <div className="map-milestone-div map-order">
          <Milestone milestoneData={milestoneData} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default MapComp;
