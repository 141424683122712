import { CircularProgress, Drawer, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import BtnOutline from "../../../components/button/BtnOutline";
import { basename, baseUrl } from "../../../Config";
import "../document.css";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import "../../../components/header/header.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { API_PATH } from "../../../constant/apiPath";
import { Textarea } from "@mui/joy";
import { Date } from "../../../utils/moment";
import toast from "react-hot-toast";
import Nodata from "../../../components/Nodata";
import { genrateDownloadLink } from "../../../utils/utility";
import instance from "../../../api/interceptor";
import BtnGreen from "../../../components/button/BtnGreen";
import urlResolver from "../../../utils/urlResolver";

const drawerWidth = 240;

const DocumentTracking = (props) => {
  const { id } = useParams();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pendingDocs, setPendingDocs] = useState({});
  const [modalOpen, setModalOpen] = useState({
    open: false,
    openFor: "",
  });
  const [textValue, setTextValue] = useState("");
  const [changedStatus, setChangedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [errorVerification, setErrorVerification] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(-1);

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }} className="Cross-img">
        <CloseIcon onClick={() => handleDrawerToggle()} />
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            {pendingDocs.HouseBillNumber && (
              <ListItemText
                primary={`House Number :  ${
                  pendingDocs?.HouseBillNumber ?? "N/A"
                }`}
              />
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const { window } = props;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = (event) => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    verifyUrl();
  }, []);

  const verifyUrl = () => {
    setLoadingVerify(true);
    const url = `${baseUrl}${API_PATH.DOCUMENT_FROM_URL}${id}`;
    axios
      .get(url)
      .then((res) => {
        setLoadingVerify(false);
        if (res.data?._IsSuccess) {
          setPendingDocs(res?.data?._Data ?? {});
        } else {
          toast.error(res?.data?._Message ?? "");
        }
      })
      .catch((err) => {
        setLoadingVerify(false);
        setErrorVerification(true);
        toast.error(err?.response?.data?._Message ?? "");
        console.log("Verify from url api error", err);
      });
  };

  const DocApproval = (status) => {
    if (status === 3 && textValue.length === 0) {
      toast.error("Please provide reason");
    } else {
      setLoading(true);
      const body = {
        Status: status ?? null,
        PK: id ?? "",
        Remarks: textValue ?? "",
      };
      const url = `${baseUrl}${API_PATH.STATUS_CHANGE_FROM_URL}`;
      axios
        .post(url, body)
        .then((res) => {
          setLoading(false);
          if (res.data?._IsSuccess) {
            toast.success(res?.data?._Message ?? "");
            setModalOpen({
              ...modalOpen,
              open: false,
              openFor: "",
            });
            if (status === 2) {
              setChangedStatus("Approved");
            } else if (status === 3) {
              setChangedStatus("Disapproved");
            }
          } else {
            setModalOpen({
              ...modalOpen,
              open: false,
              openFor: "",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setModalOpen({
            ...modalOpen,
            open: false,
            openFor: "",
          });
          toast.error(err?.response?.data?._Message ?? "");
          console.log("Document tracking change status api fail", err);
        });
    }
  };

  const onClickDownloadDocs = (item, index) => {
    setLoadingDownload(index);
    const payload = {
      OrgCodes: "",
      EmailId: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 10,
      ShipmentID: item?.ParentId ?? "",
      DocType: "HBL",
      DocumentName: "",
      DocumentDate: "",
      DocumentPk: item.PK ?? "",
    };
    instance
      .post(API_PATH.DOWNLOAD_SHIPMENT_DOCS, payload)
      .then((res) => {
        if (res?._IsSuccess && res?._Data?.ImageDataVC) {
          const downloadLink = document.createElement("a");
          const prefix = genrateDownloadLink(res?._Data?.SC_FileName);
          downloadLink.href = `${prefix}${res?._Data?.ImageDataVC}`;
          if (prefix) {
            downloadLink.download = res?._Data?.SC_FileName ?? "";
            downloadLink.click();
          } else {
            toast.error("Invalid document found!");
          }
        } else {
          toast.error("No data found!");
        }
      })
      .catch((err) => {
        console.log("download cargo docs error", err);
      })
      .finally(() => {
        setLoadingDownload(-1);
      });
  };

  return (
    <>
      <div className=" drawer-nav ">
        <Box sx={{ display: "flex" }}>
          <AppBar
            style={{ backgroundColor: "var(--color-blue2)" }}
            component="nav"
          >
            <div className="container-fluid">
              <Toolbar
                sx={{
                  padding: " 0!important",
                  // maxWidth: "1640px",
                  width: "calc(100% - 40px)",
                  margin: "0 auto",
                  height: "87px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="main-div-small">
                  <IconButton
                    color="inherit"
                    className="mui-button-small"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { lg: "none" } }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <img
                    src={urlResolver("icon/logo-asia-tracking-white.svg")}
                    alt="logo"
                    className="small-screen-logo"
                  />
                </div>

                <Typography
                  className="mui-typography"
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: "none", lg: "block" } }}
                >
                  <img
                    src={urlResolver("icon/logo-asia-tracking-white.svg")}
                    alt="logo"
                  />
                </Typography>
                <Box
                  sx={{ display: { xs: "none", lg: "block" } }}
                  className="mui-box-div"
                >
                  <div
                    className="header-page"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="header-page-first-child">
                      {pendingDocs.HouseBillNumber && (
                        <Button style={{ color: "#fff" }}>
                          House Number : {pendingDocs?.HouseBillNumber ?? "N/A"}
                        </Button>
                      )}
                    </div>
                  </div>
                </Box>
              </Toolbar>
            </div>
          </AppBar>
          <Box component="nav">
            <div className="container-fluid drawer-sidebar">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={() => handleDrawerToggle()}
                hideBackdrop={true}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  display: { xl: "block", lg: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </div>
          </Box>
        </Box>
        <div className="documents-head-sections-tracking">
          <div className="document-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 document-head p-0">
                  <p>Documents Approval</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between document-mid-section flex-wrap">
              <div className="tracking-filter">
                <div className="text-head d-flex justify-content-between">
                  <p className="text-sec">Pending document</p>
                </div>
              </div>
            </div>
          </div>

          <div className="documentTrackingpage">
            {loadingVerify ? (
              <CircularProgress
                size="3.5rem"
                style={{ color: "--var(--color-blue2)" }}
              />
            ) : (
              <>
                {!errorVerification ? (
                  <div className="container-fluid">
                    <div className="row  pending-section">
                      <div className="col-12 col-md-3">
                        <p className="pending-color first-p-of-payment">
                          House number:{" "}
                          <span>{pendingDocs?.HouseBillNumber ?? "N/A"}</span>
                        </p>
                        <p className="approved-color first-p-of-payment">
                          Shipment number:{" "}
                          <span>{pendingDocs?.ParentId ?? "N/A"}</span>
                        </p>
                      </div>
                      <div className="col-12 col-lg-7 col-md-8">
                        <div className="conatiner-fluid">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="pending-color">Data de Saida</p>
                              <p className="pending-date">
                                {Date(pendingDocs?.ETD ?? null)}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="pending-color">Data de chegada</p>
                              <p className="pending-date">
                                {Date(pendingDocs?.ETA ?? null)}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              <p className="pending-color">
                                Data limite para aprovação
                              </p>
                              <p className="pending-date">
                                {pendingDocs?.DeadlineForApprovalDate ?? "N/A"}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-6 p-0">
                              {loadingDownload === 1 ? (
                                <p className="mb-0 d-flex justify-content-center align-items-center">
                                  Downloading...
                                </p>
                              ) : (
                                <div
                                  className="d-flex image-div "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    onClickDownloadDocs(pendingDocs, 1);
                                  }}
                                >
                                  <div className=" image-section ">
                                    <img
                                      src={urlResolver("icon/icon-pdf.svg")}
                                    />
                                    {/* <p>{pendingDocs?.DataType ?? "N/A"}</p> */}
                                  </div>
                                  <div>
                                    <p className="pending-color">
                                      <span>
                                        {pendingDocs?.FileName ?? "N/A"}
                                      </span>
                                    </p>
                                    <p>Upload: {Date(pendingDocs?.Date)}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2  col-md-4 col-lg-4 d-flex align-items-center">
                        {changedStatus !== "" ? (
                          <p>{changedStatus}</p>
                        ) : (
                          <>
                            <BtnGreen
                              title={
                                loading ? (
                                  <CircularProgress
                                    size="1.5rem"
                                    style={{ color: "var(--color-white)" }}
                                  />
                                ) : (
                                  "Approve"
                                )
                              }
                              // onClick={() => DocApproval(2)}
                              onClick={() => {
                                setModalOpen({
                                  ...modalOpen,
                                  open: true,
                                  openFor: "Approve",
                                });
                              }}
                            />
                            <div style={{ marginLeft: "1rem" }} />
                            <BtnOutline
                              onClick={() =>
                                setModalOpen({
                                  ...modalOpen,
                                  open: true,
                                  openFor: "Disapprove",
                                })
                              }
                              title="Disapprove"
                            />
                          </>
                        )}
                      </div>
                      {modalOpen.open ? (
                        <div className="d-flex flex-column">
                          <Textarea
                            minRows={4}
                            className="mb-3"
                            id="standard-basic"
                            label="Standard"
                            variant="standard"
                            style={{ border: "1px solid grey", width: "100%" }}
                            value={textValue}
                            placeholder={
                              modalOpen.openFor === "Approve"
                                ? "Reason for approval"
                                : "Reason for disapproval"
                            }
                            onChange={(e) => {
                              setTextValue(e.target.value);
                            }}
                          />
                          <div className="d-flex justify-content-center">
                            <div>
                              <BtnOutline
                                title={"Cancel"}
                                onClick={() => {
                                  setModalOpen({
                                    ...modalOpen,
                                    open: false,
                                    openFor: "",
                                  });
                                }}
                              />
                            </div>
                            <div style={{ marginLeft: "1rem" }}>
                              <BtnOutline
                                title={
                                  loading ? (
                                    <CircularProgress
                                      size="1.5rem"
                                      style={{ color: "var(--color-white)" }}
                                    />
                                  ) : (
                                    "Submit"
                                  )
                                }
                                onClick={() => {
                                  if (modalOpen.openFor === "Approve")
                                    DocApproval(2);
                                  else DocApproval(3);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <Nodata text={"Document not found"} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentTracking;
