import { Skeleton } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import Nodata from "../../../components/Nodata";

function PackingList(props) {
  const { t } = props;
  return (
    <>
      {props.loading ? (
        <div className="skeleton-type">
          <Skeleton style={{ height: "16rem", margin: "1rem 0" }} />
        </div>
      ) : (
        <div className="payment">
          <p className="color-grey2 payment-heading packing-list">
            {t("PackingList")}
          </p>
          <div>
            <table className="packing-table table">
              <tr>
                <th className="packing-head">Part No.</th>
                <th>Description</th>
                <th>Inner Packs</th>
                <th>Outer Packs</th>
                <th>Quantity</th>
                <th className="packing-bottom">Type</th>
              </tr>
              {props.detailsdata?.OrderLineCollection &&
              props.detailsdata?.OrderLineCollection.length ? (
                <>
                  {props.detailsdata?.OrderLineCollection.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="packing-head">
                          {item?.PartNumber ?? "N/A"}
                        </td>
                        <td>{item?.Description ?? "N/A"}</td>
                        <td>{item?.InnerPacks ?? "N/A"}</td>
                        <td>{item?.OuterPacks ?? "N/A"}</td>
                        <td>{item?.Quantity ?? "N/A"}</td>
                        <td className="packing-bottom">
                          {item?.OuterType ?? "N/A"}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <Nodata />
              )}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default withTranslation()(PackingList);
