import toast from "react-hot-toast";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { ACTION_TYPE } from "../constants/actionType";

export const getMyTeam = (body) => async (dispatch) => {
  if (!body.CurrentPage) {
    dispatch({ type: ACTION_TYPE.TEAM_DATA_REQUEST });
  } else {
    dispatch({ type: ACTION_TYPE.LOADING_MORE });
  }

  await instance
    .post(API_PATH.GET_USER_DETAILS, body)
    .then((data) => {
      if (!body.CurrentPage) {
        dispatch({
          type: ACTION_TYPE.TEAM_DATA_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: ACTION_TYPE.TEAM_DATA_SUCCESS_PAGE,
          payload: data,
        });
      }
    })
    .catch((error) => {
      console.log(
        "getUsersDetails action fail",
        error?.response?.data?._Message
      );
      toast.error(error?.response?.data?._Message ?? "Something went wrong!");
      dispatch({
        type: ACTION_TYPE.TEAM_DATA_FAIL,
        error: error,
      });
    });
};
