import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import {
  LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_USER_KEY,
  baseUrl,
} from "../../Config";
import { API_PATH } from "../../constant/apiPath";
import { SHA256 } from "crypto-js";
import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthProvider";
import { useNavigate } from "react-router";
import { routeConstant } from "../../routing/utils/routeConstant";
import { jwtDecode } from "jwt-decode";

export default function AlreadyDialoge({ open, setOpen, credentials }) {
  const Auth = useContext(AuthContext);
  const navigate = useNavigate();
  const generateRandomNum = async () => {
    let random =
      Math.ceil(Math.random() * 9) +
      "" +
      Math.ceil(Math.random() * 9) +
      "" +
      Math.ceil(Math.random() * 9) +
      "" +
      Math.ceil(Math.random() * 9) +
      "" +
      Math.ceil(Math.random() * 9);
    return random;
  };
  const encrypLoginCrendentials = async (ran) => {
    let originalData = "";
    if (ran.toString()[2] < 5) {
      originalData =
        ran.toString()[2] +
        ">>" +
        credentials.email.toUpperCase() +
        "::" +
        credentials.password;
    } else {
      originalData =
        credentials.email.toUpperCase() +
        "::" +
        credentials.password +
        ">>" +
        ran.toString()[2];
    }
    return SHA256(originalData);
  };
  const handleContinue = async () => {
    let ran = await generateRandomNum();
    let enc = await encrypLoginCrendentials(ran);

    axios
      .post(
        `${baseUrl}${
          API_PATH.LOGIN_CONTINUE
        }?value=${enc.toString()}&number=${ran}&emailId=${credentials.email.toUpperCase()}`
      )
      .then((res) => {
        console.log("respomse", res);
        if (res.data.Success) {
          const decoded = jwtDecode(res?.data?.Data?.AccessToken);
          let obj = {
            Role: "",
            Name: "",
            Email: "",
          };

          if (Object.keys(decoded).length) {
            Object.keys(decoded).map((item) => {
              if (item.includes("role")) {
                obj.Role = decoded[item];
              } else if (item.includes("name")) {
                obj.Name = decoded[item];
              } else if (item.includes("emailaddress")) {
                obj.Email = decoded[item];
              }
            });
          }
          localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(obj));
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(res?.data?.Data)
          );
          Auth.login(res?.data?.Data ?? {});
          navigate(routeConstant.APP_INIT);
        }
      });
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>User Already Login From Another Screen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you wish to continue from this device?
          <br /> Note : Other screen will be logged out automatically
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button type="submit" className="btn-login" onClick={handleContinue}>
          Continue
        </button>
      </DialogActions>
    </Dialog>
  );
}
