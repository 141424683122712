import React from "react";
import "../financial.css";
import Checkbox from "@mui/joy/Checkbox";
import Box from "@mui/joy/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConstant } from "../../../routing/utils/routeConstant";
import BtnOutline from "../../../components/button/BtnOutline";
import Btn from "../../../components/button/Btn";
import { basename } from "../../../Config";
import Nodata from "../../../components/Nodata";
import { withTranslation } from "react-i18next";
import urlResolver from "../../../utils/urlResolver";

function Payment(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = props;
  return (
    <div className="financial-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 container-section">
            <p>{t("Financial")}</p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row payment-top-div">
          <div className="col-12 col-md-6">
            <div className="mid-section ">
              <p>{t("Grouppayment")}</p>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex payment-top-left">
            <div className="card-head-finance d-flex ">
              <div className="search-filter">
                <div className="select">
                  <select className="title-select">
                    <option value="">{t("Select")}</option>
                    <option value="CNPJ">{t("CNPJ")}</option>
                    <option value="Origin">{t("Origin")}</option>
                    <option value="Destination">{t("Destination")}</option>
                    <option value="PO Number">{t("PONumber")}</option>
                    <option value="Shipper">{t("Shipper")}</option>
                    <option value="Ship">{t("Ship")}</option>
                  </select>
                </div>
                <div className="search-div">
                  <input
                    className="input-search"
                    type="text"
                    placeholder={t("Search")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row due-section ">
          <div className="col-12 col-md-6 group-payment-top">
            <div className="">
              <p className="due-color">{t("Due")}</p>
              <p className="text-color1">{`${
                location?.state?.amount?.CompanyCurrencyCode ?? ""
              }${" "}${location?.state?.amount?.DueAmount ?? 0}`}</p>
            </div>
            <div>
              <p className="due-color">{t("Overdue")}</p>
              <p className="text-color2">
                {`${location?.state?.amount?.CompanyCurrencyCode ?? ""}${" "}${
                  location?.state?.amount?.OverDueAmount ?? 0
                }`}
                <span className="alert-icon">
                  <img
                    src={urlResolver("icon/icon-alert.svg")}
                    alt="icon-alert"
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {location?.state?.data.length > 0 ? (
        <>
          {location.state.data.map((item, index) => {
            return (
              <div className="container-fluid">
                <div className="row  payment-section">
                  <div className="col-12 col-md-4">
                    <div className="conatiner">
                      <div className="row">
                        <div className="col-1">
                          <Box sx={{ display: "flex", gap: 3 }}>
                            <Checkbox color="primary" />
                          </Box>
                        </div>
                        <div className="col-10 po-number-text">
                          <p className="payment-color">{t("PONumber")}:</p>
                          <p>{item?.PONumber ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="conatiner">
                      <div className="row">
                        <div className="col-6">
                          <p className="payment-color">{t("ShipmentNumber")}</p>
                          <p>{item?.ShipmentID ?? "N/A"}</p>
                        </div>
                        <div className="col-6">
                          <p className="payment-color">
                            {t("House Bill of Lading")}
                          </p>
                          <p>{item?.HouseBillNumber ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="conatiner">
                      <div className="row">
                        <div className="col-6">
                          <p className="payment-color">{t("Amount")}</p>
                          <p>{`${item?.OverseasCurrency ?? ""}${" "}${
                            item?.InvoiceAmount ?? 0
                          }`}</p>
                        </div>
                        <div className="col-6">
                          <p className="payment-color">{t("DueDate")}</p>
                          <p>24/12/2024</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <Nodata text={"Nothing to pay"} />
      )}

      <div className="d-flex">
        <BtnOutline
          className="paymentreview"
          onClick={() => navigate(routeConstant.PAYMENTREVIEW)}
          title={t("GoToPayment")}
        />
        <div style={{ marginLeft: "1rem" }} />
        <Btn
          onClick={() => navigate(routeConstant.FINNANCE)}
          title={`< ${t("Back")}`}
        ></Btn>
      </div>
    </div>
  );
}

export default withTranslation()(Payment);
