import React from "react";
import "./button.css";
import AddIcon from "@mui/icons-material/Add";
function ButtonIcon(props) {
  const { onClick = () => {}, title = "" } = props;
  return (
    <div className=" btnn-icon-div">
      <AddIcon />
      <button onClick={onClick} type="button" className="btnn-icon ">
        {title}
      </button>
    </div>
  );
}

export default ButtonIcon;
