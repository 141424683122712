import React from "react";
import Tooltip from "@mui/material/Tooltip";

// Dummy data
const dummyData = {
  maxValue: 2000,
  data: [
    { lable: "Maersk", value: 1001},
    { lable: "CMA-CGM", value: 1500 },
    { lable: "MSC", value: 1403 }
  ]
};

function HorizontalBarChart({ datasets = dummyData }) {
  const { maxValue, data } = datasets;

  return (
    <>
      {data.map((item, index) => {
           console.log(item,"item");
        const barPercentage = (parseInt(item.value) * 100) / parseInt(maxValue);
        return (
          <div className="horizontal-bar" key={index}>
            <div className="d-flex justify-content-between">
              <p style={{ padding:'4px'}}>{item.lable}</p>
            </div>
            <Tooltip
              placement="top"
              title={`${item.lable}: ${item.value} Kg`} 
              arrow
              style={{color:'red'}}
            >
              <div className="progress horizontal-progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${barPercentage}%` }}
                  aria-valuemin="0"
                  aria-valuemax={maxValue}
                />
              </div>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
}

export default HorizontalBarChart;

