import React, { useState } from "react";
import "../login/login.css";
import AuthLayout from "../../components/layout/Auth/AuthLayout";
import TextField from "@mui/material/TextField";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
import toast from "react-hot-toast";
import { Formik } from "formik";
import { ForgotWithEmail } from "../../helpers/validator/LoginWithPassword";
import { CircularProgress } from "@mui/material";

const Forgetpassword = () => {
  const [loading, setLoading] = useState(false);
  const onClickReset = (values, action) => {
    setLoading(true);
    instance
      .post(API_PATH.GET_RESET_PASSWORD_LINK + values.email)
      .then((res) => {
        setLoading(false);
        if (res._IsSuccess) {
          toast.success(res._Message);
          action.resetForm();
        } else if (!res._IsSuccess) {
          toast.error(res._Message);
          action.resetForm();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?._Message ?? "Something went wrong!");
        console.log("reset link send error ", err);
        action.resetForm();
      });
  };

  return (
    <AuthLayout>
      <div className="container-form">
        <div>
          <h1 className="title">AS Tracking</h1>
        </div>
        <div className="inputs" />
        <Formik
          initialValues={ForgotWithEmail.initials}
          validationSchema={ForgotWithEmail.validation}
          onSubmit={(values, action) => {
            onClickReset(values, action);
          }}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <div className="input-defalut-login">
                  <TextField
                    label="Email"
                    variant="standard"
                    value={props.values.email}
                    onChange={(event) => {
                      props.setFieldValue("email", event.target.value);
                    }}
                  />
                  {props.errors.email && (
                    <span className="span-error">{props.errors.email}</span>
                  )}
                </div>
                <div className="submit">
                  <button
                    type="submit"
                    className="btn-login min-width-reset "
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        size="1.5rem"
                        style={{ color: "var(--color-white)" }}
                      />
                    ) : (
                      "Send reset Instructions"
                    )}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default Forgetpassword;
