import { Skeleton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import instance from "../../../api/interceptor";
import "../../../components/Milestone/mileston.css";
import Nodata from "../../../components/Nodata";
import { API_PATH } from "../../../constant/apiPath";
import { Date, Time } from "../../../utils/moment";
import { withTranslation } from "react-i18next";

const Milestone = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [milestonedata, setMilestonedata] = useState([]);
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  const { t } = props;
  const getMilestone = () => {
    setLoading(true);
    instance
      .get(
        `${API_PATH.MILESTONE_PROCESS}${location?.state?.item?.ShipmentID}`,
        {
          signal,
        }
      )
      .then((res) => {
        setLoading(false);
        if (res._IsSuccess) {
          if (res?._Data?.routingTimeLine) {
            setMilestonedata(res?._Data?.routingTimeLine?.eventNodes ?? []);
          }
        } else {
          setMilestonedata([]);
        }
      })
      .catch((err) => {
        if (!checkAbort) setLoading(false);

        setMilestonedata([]);
        console.log("Get milestone api in process fail", err);
      });
  };

  useEffect(() => {
    if (location?.state?.item?.ShipmentID !== undefined) getMilestone();

    return () => {
      setCheckAbort(true);
      return controller.abort();
    };
  }, [location]);

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={611}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      ) : (
        <div className="milestone-section">
          {milestonedata.length ? (
            <>
              {milestonedata.map((item, index) => {
                return (
                  <div className="milestone-div" key={index}>
                    <div className="milestone-div-child pt-3">
                      {item?.nameId === "Arrival_At_Destination" ||
                      item?.nameId === "Arrival_At_Destination_air" ||
                      item?.nameId === "Arrival_At_Destination_roa" ? (
                        <p className="hour">
                          {/* {item?.ataDate !== "" && item?.etaDate !== "" ? (
                            <>{Date(item?.ataDate ?? item?.etaDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )} */}
                          <>{Time(item?.ataDate ?? item?.etaDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "Dept_From_First_Load_Port" ||
                        item?.nameId === "Dept_From_First_Load_Port_air" ||
                        item?.nameId === "Dept_From_First_Load_Port_roa" ? (
                        <p className="hour">
                          {/* {item?.atdDate !== "" && item?.etdDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )} */}
                          <>{Time(item?.atdDate ?? item?.etdDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "CE_Mercante_Clearance" ? (
                        <p className="hour">
                          <>{Time(item?.atdDate ?? item?.etdDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "Cargo_Ready" ? (
                        <p className="hour">
                          <>{Time(item?.atdDate ?? item?.etdDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "Pick_up" ? (
                        <p className="hour">
                          <>{Time(item?.atdDate ?? item?.etdDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "In_Transit" ? (
                        <p className="hour">
                          {/* {item.atdDate !== "" && item?.etaDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etaDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )} */}
                          <>{Time(item?.atdDate ?? item?.etdDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "Shipment_Authorization" ? (
                        <p className="hour">
                          <>{Time(item?.atdDate ?? item?.etdDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "load" ? (
                        <p className="hour">
                          <>{Time(item?.atdDate ?? item?.etdDate ?? null)}</>
                        </p>
                      ) : item?.nameId === "unload" ? (
                        <p className="hour">
                          <>{Time(item?.ataDate ?? item?.etaDate ?? null)}</>
                        </p>
                      ) : null}
                    </div>
                    <div
                      className="milestone-div-child"
                      style={{ width: "12%" }}
                    >
                      <div
                        className="circle"
                        style={{
                          backgroundColor: item.milestoneColor,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.milestoneIconPath && (
                          <img
                            src={item.milestoneIconPath}
                            alt={item.eventName}
                            height={50}
                            width={50}
                          />
                        )}
                      </div>
                      {index !== milestonedata.length - 1 ? (
                        <div
                          className="milestone-line"
                          style={{
                            backgroundColor: item.milestoneColor,
                            height: "70px",
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="milestone-div-child pt-3 milestone-right-side">
                      <p
                        className="status-name"
                        style={{ color: item.milestoneColor }}
                      >
                        {t(item?.eventName) ?? "NA"}
                      </p>
                      {item?.nameId === "Arrival_At_Destination" ||
                      item?.nameId === "Arrival_At_Destination_air" ||
                      item?.nameId === "Arrival_At_Destination_roa" ? (
                        <p className="status-year">
                          {/* {item?.ataDate !== "" && item?.etaDate !== "" ? (
                            <>{Date(item?.ataDate ?? item?.etaDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )} */}
                          {item?.ataDate === null ? (
                            <>{Date(item?.etaDate ?? null)}</>
                          ) : (
                            <>{Date(item?.ataDate ?? null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "Dept_From_First_Load_Port" ||
                        item?.nameId === "Dept_From_First_Load_Port_air" ||
                        item?.nameId === "Dept_From_First_Load_Port_roa" ? (
                        <p className="status-year">
                          {/* {item?.atdDate !== "" && item?.etdDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )} */}
                          {item?.atdDate === null ? (
                            <>{Date(item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(item?.atdDate ?? null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "CE_Mercante_Clearance" ? (
                        <p className="status-year">
                          {item.atdDate !== "" && item?.etdDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "Cargo_Ready" ? (
                        <p className="status-year">
                          {item.atdDate !== "" && item?.etdDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "Pick_up" ? (
                        <p className="status-year">
                          {item.atdDate !== "" && item?.etdDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "In_Transit" ? (
                        <p className="status-year">
                          {/* {item.atdDate !== "" && item?.etaDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etaDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )} */}
                          {item?.atdDate === null ? (
                            <>{Date(item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(item?.atdDate ?? null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "Shipment_Authorization" ? (
                        <p className="status-year">
                          {item.atdDate !== "" && item?.etaDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "load" ? (
                        <p className="status-year">
                          {item.atdDate !== "" && item?.etdDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )}
                        </p>
                      ) : item?.nameId === "unload" ? (
                        <p className="status-year">
                          {item.ataDate !== "" && item?.etaDate !== "" ? (
                            <>{Date(item?.atdDate ?? item?.etdDate ?? null)}</>
                          ) : (
                            <>{Date(null)}</>
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <Nodata text="Milestone not found" />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Milestone);
