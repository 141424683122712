import { ACTION_TYPE } from "../constants/actionType";

const initialState = {
  loading: false,
  isLoadMore: false,
  myTeam: [],
  totalCount: 0,
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.TEAM_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        myTeam: [],
      };
    case ACTION_TYPE.LOADING_MORE:
      return {
        ...state,
        isLoadMore: true,
        myTeam: [...state.myTeam],
      };
    case ACTION_TYPE.TEAM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoadMore: false,
        myTeam: [...action.payload._Data],
        totalCount: action.payload._TotalItem,
      };
    case ACTION_TYPE.TEAM_DATA_SUCCESS_PAGE:
      return {
        ...state,
        loading: false,
        isLoadMore: false,
        myTeam: [...state.myTeam, ...action.payload._Data],
        totalCount: action.payload._TotalItem,
      };
    case ACTION_TYPE.TEAM_DATA_FAIL:
      return {
        ...state,
        loading: false,
        isLoadMore: false,
        myTeam: [...state.myTeam],
      };
    default:
      return {
        ...state,
      };
  }
};
