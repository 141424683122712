import React from "react";
import moment from "moment";
import "./mileston.css";
import { Skeleton } from "@mui/material";
import { Date, Time } from "../../utils/moment";

const Milestone = ({ milestoneData, loading }) => {
  return (
    <>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={365}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      ) : (
        <div className="milestone-section">
          {milestoneData.map((item, index) => {
            return (
              <div className="milestone-div" key={index}>
                <div className="milestone-div-child pt-3">
                  <p className="hour">
                    {item.ActualDate ? Time(item?.ActualDate ?? "") : "-"}
                  </p>
                </div>
                <div className="milestone-div-child" style={{ width: "12%" }}>
                  <div
                    className="circle"
                    style={{
                      backgroundColor: item.ColourCode,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item.IconUrl && (
                      <img
                        src={item.IconUrl}
                        alt={item.Description}
                        height={50}
                        width={50}
                      />
                    )}
                  </div>
                  {index !== milestoneData.length - 1 ? (
                    <div
                      className="milestone-line"
                      style={{ backgroundColor: item.ColourCode }}
                    />
                  ) : null}
                </div>
                <div className="milestone-div-child pt-3 milestone-right-side">
                  <p className="status-name" style={{ color: item.ColourCode }}>
                    {item.Description}
                  </p>
                  <p className="status-year">
                    {Date(item?.ActualDate ?? null)}
                  </p>
                </div>
              </div>
            );
          })}
          <div></div>
        </div>
      )}
    </>
  );
};

export default Milestone;
