import instance from "../api/interceptor";
import { baseUrl, LOCAL_STORAGE_KEY } from "../Config";
import { API_PATH } from "../constant/apiPath";
import axios from "axios";
import { Buffer } from "buffer";

export const UserService = {
  login: (email, password) => {
    const auth = {
      Username: email,
      Password: password,
    };
    // const params = new URLSearchParams();
    // params.append("UserName", email);
    // params.append("Password", password);

    const token = Buffer.from(`${email}:${password}`, "utf8").toString(
      "base64"
    );

    const config = {
      method: "post",
      url: `${baseUrl}${API_PATH.LOGIN}`,

      headers: { Authorization: `Basic ${token}` },
      "Access-Control-Allow-Credentials": true,
    };

    // .post(API_PATH.LOGIN, auth)
    return axios(config).then((response) => {
      const res = response.data;
      return res;
    });
  },

  users: (body) => {
    return instance.post(API_PATH.GET_USER_DETAILS, body).then((res) => {
      // setdata into localstorage

      return res;
    });
  },
  permissions: () => {
    return instance.get(`${API_PATH.GET_PERMISSION}`).then((res) => {
      return res;
    });
  },
};
