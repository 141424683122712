import React, { createContext, useReducer, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOCAL_STORAGE_KEY } from "../../Config";
import authHeader, { localData } from "../../services/auth-header";
import { User } from "../redux/actions/UserAction";
const AuthContext = createContext();
const CONTEXT_ACTION_TYPE = {
  LOGIN: "login",
  LOGOUT: "logout",
  TOKEN_RESTORE: "restore-token",
  DOCPENDING: "docpending",
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case CONTEXT_ACTION_TYPE.LOGIN:
      return {
        ...state,
        status: "login",
        userAuth: true,
        authToken: action.token,
        loading: false,
        role: action?.token?.Roles ?? null,
      };
    case CONTEXT_ACTION_TYPE.DOCPENDING:
      return {
        ...state,
        docPending: action.token,
      };

    case CONTEXT_ACTION_TYPE.LOGOUT:
      return {
        ...state,
        status: "logout",
        userAuth: false,
        authToken: null,
        loading: false,
        role: null,
      };
    case CONTEXT_ACTION_TYPE.TOKEN_RESTORE:
      return {
        ...state,
        status: "login",
        userAuth: true,
        authToken: action.token,
        loading: false,
        role: action?.token?.Roles ?? null,
      };

    default:
      return {
        ...state,
      };
  }
};

function AuthProvider(props) {
  const [state, dispatch] = useReducer(AuthReducer, {
    loading: true,
    userAuth: false,
    status: "Idle",
    authToken: null,
    role: null,
    docPending: null,
  });

  const reduxDispatch = useDispatch();

  useEffect(() => {
    const token = authHeader();

    if (token) {
      dispatch({ type: CONTEXT_ACTION_TYPE.TOKEN_RESTORE, token: localData() });
    } else {
      dispatch({ type: CONTEXT_ACTION_TYPE.LOGOUT });
    }
  }, []);

  useEffect(() => {
    if (state.userAuth) {
      reduxDispatch(User.permissions())
        .then((res) => {
          // navigate(routeConstant.APP_INIT);
          // setLoading(false);
        })
        .catch((err) => {
          // setLoading(false);
          console.log("permission dispatch error ", err);
        });
    }
  }, [state.userAuth]);

  const actions = useMemo(
    () => ({
      login: async (token) => {
        dispatch({ type: CONTEXT_ACTION_TYPE.LOGIN, token });
      },
      docpending: async (token) => {
        dispatch({ type: CONTEXT_ACTION_TYPE.DOCPENDING, token });
      },
      logout: async () => {
        dispatch({ type: CONTEXT_ACTION_TYPE.LOGOUT });
        try {
          localStorage.removeItem(LOCAL_STORAGE_KEY);
        } catch (e) {
          console.log("e", e);
        }
      },
      getToken: async () => {
        try {
          const jsonValue = localStorage.getItem(LOCAL_STORAGE_KEY);
          const authtoken = JSON.parse(jsonValue);
          return authtoken;
        } catch (e) {
          console.log(e);
        }
      },
    }),
    [state, dispatch]
  );

  return (
    <AuthContext.Provider value={{ ...state, ...actions }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
